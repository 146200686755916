import React , { useState , useEffect, useRef }from 'react';
import {FormGroup, Button, Input, Modal, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, TabPane , TabContent ,Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import Multiselect from '../../../components/Multiselect';
import KeyValuePair from '../../../components/KeyValuePair';
import { all_allowed_key } from '../../../components/AdditionalPropKeys';
import { useAlert } from 'react-alert';
import is_valid_add_key from '../../../utils/is_valid_add_key';
import name_sort from '../../../utils/name_sort';
import { exclusion_list_options } from '../../../utils/cbu_exclusion_list';
import comp_file_top_level_elem from '../../../utils/comp_file_top_level_elem';
import general_fetch from '../../../utils/fetch';
import Upload3d_modal_modal from '../../../components/Upload_3d_modal';
import { postUploadImageExtractionfn } from '../../../utils/show_3d_model';
import Add_new_material from '../../../components/Add_new_material';
import { fetch_material_templates } from '../../../utils/api/material';
import { is_valid_file_type } from '../../../utils/is_valid_file';

const cbu_valid_types = [ "corner_unit", "cabinet", "drawer_system" ];

const AdditionalPropsModal = ({open, handleClose, modal_default_properties, modal_custom_properties, active_sku_id, update_edited_sku_json_additional_props, original_sku_json, sku_category_type}) => {

    const [additional_default_properties, set_additional_default_properties] = useState([]);
    const [additional_custom_properties, set_additional_custom_properties] = useState([]);
    const alert = useAlert()

    const compare_function = (a,b) => {
        if(a.key < b.key) return -1;
        if(a.key > b.key) return 1;
        return 0;
    }

    const onclick_change = () => {

		if(additional_default_properties && additional_default_properties.length){
			for(let i=0; i<additional_default_properties.length; i++){
				if(additional_default_properties[i].key === 'manufacturing_details'){
					try{
						let value = JSON.parse(additional_default_properties[i].value)
					}catch(err){
						alert.error('JSON entered for Manufacturing Details Additional Property is not Valid')
						return
					}
				}
			}
		}

        if(additional_default_properties && additional_default_properties.length>1 && !is_valid_add_key(additional_default_properties)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
            return
		}else if(additional_custom_properties && additional_custom_properties.length>1 && !is_valid_add_key(additional_custom_properties)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
            return
		}

        var x_addi_prop_default = [];
        var x_addi_prop_custom = [];
        additional_default_properties.map(x => {
            if(additional_default_properties[0].key){
                x_addi_prop_default.push(x)
            }
        })
        additional_custom_properties.map(x => {
            if (additional_custom_properties[0].key) {
                x_addi_prop_custom.push(x)
            }
        }) 

        let original_default_properties = JSON.parse(JSON.stringify(original_sku_json['default_properties'][active_sku_id]))
        let original_custom_properties = JSON.parse(JSON.stringify(original_sku_json['custom_properties'][active_sku_id]))
        let new_default_properties = JSON.parse(JSON.stringify(x_addi_prop_default))
        let new_custom_properties = JSON.parse(JSON.stringify(x_addi_prop_custom))

        let default_properties_changed = JSON.stringify(new_default_properties.sort(compare_function)) != JSON.stringify(original_default_properties.sort(compare_function))
        let custom_properties_changed = JSON.stringify(new_custom_properties.sort(compare_function)) != JSON.stringify(original_custom_properties.sort(compare_function))
        update_edited_sku_json_additional_props(active_sku_id, x_addi_prop_default, x_addi_prop_custom, default_properties_changed, custom_properties_changed)
        onclick_handleclose()
    }

    const onclick_handleclose = () => {
        set_additional_default_properties([])
        set_additional_custom_properties([])
        handleClose();
    }

    useEffect(() => {
        if(modal_default_properties && modal_default_properties.length) set_additional_default_properties(JSON.parse(JSON.stringify(modal_default_properties)));
    }, [modal_default_properties]);

    useEffect(() => {
        if(modal_custom_properties && modal_custom_properties.length) set_additional_custom_properties(JSON.parse(JSON.stringify(modal_custom_properties)))
    }, [modal_custom_properties]);

    return(
        <Modal className="Margin_64px_top" size='lg' isOpen={open} toggle={onclick_handleclose}>
            <Card style={{backgroundColor:'#F5F6F8'}}>
                <CardHeader className="global_modal_header">
                    <span>Additional Properties</span>
                    <i className='fa fa-times' onClick={onclick_handleclose}/>
                </CardHeader>
                <CardBody>
                    <KeyValuePair item_json = {additional_default_properties} set_item_json = {set_additional_default_properties} sku_category_type={sku_category_type} mode='default' all_allowed_key={all_allowed_key}/>
                    <KeyValuePair item_json = {additional_custom_properties} set_item_json = {set_additional_custom_properties} sku_category_type={sku_category_type} mode='custom' all_allowed_key={all_allowed_key}/>
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button className='white_button' onClick={() => {set_additional_default_properties(JSON.parse(JSON.stringify(modal_default_properties))); set_additional_custom_properties(JSON.parse(JSON.stringify(modal_custom_properties)))}}>Reset</Button>
                    <Button className='blue_button' onClick = {() => onclick_change()}>Change</Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

const TriggerAddMaterial = ({material_template, set_page_loader, update_edited_sku_json, edited_sku_json, sku_id}) => {
    const [show_add_new_material, set_show_add_new_material] = useState(false);
    const [material_mode, set_material_mode] = useState('');
    // const [ material_template, set_material_template ] = useState('');
    const [base_texture_url, set_base_texture_url] = useState('');
    const [material_details, set_material_details] = useState('');

    const close_add_new_material = () => {
        set_show_add_new_material(false)
        set_material_mode('')
    }
    const open_add_new_material = () => {
        set_show_add_new_material(true)
        if (material_details && material_details.id) {
            set_material_mode('edit')
        } else {
            set_material_mode('add')
        }
    }
    return(
        <React.Fragment>
            {/* <Add_new_material
                set_return_material_details={set_material_details}
                active_material_template={material_details}
                // return_material_details={return_material_details} 
                open={show_add_new_material}
                handle_close={close_add_new_material}
                material_template={material_template}
                set_page_loader={set_page_loader}
                // texture_json={texture_json}
                material_mode={material_mode}
                set_material_mode={set_material_mode}
                // update_sku_array = {update_sku_array}
                // sku_array_idx = {sku_array_idx}
                base_texture_url={base_texture_url}
                set_base_texture_url={set_base_texture_url}
                callbackOnMaterialAdd={(material_details) => {update_edited_sku_json('material_id', sku_id, material_details.id)}}
            /> */}
            <Add_new_material
                // add_id={add_id}
                open={show_add_new_material}
                handle_close={close_add_new_material}
                mode={material_details && material_details.id ? 'edit' : 'add'}
                initial_material_details={material_details}
                callbackOnMaterialUpdate={(material_details) => {set_material_details(material_details); update_edited_sku_json('material_id', sku_id, material_details.id)}}
            />
            <Button className="blue_button ml-3" disabled={edited_sku_json["is_owned"][sku_id] ? false : true} onClick={edited_sku_json["is_owned"][sku_id] ? () => { open_add_new_material() } : null}>{'Upload Material'}</Button>
            {
                material_details && material_details.id ?
                    <span style={{ color: 'green' }} className='ml-3'>Uploaded</span> : ""
            }
        </React.Fragment>
    )
}

const EditIndividualProperties = ({user, orignal_tags, eligible_tags, all_sales_channels, all_brands, original_sku_array, original_sales_channels, original_prices, sub_cats_included, bulk_selected_hierarchical_data, sku_category_name, active_division_id, sku_category_type, no_of_bulk_selected_skus_in_subcat, edited_sku_array, set_edited_sku_array, is_allow_material, is_allow_material_and_depth, tags_at_all_levels, set_choosing_editing_mode, editing_individual_props, set_editing_individual_props, set_confirmation_mode, create_attempt_and_trigger, default_pricing_dimension, pricing_dimension_options, default_pricing_unit, individual_props_confirmation_mode, set_individual_props_confirmation_mode, set_page_loader}) => {

    const [tabs , set_tabs] = useState(1)
    const [subcats_collapsed, set_subcats_collapsed] = useState({})
    const [original_sku_json, set_original_sku_json] = useState({});
    const [edited_sku_json, set_edited_sku_json] = useState({});
    const [new_sales_channels_added, set_new_sales_channels_added] = useState([]);
    const [no_of_price_types_new, set_no_of_price_types_new] = useState(0);
    const [no_of_price_types_modifying, set_no_of_price_types_modifying] = useState(0);
    const [no_of_price_types_modifying_per_group, set_no_of_price_types_modifying_per_group] = useState({});
    const [no_of_price_types_modifying_per_sku, set_no_of_price_types_modifying_per_sku] = useState({});
    const [show_additional_props_modal, set_show_additional_props_modal] = useState(false);
    const [modal_default_properties, set_modal_default_properties] = useState([]);
    const [modal_custom_properties, set_modal_custom_properties] = useState([]);
    const [new_sales_channels_values_json, set_new_sales_channels_values_json] = useState({});
    const [existing_sales_channels_values_json, set_existing_sales_channels_values_json] = useState({});
    const [modifed_sales_channels_values_json, set_modifed_sales_channels_values_json] = useState({});
    const [active_sku_id, set_active_sku_id] = useState();
    const [hierarchical_data_sku_name_model, set_hierarchical_data_sku_name_model] = useState();
    const [hierarchical_data_sku_dimensions_placement, set_hierarchical_data_sku_dimensions_placement] = useState();
    const [hierarchical_data_sku_tags, set_hierarchical_data_sku_tags] = useState();
    const [hierarchical_data_sku_brands, set_hierarchical_data_sku_brands] = useState();
    const [hierarchical_data_additional_props, set_hierarchical_data_additional_props] = useState();
	const [hierarchical_data_dp_3dmodel_material, set_hierarchical_data_dp_3dmodel_material] = useState();
    const [hierarchical_data_advanced_pricing, set_hierarchical_data_advanced_pricing] = useState();
	const [show_Upload3d_modal_modal, set_show_Upload3d_modal_modal] = useState(false);
    const [material_template, set_material_template] = useState('');
    const [cbu_options, set_cbu_options] = useState({})
    const [original_cbu_options, set_original_cbu_options] = useState({})

    const properties_in_conformation_array = [
        {
            name: 'SKU Properties',
            modified: (hierarchical_data_sku_name_model && hierarchical_data_sku_name_model.length) || (hierarchical_data_sku_dimensions_placement && hierarchical_data_sku_dimensions_placement.length)
        },
        {
            name: 'Brands',
            modified: hierarchical_data_sku_brands && hierarchical_data_sku_brands.length
        },
        {
            name: 'Tags',
            modified: hierarchical_data_sku_tags && hierarchical_data_sku_tags.length
        },
        {
            name: 'Sales Channel',
            modified: hierarchical_data_advanced_pricing && hierarchical_data_advanced_pricing.length
        },
        {
            name: 'Additional Properties',
            modified: hierarchical_data_additional_props && hierarchical_data_additional_props.length
        },
        {
            name: 'Display Image/ 3D Modal',
            modified: hierarchical_data_dp_3dmodel_material && hierarchical_data_dp_3dmodel_material.length
        }   
    ]

    const alert = useAlert()

    const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

    const update_subcats_collapsed = (subcat_id, property, value) => {
        let a = JSON.parse(JSON.stringify(subcats_collapsed))
        if(a[subcat_id]){
            a[subcat_id][property] = value;
        }else{
            a[subcat_id] = {};
            a[subcat_id][property] = value;
        }
        set_subcats_collapsed(a)
    }

    const update_new_sales_channels_values_json = (sku_id, sales_channel_idx, price_type_idx, property, value) => {
        let a = JSON.parse(JSON.stringify(new_sales_channels_values_json))
        if(a[sku_id] && a[sku_id][sales_channel_idx] && a[sku_id][sales_channel_idx]['price_types'][price_type_idx]){
            a[sku_id][sales_channel_idx]['price_types'][price_type_idx][property] = value
        }
        // console.log('new_sales_channels_values_json 74', a)
        set_new_sales_channels_values_json(a)
    }

    const update_modifed_sales_channels_values_json = (sku_id, sales_channel_idx, price_type_idx, property, value) => {
        let a = JSON.parse(JSON.stringify(modifed_sales_channels_values_json))
        if(a[sku_id] && a[sku_id][sales_channel_idx] && a[sku_id][sales_channel_idx]['price_types'][price_type_idx]){
            console.log('update_modifed_sales_channels_values_json', sku_id, sales_channel_idx, price_type_idx, property, value)
            a[sku_id][sales_channel_idx]['price_types'][price_type_idx][property] = value
        }
        set_modifed_sales_channels_values_json(a)
    }

    const update_modifed_sales_channels_values_json_checkbox = (sku_id, sales_channel_idx, value) => {
        let a = JSON.parse(JSON.stringify(modifed_sales_channels_values_json))
        if(a[sku_id] && a[sku_id][sales_channel_idx]){
            a[sku_id][sales_channel_idx]['checked_to_remove'] = value
        }
        set_modifed_sales_channels_values_json(a)
    }

    const reset_modifed_sales_channels_values_json_checkboxes = () => {
        let a = JSON.parse(JSON.stringify(modifed_sales_channels_values_json))
        if(original_sku_array && original_sku_array.length){
            original_sku_array.map((single_sku) => {
                let sales_channels = a[single_sku.id]
                if(sales_channels && sales_channels.length){
                    sales_channels.map((single_sales_channel) => {
                        single_sales_channel['checked_to_remove'] = false
                    })
                } 
            })
        }
        set_modifed_sales_channels_values_json(a)
    }

    const checks_before_bulk_edit = () => {
        if(original_sku_array && original_sku_array.length){
            let return_var = 0
            original_sku_array.map((single_sku, idx1) => {
                let sales_channels = modifed_sales_channels_values_json[single_sku.id]
                if(sales_channels && sales_channels.length){
                    sales_channels.map((single_sales_channel, idx2) => {
                        if(single_sales_channel['checked_to_remove']){
                            let price_types = single_sales_channel['price_types']
                            price_types.map((single_price_type, idx3) => {
                                if(single_price_type['pricing_mode'] != existing_sales_channels_values_json[single_sku.id][idx2]['price_types'][idx3]['pricing_mode']){
                                    alert.error("You are trying to modify and remove the same sales channel - '" + single_sales_channel.name + "' of the SKU - '"+ single_sku.name+"'");
                                    return_var = 1
                                    return
                                }else if(single_price_type['pricing_mode'] == 'custom'){
                                    if(single_price_type['price'] != existing_sales_channels_values_json[single_sku.id][idx2]['price_types'][idx3]['price'] || 
                                        single_price_type['display_unit'] != existing_sales_channels_values_json[single_sku.id][idx2]['price_types'][idx3]['display_unit'] || 
                                        single_price_type['tax'] != existing_sales_channels_values_json[single_sku.id][idx2]['price_types'][idx3]['tax'] || 
                                        single_price_type['margin'] != existing_sales_channels_values_json[single_sku.id][idx2]['price_types'][idx3]['margin'])
                                        {
                                            alert.error("You are trying to modify and remove the same sales channel - '" + single_sales_channel.name + "' of the SKU - '"+ single_sku.name+"'");
                                            return_var = 1
                                            return
                                        }
                                }
                            })
                            
                        }
                    })
                }
            })
            console.log('bulk_edit_function')
            if(!return_var) bulk_edit_function()
        }
    }

    const are_scalar_arrs_equal = async (a, b) => {
        if(!a || !b) return false;
        if(a.length != b.length) return false;
        for(let i=0; i<a.length; i++){
            let x = a[i].id ? a[i].id : a[i];
            let y = b[i].id ? b[i].id : b[i];
            if(x != y) return false;
        }
        return true;
    }

    const bulk_edit_function = async() => {
        let final_sku_array = []
        if(original_sku_array && original_sku_array.length){
            for(let i=0;i<original_sku_array.length;i++){
                let current_sku = original_sku_array[i]
                let edited_sku = {}
                edited_sku['id'] = current_sku.id
                if(edited_sku_json["low_model_3d_id"][current_sku.id] != original_sku_json["low_model_3d_id"][current_sku.id] && comp_file_top_level_elem(current_sku).type == 'obj_unit'){
                    let component_file = [{"id":"7d977730-291a-4259-","type":"obj_unit", "name": "Core_Element", "container_component_id": null, data: {}}]
                    if(edited_sku_json["low_model_3d_data"] && edited_sku_json["low_model_3d_data"][current_sku.id]){
                        let server_path = global.config.server_path;
                        let obj_src = edited_sku_json["low_model_3d_data"][current_sku.id].path ? server_path + server_path + edited_sku_json["low_model_3d_data"][current_sku.id].path : '';
                        let mtl_src = edited_sku_json["low_model_3d_data"][current_sku.id].mtl_path ? server_path + edited_sku_json["low_model_3d_data"][current_sku.id].mtl_path : '';
                        let glb_src = edited_sku_json["low_model_3d_data"][current_sku.id].glb_src ? server_path + edited_sku_json["low_model_3d_data"][current_sku.id].glb_src : '';
                        let scale = edited_sku_json["low_model_3d_data"][current_sku.id].scale;
                        
                        let { top_colour_image, top_grayscale_image, ...dim } = await postUploadImageExtractionfn({obj_src, mtl_src, server_path, glb_src, scale})
                        component_file = [{"id":"7d977730-291a-4259-","type":"obj_unit", "name": "Core_Element", "container_component_id": null, data: {width: Number(dim.width), depth: Number(dim.depth), height: Number(dim.height)}}]
                    }
                    current_sku.component_file = component_file
                    edited_sku['component_file'] = current_sku.component_file
                }
                if(edited_sku_json['material_id'][current_sku.id] != original_sku_json['material_id'][current_sku.id]){
                    edited_sku['material_id'] = edited_sku_json['material_id'][current_sku.id]
                }
                if(edited_sku_json['name'][current_sku.id] != original_sku_json['name'][current_sku.id]){
                    edited_sku['name'] = edited_sku_json['name'][current_sku.id]
                }
				if(edited_sku_json['description'][current_sku.id] != original_sku_json['description'][current_sku.id]){
                    edited_sku['description'] = edited_sku_json['description'][current_sku.id]
                }
                if(edited_sku_json['model_no'][current_sku.id] != original_sku_json['model_no'][current_sku.id]){
                    edited_sku['model_no'] = edited_sku_json['model_no'][current_sku.id]
                }
				if(edited_sku_json['order'][current_sku.id] != original_sku_json['order'][current_sku.id]){
                    edited_sku['order'] = Number(edited_sku_json['order'][current_sku.id])
                }
                if(edited_sku_json['brand_id'][current_sku.id] != original_sku_json['brand_id'][current_sku.id]){
                    edited_sku['brand_id'] = edited_sku_json['brand_id'][current_sku.id]
                }
                
                if(is_allow_material){
                    if(Number(edited_sku_json['height'][current_sku.id]) != Number(original_sku_json['height'][current_sku.id])){
                        edited_sku['height'] = Number(edited_sku_json['height'][current_sku.id])
                    }
                    if(Number(edited_sku_json['width'][current_sku.id]) != Number(original_sku_json['width'][current_sku.id])){
                        edited_sku['width'] = Number(edited_sku_json['width'][current_sku.id])
                    }
                    if(Number(edited_sku_json['depth'][current_sku.id]) != Number(original_sku_json['depth'][current_sku.id])){
                        edited_sku['depth'] = Number(edited_sku_json['depth'][current_sku.id])
                    }
                    if(edited_sku_json['placement_id'][current_sku.id] != original_sku_json['placement_id'][current_sku.id]){
                        edited_sku['placement_id'] = edited_sku_json['placement_id'][current_sku.id]
                    }
                }else if(current_sku && current_sku.component_file){
                    let component_file_changed = 0
					let top_level_element = comp_file_top_level_elem(current_sku)
					if(top_level_element){
                        if(!top_level_element.data){
                            top_level_element.data = {}
                        }
						// if(["cabinet", "corner_unit", "obj_unit"].includes(top_level_element.type)){
                            if(Number(edited_sku_json['height'][current_sku.id]) != Number(original_sku_json['height'][current_sku.id])){
                                top_level_element.data.height = Number(edited_sku_json['height'][current_sku.id])
                                component_file_changed = 1
                            }
                            if(Number(edited_sku_json['width'][current_sku.id]) != Number(original_sku_json['width'][current_sku.id])){
                                top_level_element.data.width = Number(edited_sku_json['width'][current_sku.id])
                                component_file_changed = 1
                            }
                            if(Number(edited_sku_json['depth'][current_sku.id]) != Number(original_sku_json['depth'][current_sku.id])){
                                top_level_element.data.depth = Number(edited_sku_json['depth'][current_sku.id])
                                component_file_changed = 1
                            }
                            if(edited_sku_json['placement_id'][current_sku.id] != original_sku_json['placement_id'][current_sku.id]){
                                top_level_element.data.attached_plane_type = edited_sku_json['placement_id'][current_sku.id]
                                component_file_changed = 1

								edited_sku['placement_id'] = edited_sku_json['placement_id'][current_sku.id]
                            }
                        // }
					}
                    if(component_file_changed) edited_sku['component_file'] = current_sku.component_file
                }

                //CBU
                if(['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) && current_sku.component_file && current_sku.component_file.length && original_sku_json['is_owned'][current_sku.id]){
                    let component_file_changed = 0
                    current_sku.component_file.map(o =>{
                        if(cbu_valid_types.includes(o.type)){
                            let new_cbu_value = edited_sku_json['is_CBU'] && edited_sku_json['is_CBU'][current_sku.id]
							
                            if(o.data.non_modular != new_cbu_value){
								console.log('edited_sku_json CBUU', new_cbu_value, o.data.non_modular)
                                o.data.non_modular = new_cbu_value
                                component_file_changed = 1
                            }
                            if(new_cbu_value && o.data.override_margin){
                                o.data.override_margin = false
                                component_file_changed = 1
                            }else if(o.data.override_margin != (edited_sku_json['is_OMC'] && edited_sku_json['is_OMC'][current_sku.id])){
                                o.data.override_margin = edited_sku_json['is_OMC'] && edited_sku_json['is_OMC'][current_sku.id]
                                component_file_changed = 1
                            }
                            if(new_cbu_value && compare_exclusion_list(current_sku.id)){
                                component_file_changed = 1
                                o.data.exclusion_list = edited_sku_json['exclusion_list'] && edited_sku_json['exclusion_list'][current_sku.id]
                            }
                        }
                    })
                    if(component_file_changed) edited_sku['component_file'] = current_sku.component_file
                }

                //tags
                let temp_tags = edited_sku_json['tag_ids'][current_sku.id] && edited_sku_json['tag_ids'][current_sku.id].length ? edited_sku_json['tag_ids'][current_sku.id].map(x => x.id) : []
				edited_sku['tag_ids'] = temp_tags

                //additional_properties
                if(edited_sku_json['default_properties_changed'][current_sku.id] || edited_sku_json['custom_properties_changed'][current_sku.id] || (edited_sku_json.not_priced[current_sku.id] != original_sku_json.not_priced[current_sku.id]) || (edited_sku_json.not_priced_text[current_sku.id] != original_sku_json.not_priced_text[current_sku.id])){
                    edited_sku['additional_properties'] = [...edited_sku_json['default_properties'][current_sku.id], ...edited_sku_json['custom_properties'][current_sku.id], {key: 'no_pricing_enabled', value: edited_sku_json['not_priced'][current_sku.id]}, {key: 'no_pricing_custom_text', value: edited_sku_json['not_priced_text'][current_sku.id]}]
                }

                // let current_not_priced_props = edited_sku_json['not_priced_properties'][current_sku.id]
                // while(current_not_priced_props && (current_not_priced_props.length >= 3))
                //         current_not_priced_props.pop()
                // let temp_arr = current_not_priced_props.filter(o => o.key === 'no_pricing_enabled')
                // console.log("kppp current_not_priced_props", current_not_priced_props, edited_sku_json)
                // if(temp_arr.length){
                //     temp_arr[0]['value'] = edited_sku_json['not_priced'][current_sku.id]
                // }else if(edited_sku_json['not_priced'][current_sku.id] || [0, false].includes(edited_sku_json['not_priced'][current_sku.id])){
                //     temp_arr.push({key: 'no_pricing_enabled', value: edited_sku_json['not_priced'][current_sku.id]})
                //     console.log("kppp temp_arr", temp_arr)
                // }
                // if(temp_arr.length){
				// 	edited_sku['additional_properties'] = [...edited_sku_json['default_properties'][current_sku.id], ...edited_sku_json['custom_properties'][current_sku.id]]
				// 	edited_sku['additional_properties'].push(temp_arr[0])
				// } 

                // let temp_arr1 = current_not_priced_props.filter(o => o.key === 'no_pricing_custom_text')
                // if(temp_arr1.length){
                //     temp_arr1[0]['value'] = edited_sku_json['not_priced_text'][current_sku.id]
                // }else if(edited_sku_json['not_priced_text'][current_sku.id]){
                //     temp_arr1.push({key: 'no_pricing_custom_text', value: edited_sku_json['not_priced_text'][current_sku.id]})
                // }
                // if(temp_arr1.length){
                //     if(!temp_arr.length)
				// 	    edited_sku['additional_properties'] = [...edited_sku_json['default_properties'][current_sku.id], ...edited_sku_json['custom_properties'][current_sku.id]]
				// 	edited_sku['additional_properties'].push(temp_arr1[0])
				// }

                //sales_channels
                let truly_new_sales_channels = []
                if(new_sales_channels_added && new_sales_channels_added.length && new_sales_channels_values_json && new_sales_channels_values_json[current_sku.id] && new_sales_channels_values_json[current_sku.id]){
                    new_sales_channels_added.map((single_sales_channel, idx1) => {
                        // console.log('new_sales_channels_values_json75', new_sales_channels_values_json, current_sku.id, single_sales_channel)
                        let temp_single_sales_channel = JSON.parse(JSON.stringify(new_sales_channels_values_json[current_sku.id][idx1]))
                        if(!(original_sku_json['sales_channels'][current_sku.id] && original_sku_json['sales_channels'][current_sku.id].length && original_sku_json['sales_channels'][current_sku.id].find(o => o.id == single_sales_channel.id))){
                            let price_types = JSON.parse(JSON.stringify(temp_single_sales_channel['price_types']))
                            if(price_types && price_types.length){
                                price_types.map((single_price_type, idx2) => {
                                    if(single_price_type['price'] || single_price_type['display_unit'] || single_price_type['tax'] || single_price_type['margin']){
                                        single_price_type['display_unit'] = single_price_type['display_unit'] ? single_price_type['display_unit'] : default_pricing_unit
                                        single_price_type['price'] = single_price_type['price'] ? calculate_actual_price(Number(single_price_type['price']), single_price_type['display_unit']) : 0
                                        single_price_type['tax'] = single_price_type['tax'] ? Number(single_price_type['tax']) : 0
                                        single_price_type['margin'] = single_price_type['margin'] ? Number(single_price_type['margin']) : 0
                                    }else{
                                        // console.log('dont update price type', current_sku.name)
                                        single_price_type['dont_update'] = 1
                                    }
                                })
                            }

                            temp_single_sales_channel.price_types = price_types && price_types.length ? price_types.filter(x => !x['dont_update']) : []
                            console.log('dont update price type 22', current_sku.name, single_sales_channel.price_types)
                            truly_new_sales_channels.push(temp_single_sales_channel)
                        }
                    })
                }

                let current_sales_channels = modifed_sales_channels_values_json && modifed_sales_channels_values_json[current_sku.id] ? JSON.parse(JSON.stringify(modifed_sales_channels_values_json[current_sku.id])) : []
                let truly_modified_sales_channels = []
                if(current_sales_channels && current_sales_channels.length){
                    current_sales_channels.map((single_sales_channel, idx2) => {
                        if(!single_sales_channel['checked_to_remove']){
                            let price_types = single_sales_channel['price_types']
                            price_types.map((single_price_type, idx3) => {
                                if(single_price_type['pricing_mode'] != existing_sales_channels_values_json[current_sku.id][idx2]['price_types'][idx3]['pricing_mode']){
                                    if(single_price_type['pricing_mode'] == 'default'){
                                        single_price_type['convert_to_default'] = true
                                    }else{
                                        single_price_type['display_unit'] = single_price_type['display_unit'] ? single_price_type['display_unit'] : default_pricing_unit
                                        single_price_type['price'] = single_price_type['price'] ? calculate_actual_price(Number(single_price_type['price']), single_price_type['display_unit']) : 0
                                        single_price_type['tax'] = single_price_type['tax'] ? Number(single_price_type['tax']) : 0
                                        single_price_type['margin'] = single_price_type['margin'] ? Number(single_price_type['margin']) : 0
                                    }
                                }else if(single_price_type['pricing_mode'] == 'custom'){
                                    if(single_price_type['price'] != existing_sales_channels_values_json[current_sku.id][idx2]['price_types'][idx3]['price'] || 
                                        single_price_type['display_unit'] != existing_sales_channels_values_json[current_sku.id][idx2]['price_types'][idx3]['display_unit'] || 
                                        single_price_type['tax'] != existing_sales_channels_values_json[current_sku.id][idx2]['price_types'][idx3]['tax'] || 
                                        single_price_type['margin'] != existing_sales_channels_values_json[current_sku.id][idx2]['price_types'][idx3]['margin'])
                                    {
                                        single_price_type['display_unit'] = single_price_type['display_unit'] ? single_price_type['display_unit'] : default_pricing_unit
                                        single_price_type['price'] = single_price_type['price'] ? calculate_actual_price(Number(single_price_type['price']), single_price_type['display_unit']) : 0
                                        single_price_type['tax'] = single_price_type['tax'] ? Number(single_price_type['tax']) : 0
                                        single_price_type['margin'] = single_price_type['margin'] ? Number(single_price_type['margin']) : 0
                                    }else{
                                        single_price_type['dont_update'] = 1
                                    }
                                }else if(single_price_type['pricing_mode'] == 'default'){
                                    single_price_type['dont_update'] = 1
                                }
                            })
                            single_sales_channel.price_types = price_types && price_types.length ? price_types.filter(x => !x['dont_update']) : []
                            truly_modified_sales_channels.push(single_sales_channel)
                        }
                    })
                }
                edited_sku['sales_channels'] = [...truly_new_sales_channels, ...truly_modified_sales_channels]

                if(sku_category_type === 'shutter_hardware' && edited_sku_json['pricing_from'][current_sku.id] != original_sku_json['pricing_from'][current_sku.id]){
                    edited_sku['pricing_from'] = edited_sku_json['pricing_from'][current_sku.id]
                }

				if(edited_sku_json['display_pic_id'][current_sku.id] != original_sku_json['display_pic_id'][current_sku.id]){
					edited_sku['display_pic_id'] = edited_sku_json['display_pic_id'][current_sku.id]
				}

				if(edited_sku_json['low_model_3d_id'][current_sku.id] != original_sku_json['low_model_3d_id'][current_sku.id]){
					edited_sku['low_model_3d_id'] = edited_sku_json['low_model_3d_id'][current_sku.id]
				}
                if(cbu_options[current_sku.id] && original_cbu_options[current_sku.id] && !(cbu_options[current_sku.id]['cbu'] == original_cbu_options[current_sku.id]['cbu'] && cbu_options[current_sku.id]['override_margin'] == original_cbu_options[current_sku.id]['override_margin'] && are_scalar_arrs_equal(cbu_options[current_sku.id]['exclusion_list'], original_cbu_options[current_sku.id]['exclusion_list']))){
                    edited_sku['cbu_options'] = {}
                    edited_sku['cbu_options']['cbu'] = cbu_options[current_sku.id]['cbu']
                    edited_sku['cbu_options']['override_margin'] = cbu_options[current_sku.id]['override_margin']
                    if(cbu_options[current_sku.id]['cbu']){
                        edited_sku['cbu_options']['exclusion_list'] = JSON.parse(JSON.stringify(cbu_options[current_sku.id]['exclusion_list'] && cbu_options[current_sku.id]['exclusion_list'].length && cbu_options[current_sku.id]['exclusion_list'].map(item => (item.id ? item.id : item))))
                    }
                }
                final_sku_array.push(edited_sku)
            }
        }
        set_edited_sku_array(final_sku_array)
        set_editing_individual_props(false)
        set_subcats_collapsed({})
        set_tabs(1)
        set_individual_props_confirmation_mode(true)
    }

    useEffect(() => {
        console.log('modifed_sales_channels_values_json', modifed_sales_channels_values_json)
    }, [modifed_sales_channels_values_json]);

    const publish_changes_and_exit = () => {
        create_attempt_and_trigger(edited_sku_array, properties_in_conformation_array.filter(x => x.modified).map(x => x.name))
    }

    const update_edited_sku_json = (property, sku_id, value, validateValue) => {
        if(validateValue){
            if(!validateValue(value)){
                return
            }
        }
        let a = JSON.parse(JSON.stringify(edited_sku_json))
        if(!a[property]) {
            a[property] = {}
        }
        a[property][sku_id] = value;
        if(property === 'low_model_3d_data'){
            a['low_model_3d_id'][sku_id] = value && value.id ? value.id : ''
        }
        set_edited_sku_json(a)
    }

    const update_cbu_options = (property, sku_id, value) => {
        let a = JSON.parse(JSON.stringify(cbu_options))
        if(!a[sku_id]) {
            a[sku_id] = {}
        }
        a[sku_id][property] = value;
        set_cbu_options(a)
    }

    const update_edited_sku_json_additional_props = (sku_id, default_properties, custom_properties, default_properties_changed, custom_properties_changed) => {
        let a = JSON.parse(JSON.stringify(edited_sku_json))
        a['default_properties'][sku_id] = default_properties
        a['custom_properties'][sku_id] = custom_properties
        a['default_properties_changed'][sku_id] = default_properties_changed
        a['custom_properties_changed'][sku_id] = custom_properties_changed
        set_edited_sku_json(a)
    }

	const update_edited_sku_json_display_pic = (sku_id, display_pic_id, display_pic_url) => {
		let a = JSON.parse(JSON.stringify(edited_sku_json))
		a['display_pic_id'][sku_id] = display_pic_id
		a['display_pic_url'][sku_id] = display_pic_url
		console.log('display_pic_url', display_pic_url,a)
		set_edited_sku_json(a)
	}
    
    const reset_property_edited_sku_json = (properties) => {
        let a = JSON.parse(JSON.stringify(edited_sku_json))
        if(properties && properties.length){
            for(let i=0;i<properties.length;i++){
                a[properties[i]] = JSON.parse(JSON.stringify(original_sku_json[properties[i]]))
            }
        }
        console.log("reset property", original_sku_json)
        set_edited_sku_json(a)
        if(properties.includes('is_CBU') && properties.includes('exclusion_list') && properties.includes('is_OMC')){
            let b = JSON.parse(JSON.stringify(original_cbu_options))
            set_cbu_options(b)
        }
    }

    const calculate_display_price = (qty, unit) => {
        if (!unit) {
            return qty;
        }

        if (unit.indexOf('sq_') > -1) {
            console.log('checking for sq')
            var actual_unit =  unit.split('sq_')[1];
            return calculate_display_price(calculate_display_price(qty, actual_unit), actual_unit)
        }

        if (unit.indexOf('cu_') > -1) {
            var actual_unit =  unit.split('cu_')[1];
            return calculate_display_price(calculate_display_price(calculate_display_price(qty, actual_unit), actual_unit), actual_unit)
        }

        if (unit == 'metre') {
            return qty * 1000;
        } else if (unit == 'foot') {
            return qty * 304.8;
        } else {
            return qty;
        }
    }

    //converts to mm
    const calculate_actual_price = (qty, unit) => {
        if (!unit) {
            return qty;
        }

        if (unit.indexOf('sq_') > -1) {
            var actual_unit =  unit.split('sq_')[1];
            return calculate_actual_price(calculate_actual_price(qty, actual_unit), actual_unit)
        }

        if (unit.indexOf('cu_') > -1) {
            var actual_unit =  unit.split('cu_')[1];
            return calculate_actual_price(calculate_actual_price(calculate_actual_price(qty, actual_unit), actual_unit), actual_unit)
        }

        if (unit == 'metre') {
            return qty / 1000;
        } else if (unit == 'foot') {
            return qty / 304.8;
        } else {
            return qty;
        }   
    }

	const close_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(false)
	}
	const open_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(true)
	}

    const compare_tags = (sku_id) => {
        let existing_tags = original_sku_json['tag_ids'] && original_sku_json['tag_ids'][sku_id] && original_sku_json['tag_ids'][sku_id].length ? original_sku_json['tag_ids'][sku_id].map(x => x.id) : []
        existing_tags = existing_tags.sort()
        let new_tags = edited_sku_json['tag_ids'][sku_id] && edited_sku_json['tag_ids'][sku_id].length ? edited_sku_json['tag_ids'][sku_id].map(x => x.id) : []
        new_tags = new_tags.sort()

        if(JSON.stringify(existing_tags) != JSON.stringify(new_tags)){ 
            // update_edited_sku_json('tags_changed', sku_id, true)
            return true
        }
        // update_edited_sku_json('tags_changed', sku_id, false)
        return false
    }

    const compare_exclusion_list = (sku_id) => {
        let original_exclusion_list = original_sku_json['exclusion_list'] && original_sku_json['exclusion_list'][sku_id] && original_sku_json['exclusion_list'][sku_id].length ? original_sku_json['exclusion_list'][sku_id].map(x => x.id):[]
        original_exclusion_list = original_exclusion_list.sort()
        let new_exclusion_list = edited_sku_json['exclusion_list'] && edited_sku_json['exclusion_list'][sku_id] && edited_sku_json['exclusion_list'][sku_id].length ? edited_sku_json['exclusion_list'][sku_id].map(x => x.id):[]
        new_exclusion_list = new_exclusion_list.sort()

        if(JSON.stringify(original_exclusion_list) != JSON.stringify(new_exclusion_list)) return true

        return false
    }

	const set_display_image = async(file, sku_id) => {
		if(file){
			try{
				if (file.size > 5 * 1024 * 1024) {
					alert.error(`Image size exceeds 5MB. Please choose a smaller image.`);
					return;
				}
                if(!is_valid_file_type(file, ['jpeg','jpg', 'png'])){
                    alert.error("Invalid file format! Please upload a file with a valid format(jpeg, jpg, png).")
                    return
                }
				let data = new FormData();
				data.append('upl', file)
				set_page_loader({
					show:true,
					text:'Please wait.. Uploading Display Image'
				})
				let response = await general_fetch({url: 'image/add', body:data, is_form_data:true, req_resp:true})
				if(response && response.data){
					update_edited_sku_json_display_pic(sku_id, response.data.id, response.data.image)
				} 
				set_page_loader({
					show:false
				})
			}catch(err){
				set_page_loader({
					show:false
				})
				console.log(err)
			}
		}else{

		}
	}

    const fetch_material_templates_fn = async() => {
        try{
            let resp = await fetch_material_templates();
            set_material_template(resp);
        }catch(err){
            alert.error("Error fetching Material templates");
        }
    }

    useEffect(() => {
        if(original_sku_array && original_sku_array.length && tags_at_all_levels){
        let temp_original_sku_json = {name:{}, description:{}, brand_id:{}, model_no:{}, order:{}, placement_id:{}, height:{}, width:{}, depth:{}, component_file:{}, default_properties:{}, custom_properties:{}, not_priced_properties:{}, not_priced:{}, not_priced_text:{}, default_properties_changed:{}, custom_properties_changed:{}, tag_ids:{}, tags_changed:{}, is_owned:{}, sales_channels:{}, is_CBU:{}, is_OMC:{}, exclusion_list:{}, pricing_from:{}, display_pic_id:{}, display_pic_url:{}, low_model_3d_id:{}, material_id:{}}
            for(let i = 0; i < original_sku_array.length; i++){
                
                // temp_original_sku_json[original_sku_array[i].id] = { 
                //                                                     id: original_sku_array[i].id, 
                //                                                     name: original_sku_array[i].name, 
                //                                                     model_no: original_sku_array[i].model_no, 
                //                                                     placement_id: original_sku_array[i].placement_id, 
                //                                                     brand_id: original_sku_array[i].brand_id,
                //                                                     height: original_sku_array[i].height, 
                //                                                     width: original_sku_array[i].width,
                //                                                     depth: original_sku_array[i].depth,
                //                                                     component_file: original_sku_array[i].component_file,
                //                                                     additional_properties: original_sku_array[i].additional_properties,
                //                                                     tag_ids: tags_at_all_levels[original_sku_array[i].id].sku_tags
                //                                                    }
                temp_original_sku_json['name'][original_sku_array[i].id] = original_sku_array[i].name
				temp_original_sku_json['description'][original_sku_array[i].id] = original_sku_array[i].description
                temp_original_sku_json['brand_id'][original_sku_array[i].id] = original_sku_array[i].brand_id ? original_sku_array[i].brand_id : 'none'
                temp_original_sku_json['model_no'][original_sku_array[i].id] = original_sku_array[i].model_no ? original_sku_array[i].model_no : ''
				temp_original_sku_json['order'][original_sku_array[i].id] = original_sku_array[i].order ? original_sku_array[i].order : 0
                if(original_sku_array[i].component_file && original_sku_array[i].component_file.length){
                    let element = comp_file_top_level_elem(original_sku_array[i])
                    temp_original_sku_json['height'][original_sku_array[i].id] = (element && element.data && element.data.height) ? element.data.height :undefined
                    temp_original_sku_json['width'][original_sku_array[i].id] = (element && element.data && element.data.width) ? element.data.width :undefined
                    temp_original_sku_json['depth'][original_sku_array[i].id] = (element && element.data && element.data.depth) ? element.data.depth :undefined
					let placement = (element && element.data && element.data.attached_plane_type) ? element.data.attached_plane_type :''
					if(!placement){
						if(original_sku_array[i].placement_id){
							placement = original_sku_array[i].placement_id
						}else{
							placement = 'floor'
						}
					}
					if(placement === 'base' || placement === 'base_corner' || placement === 'default'){
						placement = 'floor'
					}else if(placement === 'wall_corner'){
						placement = 'wall'
					}
                    temp_original_sku_json['placement_id'][original_sku_array[i].id] = placement

                    //cbu and omc
                    element = original_sku_array[i].component_file.find(o => cbu_valid_types.includes(o.type));
                    let is_cbu = element && element.data && element.data.non_modular ? element.data.non_modular :false;
			        let is_omc = element && element.data && element.data.override_margin ? element.data.override_margin :false;
                    let exclusion_list = element && element.data ? element.data.exclusion_list : [];
                    temp_original_sku_json['is_CBU'][original_sku_array[i].id] = is_cbu
                    temp_original_sku_json['is_OMC'][original_sku_array[i].id] = is_omc
                    temp_original_sku_json['exclusion_list'][original_sku_array[i].id] = exclusion_list
                }else{
                    temp_original_sku_json['height'][original_sku_array[i].id] = original_sku_array[i].height
                    temp_original_sku_json['width'][original_sku_array[i].id] = original_sku_array[i].width
                    temp_original_sku_json['depth'][original_sku_array[i].id] = original_sku_array[i].depth
					let placement = original_sku_array[i].placement_id
					if(!placement) placement = 'floor'
					if(placement === 'base' || placement === 'base_corner' || placement === 'default'){
						placement = 'floor'
					}else if(placement === 'wall_corner'){
						placement = 'wall'
					}
                    temp_original_sku_json['placement_id'][original_sku_array[i].id] = placement
                }
                temp_original_sku_json['component_file'][original_sku_array[i].id] = original_sku_array[i].component_file
                // temp_original_sku_json['additional_properties'][original_sku_array[i].id] = original_sku_array[i].additional_properties ? original_sku_array[i].additional_properties : []
                let temp_additional_props = original_sku_array[i].additional_properties ? original_sku_array[i].additional_properties : []
                let temp_default_props = [], temp_custom_props = [], temp_not_priced_props = [], temp_not_priced = false, temp_not_priced_text = ''
                if(temp_additional_props && temp_additional_props.length){
                    for(let j=0;j<temp_additional_props.length;j++){
                        if(all_allowed_key[temp_additional_props[j].key]){
                            temp_default_props.push(temp_additional_props[j])
                        }else if(temp_additional_props[j].key === 'no_pricing_enabled' || temp_additional_props[j].key === 'no_pricing_custom_text'){
                            temp_not_priced_props.push(temp_additional_props[j])
                            if(temp_additional_props[j].key === 'no_pricing_enabled' && temp_additional_props[j].value){
                                temp_not_priced = true
                            }
                            if(temp_additional_props[j].key === 'no_pricing_custom_text'){
                                temp_not_priced_text = temp_additional_props[j].value
                            }
                        }/*else if(temp_additional_props[j].key.toLowerCase() === 'description'){

						}*/else{
                            temp_custom_props.push(temp_additional_props[j])
                        }
                    }
                }
                temp_original_sku_json['default_properties'][original_sku_array[i].id] = temp_default_props
                temp_original_sku_json['custom_properties'][original_sku_array[i].id] = temp_custom_props
                temp_original_sku_json['not_priced_properties'][original_sku_array[i].id] = temp_not_priced_props
                temp_original_sku_json['not_priced'][original_sku_array[i].id] = temp_not_priced
                temp_original_sku_json['not_priced_text'][original_sku_array[i].id] = temp_not_priced_text
                temp_original_sku_json['default_properties_changed'][original_sku_array[i].id] = false
                temp_original_sku_json['custom_properties_changed'][original_sku_array[i].id] = false
                temp_original_sku_json['tag_ids'][original_sku_array[i].id] = tags_at_all_levels[original_sku_array[i].id] ? tags_at_all_levels[original_sku_array[i].id].sku_tags : []
                temp_original_sku_json['tags_changed'][original_sku_array[i].id] = false
                temp_original_sku_json['is_owned'][original_sku_array[i].id] = original_sku_array[i].business_unit_id === user.current_business_unit_id ? true : false
                // temp_original_sku_json['is_owned'][original_sku_array[i].id] = original_sku_array[i].store_id === user.store_id ? true : false
                temp_original_sku_json['sales_channels'][original_sku_array[i].id] = original_sales_channels[original_sku_array[i].id] ? original_sales_channels[original_sku_array[i].id] : [] 
                if(sku_category_type === 'shutter_hardware') {
                    temp_original_sku_json['pricing_from'][original_sku_array[i].id] = original_sku_array[i]['pricing_from']
                }
				if(is_allow_material){
					temp_original_sku_json['material_id'][original_sku_array[i].id] = original_sku_array[i].material_id
				}else{
					temp_original_sku_json['display_pic_id'][original_sku_array[i].id] = original_sku_array[i].display_pic_id
					temp_original_sku_json['display_pic_url'][original_sku_array[i].id] = original_sku_array[i].display_pic ? original_sku_array[i].display_pic.image : ''
					temp_original_sku_json['low_model_3d_id'][original_sku_array[i].id] = original_sku_array[i].low_model_3d_id
				}
                if(original_sku_array[i].modular)
                    temp_original_sku_json['modular'][original_sku_array[i].id] = true
            }
            set_original_sku_json(temp_original_sku_json)
            set_edited_sku_json(temp_original_sku_json)
        }
    }, [original_sku_array, tags_at_all_levels, original_sales_channels]);

    useEffect(() => {
        if(original_sku_array && original_sku_array.length && original_sales_channels && original_sales_channels[original_sku_array[0].id] && original_prices && original_prices[original_sku_array[0].id]){
            let temp_sales_channels_values_json = {}
            let temp=0
            let cbu_options_temp = {}
            original_sku_array.map((single_sku, idx1) => {
                let cur_sales_channels_data = original_sales_channels && original_sales_channels[single_sku.id] ? JSON.parse(JSON.stringify(original_sales_channels[single_sku.id])) : []
                if(cur_sales_channels_data && cur_sales_channels_data.length){
                    cur_sales_channels_data.map((single_sales_channel, idx2) => {
                        let cur_price_types = single_sales_channel.price_types
                        if(cur_price_types && cur_price_types.length){
                            cur_price_types.map((single_price_type, idx3) => {
                                temp += 1
                                single_price_type['pricing_mode'] = (original_prices[single_sku.id]['sales_channel_prices'][single_sales_channel.id][single_price_type.id].actual == false || original_prices[single_sku.id]['sales_channel_prices'][single_sales_channel.id][single_price_type.id].immediate_price.use_referred_price) ? 'default' : 'custom'
                                
                                let inherited_price = original_prices[single_sku.id] && original_prices[single_sku.id]['sales_channel_prices'] && original_prices[single_sku.id]['sales_channel_prices'][single_sales_channel.id] ? original_prices[single_sku.id]['sales_channel_prices'][single_sales_channel.id][single_price_type.id].inherited_price : {}
                                let immediate_price = original_prices[single_sku.id] && original_prices[single_sku.id]['sales_channel_prices'] && original_prices[single_sku.id]['sales_channel_prices'][single_sales_channel.id] ? original_prices[single_sku.id]['sales_channel_prices'][single_sales_channel.id][single_price_type.id].immediate_price : {}
                                single_price_type['display_unit_default_mode'] = inherited_price['display_unit']
                                single_price_type['display_unit'] = immediate_price['display_unit']
                                single_price_type['price_default_mode'] = inherited_price ? (Math.round(calculate_display_price(inherited_price.price, inherited_price.display_unit) * 10000000000) / 10000000000) : 0
                                single_price_type['price'] = immediate_price ? (Math.round(calculate_display_price(immediate_price.price, immediate_price.display_unit) * 10000000000) / 10000000000) : 0
                                single_price_type['tax_default_mode'] = inherited_price['tax']
                                single_price_type['tax'] = immediate_price['tax']
                                single_price_type['margin_default_mode'] = inherited_price['margin']
                                single_price_type['margin'] = immediate_price['margin']
                                cbu_options_temp[single_sku.id] = {}
                                cbu_options_temp[single_sku.id]['cbu'] = immediate_price['cbu']
                                cbu_options_temp[single_sku.id]['override_margin'] = immediate_price['override_margin']
                                cbu_options_temp[single_sku.id]['exclusion_list'] = immediate_price['exclusion_list']
                            })
                        }
                    })
                    temp_sales_channels_values_json[single_sku.id] = cur_sales_channels_data
                }
            })
            set_cbu_options(cbu_options_temp)
            let cbu_options_temp1 = JSON.parse(JSON.stringify(cbu_options_temp))
            if(cbu_options_temp1['exclusion_list'])
                cbu_options_temp1['exclusion_list'] = JSON.parse(JSON.stringify(cbu_options_temp1['exclusion_list']))
            set_original_cbu_options(cbu_options_temp1)
            set_existing_sales_channels_values_json(temp_sales_channels_values_json)
            set_modifed_sales_channels_values_json(temp_sales_channels_values_json)
            set_no_of_price_types_modifying(temp)
        }
    }, [original_sku_array, original_sales_channels, original_prices]);

    useEffect(() => {
        if(original_sales_channels && bulk_selected_hierarchical_data && bulk_selected_hierarchical_data.length){
            let temp_json_per_group = {}
            let temp_json_per_sku = {}
            bulk_selected_hierarchical_data.map((single_sub_category, idx1) => {
                let sku_groups = single_sub_category.sku_groups
                if(sku_groups && sku_groups.length){
                    sku_groups.map((single_group, idx2) => {
                        let skus = single_group.sku
                        let count_per_group = 0
                        if(skus && skus.length){
                            skus.map((single_sku, idx3) => {
                                let count_per_sku = 0
                                let sales_channels = original_sales_channels[single_sku.id]
                                if(sales_channels && sales_channels.length){
                                    sales_channels.map((single_sales_channel, idx4) => {
                                        count_per_sku += single_sales_channel['price_types'].length
                                        count_per_group += single_sales_channel['price_types'].length
                                    })
                                }
                                temp_json_per_sku[single_sku.id] = count_per_sku
                            })
                        }
                        temp_json_per_group[single_group.id] = count_per_group
                    })
                }
            })
            set_no_of_price_types_modifying_per_group(temp_json_per_group)
            set_no_of_price_types_modifying_per_sku(temp_json_per_sku)
        }
    }, [original_sales_channels, bulk_selected_hierarchical_data]);

    useEffect(() => {
        if(new_sales_channels_added && new_sales_channels_added.length){
            let temp=0
            let temp_json = {}
            for(let i=0; i<new_sales_channels_added.length; i++){
                temp += new_sales_channels_added[i].price_types ? new_sales_channels_added[i].price_types.length : 0 
            }
            set_no_of_price_types_new(temp)

            // let existing_sales_channels = new_sales_channels_values_json && new_sales_channels_values_json[original_sku_array[0].id] && new_sales_channels_values_json[original_sku_array[0].id].length ? new_sales_channels_values_json[original_sku_array[0].id] : []
            // let truly_new_sales_channels = new_sales_channels_added && new_sales_channels_added.length ? new_sales_channels_added.filter(x => !(existing_sales_channels && existing_sales_channels.length && existing_sales_channels.find(o => o.id === x.id))) : []
            // let removed_sales_channels = existing_sales_channels && existing_sales_channels.length ? existing_sales_channels.filter(x => !(new_sales_channels_added && new_sales_channels_added.length && new_sales_channels_added.find(o => o.id === x.id))) : []

            let a = JSON.parse(JSON.stringify(new_sales_channels_values_json))

            if(original_sku_array && original_sku_array.length){
                original_sku_array.map((single_sku, idx) => {
                    let cur_sales_channel_data = a[single_sku.id]
                    // cur_sales_channel_data = [...cur_sales_channel_data, truly_new_sales_channels]
                    // a[single_sku.id] = cur_sales_channel_data && cur_sales_channel_data.length ? cur_sales_channel_data.filter(x => !(removed_sales_channels && removed_sales_channels.length && removed_sales_channels.find(o => original_sku_array.id === x.id))) : []
                    let new_sales_channels_data = []
                    new_sales_channels_added.map((single_sales_channel) => {
                        new_sales_channels_data.push(cur_sales_channel_data && cur_sales_channel_data.length && cur_sales_channel_data.find(o => o.id === single_sales_channel.id) ? cur_sales_channel_data.find(o => o.id === single_sales_channel.id) : single_sales_channel)
                    })
                    a[single_sku.id] = new_sales_channels_data
                })
                set_new_sales_channels_values_json(a)
            }
        }else{
            set_no_of_price_types_new(0)
            set_new_sales_channels_values_json({})
        }

    }, [new_sales_channels_added]);

    useEffect(() => {
        console.log("new_sales_channels_values_json", new_sales_channels_values_json)
    }, [new_sales_channels_values_json]);

    // useEffect(() => {
    //     console.log('show_additional_props_modal', show_additional_props_modal)
    // }, [show_additional_props_modal]);

    useEffect(() => {
        console.log("modifed_sales_channels_values_json", modifed_sales_channels_values_json, existing_sales_channels_values_json)
    }, [modifed_sales_channels_values_json]);

    // Default UseEffect
    useEffect(() => {
        fetch_material_templates_fn()
    }, []);

    // useEffect(() => {
    //     console.log('edited_sku_json', edited_sku_json, original_sku_json)
    // }, [edited_sku_json]);

    useEffect(() => {
        if(individual_props_confirmation_mode && bulk_selected_hierarchical_data && bulk_selected_hierarchical_data.length){
            let temp_hierarchical_data_sku_name_model = []
            let temp_hierarchical_data_sku_dimensions_placement = []
            let temp_hierarchical_data_sku_brands = []
            let temp_hierarchical_data_sku_tags = []
            let temp_hierarchical_data_additional_props = []
			let temp_hierarchical_data_dp_3dmodel_material = []

            for(let i=0;i<bulk_selected_hierarchical_data.length;i++){
                let single_sub_category = JSON.parse(JSON.stringify(bulk_selected_hierarchical_data[i]))
                let included_groups_in_subcat_name_model = {sku_groups:[], id:single_sub_category.id, name:single_sub_category.name}
                let included_groups_in_subcat_dimensions_placement = {sku_groups:[], id:single_sub_category.id, name:single_sub_category.name}
                let included_groups_in_subcat_brands = {sku_groups:[], id:single_sub_category.id, name:single_sub_category.name}
                let included_groups_in_subcat_tags = {sku_groups:[], id:single_sub_category.id, name:single_sub_category.name}
                let included_groups_in_subcat_additional_props = {sku_groups:[], id:single_sub_category.id, name:single_sub_category.name}
				let included_groups_in_subcat_dp_3dmodel_material = {sku_groups:[], id:single_sub_category.id, name:single_sub_category.name}
                if(single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length){
                    for(let j=0;j<single_sub_category.sku_groups.length;j++){
                        let single_group = JSON.parse(JSON.stringify(single_sub_category.sku_groups[j]));
                        let included_skus_in_group_name_model = {sku:[], id:single_group.id, name: single_group.name}
                        let included_skus_in_group_dimensions_placement = {sku:[], id:single_group.id, name: single_group.name}
                        let included_skus_in_group_brands = {sku:[], id:single_group.id, name: single_group.name}
                        let included_skus_in_group_tags = {sku:[], id:single_group.id, name: single_group.name}
                        let included_skus_in_group_additional_props = {sku:[], id:single_group.id, name: single_group.name}
						let included_skus_in_group_dp_3dmodel_material = {sku:[], id:single_group.id, name: single_group.name}
                        if(single_group && single_group.sku && single_group.sku.length){
                            for(let k=0;k<single_group.sku.length;k++){
                                let single_sku = single_group.sku[k]
                                if(original_sku_json['name'][single_sku.id] != edited_sku_json['name'][single_sku.id] || original_sku_json['description'][single_sku.id] != edited_sku_json['description'][single_sku.id] || original_sku_json['model_no'][single_sku.id] != edited_sku_json['model_no'][single_sku.id] || original_sku_json['order'][single_sku.id] != edited_sku_json['order'][single_sku.id]){
                                    included_skus_in_group_name_model.sku.push(single_sku)
                                }
                                if(original_sku_json['height'][single_sku.id] != edited_sku_json['height'][single_sku.id] || original_sku_json['width'][single_sku.id] != edited_sku_json['width'][single_sku.id] || original_sku_json['depth'][single_sku.id] != edited_sku_json['depth'][single_sku.id] || original_sku_json['placement_id'][single_sku.id] != edited_sku_json['placement_id'][single_sku.id]){
                                    included_skus_in_group_dimensions_placement.sku.push(single_sku)
                                }
                                if(original_sku_json['brand_id'][single_sku.id] != edited_sku_json['brand_id'][single_sku.id]){
                                    included_skus_in_group_brands.sku.push(single_sku)
                                }
                                if(compare_tags(single_sku.id)){
                                    included_skus_in_group_tags.sku.push(single_sku)
                                }
                                if(edited_sku_json['default_properties_changed'][single_sku.id] || edited_sku_json['custom_properties_changed'][single_sku.id]){
                                    included_skus_in_group_additional_props.sku.push(single_sku)
                                }
								if(original_sku_json['low_model_3d_id'][single_sku.id] != edited_sku_json['low_model_3d_id'][single_sku.id] || original_sku_json['display_pic_id'][single_sku.id] != edited_sku_json['display_pic_id'][single_sku.id] || original_sku_json['material_id'][single_sku.id] != edited_sku_json['material_id'][single_sku.id]){
									included_skus_in_group_dp_3dmodel_material.sku.push(single_sku)
                                }
                            }
                            if(included_skus_in_group_name_model.sku && included_skus_in_group_name_model.sku.length){
                                included_groups_in_subcat_name_model.sku_groups.push(included_skus_in_group_name_model)
                            }
                            if(included_skus_in_group_dimensions_placement.sku && included_skus_in_group_dimensions_placement.sku.length){
                                included_groups_in_subcat_dimensions_placement.sku_groups.push(included_skus_in_group_dimensions_placement)
                            }
                            if(included_skus_in_group_brands.sku && included_skus_in_group_brands.sku.length){
                                included_groups_in_subcat_brands.sku_groups.push(included_skus_in_group_brands)
                            }
                            if(included_skus_in_group_tags.sku && included_skus_in_group_tags.sku.length){
                                included_groups_in_subcat_tags.sku_groups.push(included_skus_in_group_tags)
                            }
                            if(included_skus_in_group_additional_props.sku && included_skus_in_group_additional_props.sku.length){
                                included_groups_in_subcat_additional_props.sku_groups.push(included_skus_in_group_additional_props)
                            }
							if(included_skus_in_group_dp_3dmodel_material.sku && included_skus_in_group_dp_3dmodel_material.sku.length){
								included_groups_in_subcat_dp_3dmodel_material.sku_groups.push(included_skus_in_group_dp_3dmodel_material)
							}
                        }
                    }
                    if(included_groups_in_subcat_name_model.sku_groups && included_groups_in_subcat_name_model.sku_groups.length){
                        temp_hierarchical_data_sku_name_model.push(included_groups_in_subcat_name_model)
                    }
                    if(included_groups_in_subcat_dimensions_placement.sku_groups && included_groups_in_subcat_dimensions_placement.sku_groups.length){
                        temp_hierarchical_data_sku_dimensions_placement.push(included_groups_in_subcat_dimensions_placement)
                    }
                    if(included_groups_in_subcat_brands.sku_groups && included_groups_in_subcat_brands.sku_groups.length){
                        temp_hierarchical_data_sku_brands.push(included_groups_in_subcat_brands)
                    }
                    if(included_groups_in_subcat_tags.sku_groups && included_groups_in_subcat_tags.sku_groups.length){
                        temp_hierarchical_data_sku_tags.push(included_groups_in_subcat_tags)
                    }
                    if(included_groups_in_subcat_additional_props.sku_groups && included_groups_in_subcat_additional_props.sku_groups.length){
                        temp_hierarchical_data_additional_props.push(included_groups_in_subcat_additional_props)
                    }
					if(included_groups_in_subcat_dp_3dmodel_material.sku_groups && included_groups_in_subcat_dp_3dmodel_material.sku_groups.length){
						temp_hierarchical_data_dp_3dmodel_material.push(included_groups_in_subcat_dp_3dmodel_material)
					}
                }
            }
            set_hierarchical_data_sku_name_model(temp_hierarchical_data_sku_name_model)
            set_hierarchical_data_sku_dimensions_placement(temp_hierarchical_data_sku_dimensions_placement)
            set_hierarchical_data_sku_brands(temp_hierarchical_data_sku_brands)
            set_hierarchical_data_sku_tags(temp_hierarchical_data_sku_tags)
            set_hierarchical_data_additional_props(temp_hierarchical_data_additional_props)
			set_hierarchical_data_dp_3dmodel_material(temp_hierarchical_data_dp_3dmodel_material)
        }
    }, [individual_props_confirmation_mode, bulk_selected_hierarchical_data]);

    const SKU_Name_Model_Table = (hierarchical_data) => {
        return (
            hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
                <div key={'SKU_Name_Model_Table'+idx}>
                    <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'name_model', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['name_model'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['name_model']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
                    {
                        subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['name_model']?
                        <table key={'table'+idx} style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            <tbody key={'tablebody'+idx} style={{width:'100%'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr key={'tr1'+idx+idx1+idx2} className='tr_style_bulk_edit'>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'20%'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td key={'td1'+idx+idx1+idx2} style={{backgroundColor:'#FAFAFA', width:'16%'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}<span style={{color:'#A7A8B2'}}>{!edited_sku_json['is_owned'][single_sku.id]?'\xa0'+'(Not Owned)':''}</span></td>
                                            <td key={'td2'+idx+idx1+idx2} className='td_style_bulk_edit' style={{width:'16%', padding:'0px'}}>
                                                <Input key={'test1'+idx+idx1+idx2} disabled={!edited_sku_json['is_owned'][single_sku.id]} type='text' 
                                                value={edited_sku_json['name'][single_sku.id]} onChange = {(e) => update_edited_sku_json('name', single_sku.id, e.target.value, (value) => {if(value.length > 200){alert.error("Name too long. SKU name cannot have more than 200 characters"); return false} return true})} 
                                                className='input_class' style={{border:edited_sku_json['name'][single_sku.id] != original_sku_json['name'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                            </td>
                                            <td key={'td3'+idx+idx1+idx2} className='td_style_bulk_edit' style={{width:'16%', padding:'0px'}}>
                                                <Input key={'test2'+idx+idx1+idx2} disabled={!edited_sku_json['is_owned'][single_sku.id]} type='text' 
                                                value={edited_sku_json['description'][single_sku.id]} onChange = {(e) => update_edited_sku_json('description', single_sku.id, e.target.value, (value) => {if(value.length > 200){alert.error("Description too long. Description cannot have more than 200 characters"); return false} return true})} 
                                                className='input_class' style={{border:edited_sku_json['description'][single_sku.id] != original_sku_json['description'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                            </td>
                                            <td key={'td4'+idx+idx1+idx2} className='td_style_bulk_edit' style={{width:'16%', padding:'0px'}}>
                                                <Input key={'test3'+idx+idx1+idx2} disabled={!edited_sku_json['is_owned'][single_sku.id]} type='text' 
                                                value={edited_sku_json['model_no'][single_sku.id]} onChange = {(e) => update_edited_sku_json('model_no', single_sku.id, e.target.value, (value) => {if(value.length > 50){alert.error("Model No too long. Model No cannot have more than 50 characters"); return false} return true})} 
                                                className='input_class' style={{border:edited_sku_json['model_no'][single_sku.id] != original_sku_json['model_no'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                            </td>
											<td key={'td5'+idx+idx1+idx2} className='td_style_bulk_edit' style={{width:'16%', padding:'0px'}}>
                                                <Input key={'test4'+idx+idx1+idx2} disabled={!edited_sku_json['is_owned'][single_sku.id]} type='text' 
                                                value={edited_sku_json['order'][single_sku.id]} onChange = {(e) => update_edited_sku_json('order', single_sku.id, e.target.value)} 
                                                className='input_class' style={{border:edited_sku_json['order'][single_sku.id] != original_sku_json['order'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                            </td>
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
                    }
                </div>
            )):''
        )
    }

    const SKU_Dimension_Placement_Table = (hierarchical_data) => {
        return(
            hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
                <>
                    <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'dimensions_placement', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['dimensions_placement'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['dimensions_placement']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
                    {
                        subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['dimensions_placement']?
                        <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            <tbody style={{width:'100%'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr className='tr_style_bulk_edit'>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td style={{backgroundColor:'#FAFAFA'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}<span style={{color:'#A7A8B2'}}>{!edited_sku_json['is_owned'][single_sku.id]?'\xa0'+'(Not Owned)':''}</span></td>
                                            {/* <td className='td_style_bulk_edit' style={{width:'30%', padding:'0px'}}>
                                                <Input type='text' value={edited_sku_json['name'][single_sku.id]} onChange = {(e) => update_edited_sku_json('name', single_sku.id, e.target.value)} className='input_class'/>
                                            </td>
                                            <td className='td_style_bulk_edit' style={{width:'10%', padding:'0px'}}>
                                                <Input type='text' value={edited_sku_json['model_no'][single_sku.id]} onChange = {(e) => update_edited_sku_json('model_no', single_sku.id, e.target.value)} className='input_class'/>
                                            </td> */}
                                            <div className='flex_property' style={{width:active_division_id == 'finish'?'100%':''}}>
                                                {
                                                    is_allow_material?
                                                    <div className='flex_property' style={{width:'100%'}}>
                                                        <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                            <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['height'][single_sku.id]} onChange = {(e) => update_edited_sku_json('height', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['height'][single_sku.id] != original_sku_json['height'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                        </td>
                                                        {
                                                            is_allow_material_and_depth?
                                                            <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                                <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['depth'][single_sku.id]} onChange = {(e) => update_edited_sku_json('depth', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['depth'][single_sku.id] != original_sku_json['depth'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                            </td>:''
                                                        }
                                                        {
                                                            sku_category_type == 'tile_finish' ?
                                                            <>
                                                                <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                                    <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['width'][single_sku.id]} onChange = {(e) => update_edited_sku_json('width', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['width'][single_sku.id] != original_sku_json['width'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                                </td>
                                                                <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                                    <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['depth'][single_sku.id]} onChange = {(e) => update_edited_sku_json('depth', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['depth'][single_sku.id] != original_sku_json['depth'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                                </td>
                                                            </>:''
                                                        }
                                                    </div>:
                                                    <div className='flex_property' style={{width:'100%'}}>
                                                        <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                            <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['height'][single_sku.id]} onChange = {(e) => update_edited_sku_json('height', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['height'][single_sku.id] != original_sku_json['height'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                        </td>
                                                        <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                            <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['width'][single_sku.id]} onChange = {(e) => update_edited_sku_json('width', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['width'][single_sku.id] != original_sku_json['width'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                        </td>
                                                        <td className='td_style_bulk_edit' style={{width:'100%', padding:'0px'}}>
                                                            <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='number' value={edited_sku_json['depth'][single_sku.id]} onChange = {(e) => update_edited_sku_json('depth', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['depth'][single_sku.id] != original_sku_json['depth'][single_sku.id] ? '1px solid #23C16B' : ''}}/>
                                                        </td>
                                                    </div>
                                                }
                                                {
                                                    active_division_id != 'finish'?
                                                    <>
                                                        <td className='td_style_bulk_edit' style={{padding:'0px'}}>
                                                            <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='select' value={edited_sku_json['placement_id'][single_sku.id]} onChange = {(e) => update_edited_sku_json('placement_id', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['placement_id'][single_sku.id] != original_sku_json['placement_id'][single_sku.id] ? '1px solid #23C16B' : ''}}>
                                                                {/* <option value='none'>None</option> */}
                                                                <option value='floor'>Base</option>
                                                                {/* <option value='base_corner'>Base Corner</option> */}
                                                                <option value='wall'>Wall</option>
                                                                {/* <option value='wall_corner'>Corner Wall</option> */}
                                                                <option value='ceiling'>Ceiling</option>
                                                                {/* <option value='default'>Default</option> */}
                                                            </Input>
                                                        </td>
                                                    </>:''
                                                }
                                            </div>
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
                    }
                </>
            )):''
        )
    }

    const SKU_Brands_Table = (hierarchical_data) => {
        return (
            hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
                <>
                    <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'brands', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['brands'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['brands']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
                    {
                        subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['brands']?
                        <table style={{width:'100%'}}>
                            <tbody style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr className='tr_style_bulk_edit'>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td style={{backgroundColor:'#FAFAFA'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}<span style={{color:'#A7A8B2'}}>{!edited_sku_json['is_owned'][single_sku.id]?'\xa0'+'(Not Owned)':''}</span></td>
                                            <td className='td_style_bulk_edit' style={{width:'40%', padding:'0px'}}>
                                                <Input disabled={!edited_sku_json['is_owned'][single_sku.id]} type='select' value={edited_sku_json['brand_id'][single_sku.id]} onChange = {(e) => update_edited_sku_json('brand_id', single_sku.id, e.target.value)} className='input_class' style={{border:edited_sku_json['brand_id'][single_sku.id] != original_sku_json['brand_id'][single_sku.id] ? '1px solid #23C16B' : ''}}>
                                                    <option value='none'>None</option>
                                                    {
                                                        all_brands && all_brands.length ? all_brands.map((list_value, idx)=>
                                                            <option key={idx} value={list_value.id}>{list_value.name}</option>
                                                        ):''
                                                    }
                                                </Input>
                                            </td>
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
                    }
                </>
            )):''
        )
    }

    const SKU_Tags_Table = (hierarchical_data) => {
        return(
            hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
                <>
                    <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'tag_ids', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['tag_ids'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['tag_ids']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
                    {
                        subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['tag_ids']?
                        <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            <tbody style={{width:'100%'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr className='tr_style_bulk_edit'>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td style={{backgroundColor:'#FAFAFA'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}</td>
                                            <td className='td_style_bulk_edit' style={{width:'40%', padding:'0px'}}>
                                                <Multiselect
                                                    style={{border:compare_tags(single_sku.id) ? '1px solid #23C16B' : ''}}
                                                    options={eligible_tags && eligible_tags.length ? single_sku.store_id === user.store_id ? eligible_tags : eligible_tags.filter(x => x.store_id === user.store_id) : []}
                                                    selectedValues = {edited_sku_json['tag_ids'][single_sku.id]}
                                                    onSelect = {(selectedList) =>{update_edited_sku_json('tag_ids', single_sku.id, selectedList)}}
                                                    onRemove = {(selectedList) =>{update_edited_sku_json('tag_ids', single_sku.id, selectedList)}}
                                                    displayValue = "tag" // Property name
                                                    placeholder = "Select Tags" // Property placeholder
                                                />
                                            </td>
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
                    }
                </>
            )):''
        )
    }

    const Additional_Properties_Table = (hierarchical_data) => {
        return(
            hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
                <>
                    <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'additional_props', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['additional_props'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['additional_props']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
                    {
                        subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['additional_props']?
                        <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            <tbody style={{width:'100%'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr className='tr_style_bulk_edit' style={{pointerEvents:edited_sku_json['is_owned'][single_sku.id]?'auto':'none'}}>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'25%'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td style={{backgroundColor:'#FAFAFA', width:'25%'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}<span style={{color:'#A7A8B2'}}>{!edited_sku_json['is_owned'][single_sku.id]?'\xa0'+'(Not Owned)':''}</span></td>
                                            <td className='td_style_bulk_edit' style={{width:'50%'}}>
                                                <div className='flex_property' style={{justifyContent:'space-between'}} onClick={() => {set_modal_default_properties(edited_sku_json['default_properties'][single_sku.id]); set_modal_custom_properties(edited_sku_json['custom_properties'][single_sku.id]); set_active_sku_id(single_sku.id); set_show_additional_props_modal(true)}}>
                                                    <div>
                                                        <span>
                                                            <span className='additional_props_row' style={{color:edited_sku_json['default_properties_changed'][single_sku.id]?'#23C16B':(edited_sku_json['default_properties'][single_sku.id].length ? '' : '#A7A8B3')}}>{edited_sku_json['default_properties'][single_sku.id].length} Default Properties</span> 
                                                            {'\xa0\xa0\xa0\xa0'+'|'+'\xa0\xa0\xa0\xa0'}
                                                            <span className='additional_props_row' style={{color:edited_sku_json['custom_properties_changed'][single_sku.id]?'#23C16B':(edited_sku_json['custom_properties'][single_sku.id].length ? '' : '#A7A8B3')}}>{edited_sku_json['custom_properties'][single_sku.id].length} Custom Properties</span>
                                                        </span>
                                                    </div>
                                                    {edited_sku_json['is_owned'][single_sku.id]?<i className='fa fa-pencil-alt additional_props_row'/>:''}
                                                </div>
                                            </td>
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
                    }
                </>
            )):''
        )
    }

	const Dp_3DModel_Material_Table = (hierarchical_data) => {
        
		return(
			hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
				<>
					<div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'dp_3dmodel_material', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['dp_3dmodel_material'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['dp_3dmodel_material']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
					{
						subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['dp_3dmodel_material']?
                        <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            <tbody style={{width:'100%'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr className='tr_style_bulk_edit'>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'25%'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td style={{backgroundColor:'#FAFAFA', width:'25%'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}<span style={{color:'#A7A8B2'}}>{!edited_sku_json['is_owned'][single_sku.id]?'\xa0'+'(Not Owned)':''}</span></td>
                                            {
												is_allow_material?
												<td className='td_style_bulk_edit' style={{width:'50%'}}>
                                                    <TriggerAddMaterial material_template={material_template} set_page_loader={set_page_loader} update_edited_sku_json={update_edited_sku_json} edited_sku_json={edited_sku_json} sku_id={single_sku.id}/>
												</td>
												:
												<>
													<td className='td_style_bulk_edit' style={{width:'25%', boxShadow:edited_sku_json['display_pic_id'][single_sku.id] != original_sku_json['display_pic_id'][single_sku.id] ? 'inset 0 0 0 1px #23C16B' : ''}}>
														{
															edited_sku_json['display_pic_url'] && edited_sku_json['display_pic_url'][single_sku.id]? 
															<>
																<img src={global.config.server_path.slice(0,-1)+edited_sku_json['display_pic_url'][single_sku.id]} width='32px' height='32px' className='ml-3' style={{cursor:'pointer'}}></img>
																{/* <i className='fa fa-trash ml-3 cp' onClick={() => {set_display_image_file(null); set_display_pic_id('')}} style={{color:'red'}}/> */}
															</>
															:<img src={global.config.sku_image_not_found} width='32px' height='32px' className='ml-3'/>
														}
														<Button disabled={!edited_sku_json['is_owned'][single_sku.id]} style={{marginLeft:'32px'}} className="blue_button" onClick = {() => {document.getElementById('individual_property_editing_display_pic'+single_sku.id).click()}}>{'Upload file'}</Button>
														<Input id={'individual_property_editing_display_pic'+single_sku.id} type="file" onChange={(e) => {set_display_image(e.target.files[0], single_sku.id)}} style={{display:"none"}} accept='.jpg,.jpeg,.png'/>
													</td>
													<td className='td_style_bulk_edit' style={{width:'25%', boxShadow:edited_sku_json['low_model_3d_id'][single_sku.id] != original_sku_json['low_model_3d_id'][single_sku.id] ? 'inset 0 0 0 1px #23C16B' : ''}}>
														<Button disabled={!edited_sku_json['is_owned'][single_sku.id]} style={{}} className="blue_button ml-3" onClick = {() => {open_Upload3d_modal_modal(); set_active_sku_id(single_sku.id)}}>{'Upload 3D Model'}</Button>
														{
															edited_sku_json['low_model_3d_id'][single_sku.id] ? 
															<span style={{color:'gray'}} className='ml-3'>Uploaded</span>:""
														}
													</td>
												</>
											}
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
					}
				</>
			)):''
		)
	}

    const Advanced_pricing_table = (hierarchical_data) => {
        return (
            hierarchical_data && hierarchical_data.length ? hierarchical_data.map((single_sub_category, idx) => (
                <>
                    <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'advanced_pricing_options', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['advanced_pricing_options'] : true)}>
                        <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                        <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['advanced_pricing_options']?'fa fa-caret-up':'fa fa-caret-down'} />
                    </div>
                    {
                        subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['advanced_pricing_options']?
                        <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                            <tbody style={{width:'100%'}}>
                            {
                                single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                    single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                        <tr className='tr_style_bulk_edit'>
                                            {idx2==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'20%'}} rowSpan={single_group.sku ? single_group.sku.length : 1}>{single_group.name}</td>:''}
                                            <td style={{backgroundColor:'#FAFAFA', width:'20%'}} className='td_style_bulk_edit'>{single_sku ? single_sku.name : ''}<span style={{color:'#A7A8B2'}}>{!edited_sku_json['is_owned'][single_sku.id]?'\xa0'+'(Not Owned)':''}</span></td>
                                            {
                                                ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type)?
                                                <>
                                                    <td className='td_style_bulk_edit' style={{width:'20%', boxShadow: (cbu_options[single_sku.id] && original_cbu_options[single_sku.id] && (cbu_options[single_sku.id]['cbu'] != original_cbu_options[single_sku.id]['cbu'] || (!are_scalar_arrs_equal(cbu_options[single_sku.id]['exclusion_list'], original_cbu_options[single_sku.id] && original_cbu_options[single_sku.id]['exclusion_list'])))) ? 'inset  0 0 0 1px #23C16B' : ''}}>
                                                        <div className={'flex_property ' + ((!edited_sku_json['is_owned'][single_sku.id] || edited_sku_json['not_priced'][single_sku.id]) ? 'disable_and_reduce_opacity' : '')}>
                                                            <input type='checkbox' checked = {cbu_options[single_sku.id] && cbu_options[single_sku.id]['cbu']} className='mr-3' onClick={() => {update_cbu_options('cbu', single_sku.id, !(cbu_options[single_sku.id] && cbu_options[single_sku.id]['cbu']))}}/>
                                                            <span className='mr-2'>Exclude</span>
                                                            <Multiselect 
                                                                style= {{maxWidth:'200px', border:(!are_scalar_arrs_equal(cbu_options[single_sku.id] && cbu_options[single_sku.id]['exclusion_list'], original_cbu_options[single_sku.id] && original_cbu_options[single_sku.id]['exclusion_list'])) ? '1px solid #23C16B' : ''}}
                                                                options={exclusion_list_options && exclusion_list_options.length? JSON.parse(JSON.stringify(exclusion_list_options)):[]}
                                                                selectedValues = {exclusion_list_options && exclusion_list_options.length && exclusion_list_options.filter(item => (cbu_options[single_sku.id] && cbu_options[single_sku.id]['exclusion_list'] && cbu_options[single_sku.id]['exclusion_list'].includes(item.id)) || (cbu_options[single_sku.id] && cbu_options[single_sku.id]['exclusion_list'] && cbu_options[single_sku.id]['exclusion_list'].length && cbu_options[single_sku.id]['exclusion_list'].find(item1 => item1.id == item.id)))}
                                                                onSelect = {(selectedList) =>{update_cbu_options('exclusion_list', single_sku.id, selectedList)}}
                                                                onRemove = {(selectedList) =>{update_cbu_options('exclusion_list', single_sku.id, selectedList)}}
                                                                displayValue = "name" // Property name
                                                                placeholder = "Select items" // Property placeholder
                                                                disable={!(cbu_options[single_sku.id] && cbu_options[single_sku.id]['cbu'])}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className='td_style_bulk_edit' style={{width:'20%', boxShadow: cbu_options[single_sku.id] && original_cbu_options[single_sku.id] && (cbu_options[single_sku.id]['override_margin'] != original_cbu_options[single_sku.id]['override_margin']) ? 'inset  0 0 0 1px #23C16B' : ''}}>
                                                        <div className={'flex_property ' + ((!edited_sku_json['is_owned'][single_sku.id] || edited_sku_json['not_priced'][single_sku.id]) ? 'disable_and_reduce_opacity' : '')}>
                                                            <input type='checkbox' checked = {cbu_options[single_sku.id] && cbu_options[single_sku.id]['override_margin']} style={{marginRight:'8px'}}  onClick={() => {if(cbu_options[single_sku.id])update_cbu_options('override_margin', single_sku.id, !cbu_options[single_sku.id]['override_margin'])}}/>
                                                            <span>Override Margin Of Constituents</span>
                                                        </div>
                                                    </td>
                                                </>:""
                                            }
                                            {
                                                sku_category_type === 'shutter_hardware'?
                                                <td className='td_style_bulk_edit' style={{width:'30%', boxShadow: edited_sku_json['pricing_from'][single_sku.id] != original_sku_json['pricing_from'][single_sku.id] ? 'inset  0 0 0 1px #23C16B' : ''}}>
                                                    <Row className={!edited_sku_json['is_owned'][single_sku.id] ? 'disable_and_reduce_opacity' : ''} onChange={(e) => {update_edited_sku_json('pricing_from', single_sku.id, e.target.value)}} >
                                                        <Col xs={3} style={{display:'flex', alignItems:'center',justifyContent:'flex-start', paddingRight:0, minWidth:'200px'}}>
                                                            <Input value='constituents' checked={edited_sku_json['pricing_from'][single_sku.id]==='constituents'?true:false} style={{position:'unset', margin:'0px 10px 0px 0px'}} name={'shutter_price_flag'+single_sku.id} type='radio'></Input>
                                                            <span >from Constituents</span>
                                                        </Col>
                                                        <Col xs={3} style={{display:'flex', alignItems:'center',justifyContent:'flex-start', paddingRight:0, minWidth:'200px'}}>
                                                            <Input value='design' checked={edited_sku_json['pricing_from'][single_sku.id]==='design'?true:false} style={{position:'unset', margin:'0px 10px 0px 0px'}} name={'shutter_price_flag'+single_sku.id} type='radio'></Input>
                                                            <span >from Shutter Design</span>
                                                        </Col>
                                                    </Row>
                                                </td>:''
                                            }
                                            <td className='td_style_bulk_edit' style={{width:'20%', boxShadow: edited_sku_json['not_priced'][single_sku.id] != original_sku_json['not_priced'][single_sku.id] ? 'inset  0 0 0 1px #23C16B' : ''}}>
                                                <div className={'flex_property ' + ((!edited_sku_json['is_owned'][single_sku.id] || (cbu_options[single_sku.id] && cbu_options[single_sku.id]['cbu']) || (cbu_options[single_sku.id] && cbu_options[single_sku.id]['override_margin'])) ? 'disable_and_reduce_opacity' : '')}>
                                                    <input type='checkbox' className='mr-3' checked={edited_sku_json['not_priced'][single_sku.id]} onClick={() => {update_edited_sku_json('not_priced', single_sku.id, !edited_sku_json['not_priced'][single_sku.id])}}/>
                                                    <span className='mr-2'>Text</span>
                                                    <Input type='text' disabled={!edited_sku_json['not_priced'][single_sku.id]} className='standard_input' value={edited_sku_json['not_priced_text'][single_sku.id]} onChange={(e) => {update_edited_sku_json('not_priced_text', single_sku.id, e.target.value)}} style={{boxShadow: edited_sku_json['not_priced_text'][single_sku.id] != original_sku_json['not_priced_text'][single_sku.id] ? 'inset  0 0 0 1px #23C16B' : ''}} />
                                                </div>
                                            </td>
                                        </tr>
                                    )):''
                                )):''
                            }
                            </tbody>
                        </table>:''
                    }
                </>
            )):''
        )
    }

    return (
        <>
            <AdditionalPropsModal
                open={show_additional_props_modal}
                handleClose = {() => {set_show_additional_props_modal(false); set_modal_default_properties([]); set_modal_custom_properties([]); set_active_sku_id('')}}
                modal_default_properties = {modal_default_properties}
                modal_custom_properties = {modal_custom_properties}
                active_sku_id = {active_sku_id}
                update_edited_sku_json_additional_props = {update_edited_sku_json_additional_props}
                original_sku_json = {original_sku_json}
                sku_category_type={sku_category_type}
            />
			<Upload3d_modal_modal
				open={show_Upload3d_modal_modal} 
				handleclose={close_Upload3d_modal_modal} 
				set_return_confirm_low_resp_data={(data) => {update_edited_sku_json('low_model_3d_data', active_sku_id, data)}}
				// set_low_model_3d_id = {(low_model_3d_id) => update_edited_sku_json('low_model_3d_id', active_sku_id, low_model_3d_id)}
				active_division_id={active_division_id}
				set_page_loader={set_page_loader}
                decimate={true}
			/>
            <CardBody style={{backgroundColor:'#F5F6F8', height:'calc(100% - 84px)'}}>
                <div className='flex_property' style={{justifyContent:'space-between', backgroundColor:'white', height:'40px', fontSize:'15px'}}>
                    <span className='flex_property'>
                        <span className='flex_property' style={{backgroundColor:'rgba(103, 104, 120, 0.1)', height:'24px', fontWeight:'900', margin:'0px 4px 0px 8px', padding:'4px', borderRadius:'4px'}}>{individual_props_confirmation_mode?'Confirm edit changes ':'Editing Individual SKUs '}</span>
                        <span> {' from Total of'} <span style={{fontWeight:'900'}}>{original_sku_array ? original_sku_array.length : 0} SKUs</span> selected from, <span style={{fontWeight:'900'}}>{sub_cats_included.length} {sub_cats_included.length==1 ? 'SubCategory' : 'SubCategories'}</span>, in Category<span style={{fontWeight:'900'}}> {sku_category_name}</span></span>
                    </span>
                </div>
                <div className="nav-wrapper" style={{padding:'2px 8px', marginTop:'20px', backgroundColor:'white', fontSize:'15px'}}>
                    <Nav 
                        style={{display:'flex', alignItems:'center'}}
                        className="flex-md-row"
                        id="tabs-icons-text" 
                        pills
                        role="tablist"
                    >   
                        <NavItem>
                            <NavLink
                                aria-selected={tabs === 1}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: tabs === 1
                                })}
                                onClick={e => toggleNavs(e, 1)}
                                href="#pablo"
                                role="tab"
                            >
                                <span>SKU Properties</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={tabs === 2}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: tabs === 2
                                })}
                                onClick={e => toggleNavs(e, 2)}
                                href="#pablo"
                                role="tab"
                            >
                                <span>Brands</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={tabs === 3}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: tabs === 3
                                })}
                                onClick={e => toggleNavs(e, 3)}
                                href="#pablo"
                                role="tab"
                            >
                                <span>Tags</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={tabs === 4}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: tabs === 4
                                })}
                                onClick={e => toggleNavs(e, 4)}
                                href="#pablo"
                                role="tab"
                            >
                                <span>Sales Channels</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={tabs === 5}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: tabs === 5
                                })}
                                onClick={e => toggleNavs(e, 5)}
                                href="#pablo"
                                role="tab"
                            >
                                <span>Additional Properties</span>
                            </NavLink>
                        </NavItem>
						{
							// !is_allow_material?
							<NavItem>
								<NavLink
									aria-selected={tabs === 6}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 6
									})}
									onClick={e => toggleNavs(e, 6)}
									href="#pablo"
									role="tab"
								>
									<span>{!is_allow_material ? 'Display Image / 3D Model' : 'Material'}</span>
								</NavLink>
							</NavItem>
                            // :""
						}
                    </Nav>
                </div>
                <TabContent key='tabcontent' activeTab={"tabs" + tabs} style={{height:'85%', fontSize:'15px'}}>
                    <TabPane key='tabpane_for_sku_props' tabId="tabs1" className='editing_all_skus_tabpane'>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>{individual_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of SKU Properties</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['name', 'order', 'description', 'model_no', 'height', 'width', 'depth', 'placement_id'])}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>SKU Name, Description, Model No & Viewing Order <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {
                                individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['name', 'order', 'description', 'model_no'])}}>Reset Changes</span>
                            }
                        </div>
                        <div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit' style={{width:'20%'}}>SKU Groups</th>
                                            <th className='th_style_bulk_edit' style={{width:'16%'}}>SKUs</th>
                                            <th className='th_style_bulk_edit' style={{width:'16%'}}>SKU Name</th>
											<th className='th_style_bulk_edit' style={{width:'16%'}}>Description</th>
											<th className='th_style_bulk_edit' style={{width:'16%'}}>Model No.</th>
											<th className='th_style_bulk_edit' style={{width:'16%'}}>Viewing Order</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        
                        {
                            individual_props_confirmation_mode ? SKU_Name_Model_Table(hierarchical_data_sku_name_model) : SKU_Name_Model_Table(bulk_selected_hierarchical_data)
                        }
                        
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>{active_division_id != 'finish' ? 'SKU Dimensions & Placement' : 'SKU Dimensions'} <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['height', 'width', 'depth', 'placement_id'])}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit'>SKU Groups</th>
                                            <th className='th_style_bulk_edit'>SKUs</th>
                                            <div className='flex_property' style={{width:active_division_id == 'finish'?'100%':''}}>
                                            {
                                                is_allow_material?
                                                <div className='flex_property' style={{width:'100%'}}>
                                                    <th className='th_style_bulk_edit flex_property' style={{width:'100%'}}>Thickness</th>
                                                    {
                                                        is_allow_material_and_depth?
                                                        <th className='th_style_bulk_edit' style={{width:'100%'}}>{sku_category_type==='edge_band_hardware'?'Tape Width':'Height'}</th>:''
                                                    }
                                                    {
                                                        sku_category_type == 'tile_finish' ?
                                                        <>
                                                            <th className='th_style_bulk_edit flex_property' style={{width:'100%'}}>Width</th>
                                                            <th className='th_style_bulk_edit flex_property' style={{width:'100%'}}>Height</th>
                                                        </>:''
                                                    }
                                                </div>:
                                                <div className='flex_property' style={{width:'100%'}}>
                                                    <th className='th_style_bulk_edit flex_property' style={{width:'100%'}}>{sku_category_type === 'shutter_hardware' ? 'Height' : 'Height'}</th>
                                                    <th className='th_style_bulk_edit flex_property' style={{width:'100%'}}>Width</th>
                                                    <th className='th_style_bulk_edit flex_property' style={{width:'100%'}}>{sku_category_type === 'shutter_hardware' ? 'Thickness' : 'Depth'}</th>
                                                </div>
                                            }
                                            {
                                                active_division_id != 'finish'?
                                                <>
                                                    <th className='th_style_bulk_edit flex_property'>Placement</th>
                                                </>:''
                                            }
                                            </div>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        {
                            individual_props_confirmation_mode ? SKU_Dimension_Placement_Table(hierarchical_data_sku_dimensions_placement) : SKU_Dimension_Placement_Table(bulk_selected_hierarchical_data)
                        }
                        </div>
                    </TabPane>
                    <TabPane tabId="tabs2" className='editing_all_skus_tabpane'>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>{individual_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of Brands</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['brand_id'])}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Modify / Add / Remove Brands <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['brand_id'])}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit'>SKU Groups</th>
                                            <th className='th_style_bulk_edit'>SKUs</th>
                                            <th className='th_style_bulk_edit' style={{width:'40%'}}>Brands</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        {
                            individual_props_confirmation_mode ? SKU_Brands_Table(hierarchical_data_sku_brands) : SKU_Brands_Table(bulk_selected_hierarchical_data)
                        }
                        </div>
                    </TabPane>
                    <TabPane tabId="tabs3" className='editing_all_skus_tabpane'>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>{individual_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of Tags</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['tag_ids'])}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Modify / Add / Remove Tags <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['tag_ids'])}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit'>SKU Groups</th>
                                            <th className='th_style_bulk_edit'>SKUs</th>
                                            <th className='th_style_bulk_edit' style={{width:'40%'}}>Tags</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        {
                            individual_props_confirmation_mode ? SKU_Tags_Table(hierarchical_data_sku_tags) : SKU_Tags_Table(bulk_selected_hierarchical_data)
                        }
                        </div>
                    </TabPane>
                    <TabPane tabId="tabs4" className='editing_all_skus_tabpane'>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>{individual_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of Sales Channels</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_new_sales_channels_added([]);reset_modifed_sales_channels_values_json_checkboxes();set_modifed_sales_channels_values_json(existing_sales_channels_values_json)}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Map New Sales Channel <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_new_sales_channels_added([])}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                            Select the Sales Channels to be added
                            <div style={{marginTop:"4px", pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                                <Multiselect
                                    options={all_sales_channels && all_sales_channels.length ? all_sales_channels : []}
                                    selectedValues = {new_sales_channels_added}
                                    onSelect = {(selectedList) =>{set_new_sales_channels_added(selectedList)}}
                                    onRemove = {(selectedList) =>{set_new_sales_channels_added(selectedList)}}
                                    displayValue = "name" // Property name
                                    placeholder = "Select Sales Channels" // Property placeholder
                                />
                            </div>

                            {
                                no_of_price_types_new?
                                <div className='table_header' style={{width:'100%', marginTop:'16px'}}>
                                    <table style={{width:'100%'}}>
                                        <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                            <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                                <th className='th_style_bulk_edit' style={{width:'20%'}}>SKU Groups</th>
                                                <th className='th_style_bulk_edit' style={{width:'20%'}}>SKUs</th>
                                                <th className='th_style_bulk_edit' style={{width:'18%'}}>Added Sales Channels</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Price Types</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Price</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Unit</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Tax%</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Margin%</th>
												<th className='th_style_bulk_edit' style={{width:'7%', backgroundColor:'rgb(255, 250, 230)'}}>Selling Price</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>:''
                            }
                            {
                                no_of_price_types_new && bulk_selected_hierarchical_data && bulk_selected_hierarchical_data.length ? bulk_selected_hierarchical_data.map((single_sub_category, idx) => (
                                    <>
                                        <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'sales_channels_map', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['sales_channels_map'] : true)}>
                                            <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                                            <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['sales_channels_map']?'fa fa-caret-up':'fa fa-caret-down'} />
                                        </div>
                                        {
                                            subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['sales_channels_map']?
                                            <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                                                <tbody style={{width:'100%'}}>
                                                {
                                                    single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                                        single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                                            new_sales_channels_added && new_sales_channels_added.length ? new_sales_channels_added.map((single_sales_channel, idx3) => (
                                                                single_sales_channel && single_sales_channel.price_types && single_sales_channel.price_types.length ? single_sales_channel.price_types.map((single_price_type, idx4) => (
                                                                    <tr className='tr_style_bulk_edit'>
                                                                        {idx2==0 && idx3==0 && idx4==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'20%'}} rowSpan={single_group.sku ? single_group.sku.length*no_of_price_types_new : 1}>{single_group.name}</td>:''}
                                                                        {idx3==0 && idx4==0 ? <td style={{backgroundColor:'#FAFAFA', width:'20%'}} className='td_style_bulk_edit' rowSpan={no_of_price_types_new ? no_of_price_types_new : 1} >{single_sku ? single_sku.name : ''}</td>:''}
                                                                        {idx4==0 ? <td style={{backgroundColor:'#FAFAFA', width:'18%'}} className='td_style_bulk_edit' rowSpan={single_sales_channel && single_sales_channel.price_types ? single_sales_channel.price_types.length : 1} >{single_sales_channel ? single_sales_channel.name : ''}<span style={{opacity:'0.5'}}>{original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id) ? ' (Mapped already)' : ''}</span></td>:''}
                                                                        <td style={{width:'7%', backgroundColor:'#FAFAFA'}} className='td_style_bulk_edit'>{single_price_type ? single_price_type.name : ''}</td>
                                                                        <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                            <Input 
                                                                                disabled={original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id)} 
                                                                                value={new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['price'] ? new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['price'] : ''} 
                                                                                style={{border:new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['price'] ? '1px solid #23C16B' : '', backgroundColor:original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id) ? '#FAFAFA' : ''}}
                                                                                onChange={(e) => {Number(e.target.value) >=0 ? update_new_sales_channels_values_json(single_sku.id, idx3, idx4, 'price', e.target.value) : alert.error("Negative numbers are not allowed!")}} type='number' className='input_class'/>
                                                                        </td>
                                                                        <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                            <Input 
                                                                                disabled={original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id)} 
                                                                                value={new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['display_unit'] ? new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['display_unit'] : default_pricing_unit} 
                                                                                style={{border:new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['display_unit'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['display_unit'] != default_pricing_unit ? '1px solid #23C16B' : '', backgroundColor:original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id) ? '#FAFAFA' : ''}}
                                                                                onChange={(e) => {update_new_sales_channels_values_json(single_sku.id, idx3, idx4, 'display_unit', e.target.value)}} type='select' className='input_class'
                                                                            >
                                                                            {
                                                                                // default_pricing_dimension && default_pricing_dimension=='per_unit_area'?
                                                                                // <>
                                                                                //     <option value='sq_millimetre'>sq_millimetre</option>
                                                                                //     <option value='sq_metre'>sq_metre</option>
                                                                                //     <option value='sq_foot'>sq_foot</option>
                                                                                // </>
                                                                                // :
                                                                                // (
                                                                                //     default_pricing_dimension && default_pricing_dimension=='per_unit_length'?
                                                                                //     <>
                                                                                //         <option value='millimetre'>millimetre</option>
                                                                                //         <option value='metre'>metre</option>
                                                                                //         <option value='foot'>foot</option>
                                                                                //     </>:
                                                                                //     <option value='none'>per_unit</option>
                                                                                // )
                                                                                pricing_dimension_options && pricing_dimension_options.length ? pricing_dimension_options.map(single_option => (
                                                                                    <option value={single_option.id}>{single_option.id}</option>
                                                                                )):''
                                                                            }
                                                                            </Input>
                                                                        </td>
                                                                        <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                            <Input 
                                                                                disabled={original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id)} 
                                                                                value={new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['tax']>=0 ? new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['tax'] : ''} 
                                                                                style={{border:new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['tax'] ? '1px solid #23C16B' : '', backgroundColor:original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id) ? '#FAFAFA' : ''}}
                                                                                onChange={(e) => {Number(e.target.value) >=0 ? update_new_sales_channels_values_json(single_sku.id, idx3, idx4, 'tax', e.target.value) : alert.error("Negative numbers are not allowed!")}} type='number' className='input_class'
																			/>
                                                                        </td>
                                                                        <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                            <Input 
                                                                                disabled={original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id)} 
                                                                                value={new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['margin']>=0 ? new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['margin'] : ''}
                                                                                style={{border:new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['margin'] ? '1px solid #23C16B' : '', backgroundColor:original_sku_json['sales_channels'][single_sku.id] && original_sku_json['sales_channels'][single_sku.id].length && original_sku_json['sales_channels'][single_sku.id].find(o => o.id == single_sales_channel.id) ? '#FAFAFA' : ''}}
                                                                                onChange={(e) => {Number(e.target.value) >=0 ? update_new_sales_channels_values_json(single_sku.id, idx3, idx4, 'margin', e.target.value) : alert.error("Negative numbers are not allowed!")}} type='number' className='input_class'
																			/>
                                                                        </td>
																		<td style={{width:'7%', padding:'0px', backgroundColor:'rgb(255, 250, 230)'}} className='td_style_bulk_edit'>
																			{
																				new_sales_channels_values_json && new_sales_channels_values_json[single_sku.id] && new_sales_channels_values_json[single_sku.id][idx3] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'] && new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]?
																				<div className='ml-3'>{new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].price  ? (((new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].price ? Number(new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].price) : 0) / (1 - (new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].margin ? Number(new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].margin) : 0) / 100)) * (1 + (new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].tax ? Number(new_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4].tax) : 0) / 100)).toFixed(2) : ''}</div>:''
																			}
																		</td>
                                                                    </tr>
                                                                )):''
                                                            )):''
                                                        )):''
                                                    )):''
                                                }
                                                </tbody>
                                            </table>:''
                                        }
                                    </>
                                )):''
                            }
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Unmap Sales Channel</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_modifed_sales_channels_values_json_checkboxes()}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                        {
                            no_of_price_types_modifying ? 
                            <>
                                <div className='table_header' style={{width:'100%', marginTop:'16px'}}>
                                    <table style={{width:'100%'}}>
                                        <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                            <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                                <th className='th_style_bulk_edit' style={{width:'16%'}}>SKU Groups</th>
                                                <th className='th_style_bulk_edit' style={{width:'16%'}}>SKUs</th>
                                                <th className='th_style_bulk_edit' style={{width:'16%'}}>Remove Sales Channels</th>
                                                <th className='th_style_bulk_edit' style={{width:'10%'}}>Price Types</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Pricing Mode</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Price</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Unit</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Tax%</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Margin%</th>
												<th className='th_style_bulk_edit' style={{width:'7%', backgroundColor:'rgb(255, 250, 230)'}}>Selling Price</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                {
                                    bulk_selected_hierarchical_data && bulk_selected_hierarchical_data.length ? bulk_selected_hierarchical_data.map((single_sub_category, idx) => (
                                        <>
                                            <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'sales_channels_unmap', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['sales_channels_unmap'] : true)}>
                                                <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                                                <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['sales_channels_unmap']?'fa fa-caret-up':'fa fa-caret-down'} />
                                            </div>
                                            {
                                                subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['sales_channels_unmap']?
                                                <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                                                    <tbody style={{width:'100%'}}>
                                                    {
                                                        single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                                            no_of_price_types_modifying_per_group[single_group.id] && single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                                                no_of_price_types_modifying_per_sku[single_sku.id] && modifed_sales_channels_values_json && modifed_sales_channels_values_json[single_sku.id] && modifed_sales_channels_values_json[single_sku.id].length ? modifed_sales_channels_values_json[single_sku.id].map((single_sales_channel, idx3) => (
                                                                    single_sales_channel && single_sales_channel.price_types && single_sales_channel.price_types.length ? single_sales_channel.price_types.map((single_price_type, idx4) => (
                                                                        <tr className='tr_style_bulk_edit'>
                                                                            {idx2==0 && idx3==0 && idx4==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'16%'}} rowSpan={no_of_price_types_modifying_per_group[single_group.id] ? no_of_price_types_modifying_per_group[single_group.id]  : 1}>{single_group.name}</td>:''}
                                                                            {idx3==0 && idx4==0 ? <td style={{backgroundColor:'#FAFAFA', width:'16%'}} className='td_style_bulk_edit' rowSpan={no_of_price_types_modifying_per_sku[single_sku.id] ? no_of_price_types_modifying_per_sku[single_sku.id] : 1} >{single_sku ? single_sku.name : ''}</td>:''}
                                                                            {idx4==0 ? <td style={{width:'16%'}} className='td_style_bulk_edit' rowSpan={single_sales_channel && single_sales_channel.price_types ? single_sales_channel.price_types.length : 1} >
                                                                                <div className='flex_property'>
                                                                                    <input type='checkbox' style={{marginRight:'8px'}} checked={single_sales_channel['checked_to_remove']} onClick={() => {update_modifed_sales_channels_values_json_checkbox(single_sku.id, idx3, !(single_sales_channel['checked_to_remove']))}}/>
                                                                                    <span>{single_sales_channel ? single_sales_channel.name : ''}</span>
                                                                                </div>
                                                                            </td>:''}
                                                                            <td style={{width:'10%', backgroundColor:'#FAFAFA'}} className='td_style_bulk_edit'>{single_price_type ? single_price_type.name : ''}</td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input  
                                                                                    value={single_price_type['pricing_mode'] == 'default' ? 'Default' : 'Custom'} 
                                                                                    style = {{backgroundColor:'#FAFAFA', PointerEvents:'none'}} type = 'text'
                                                                                    className='input_class'
																					disabled
                                                                                />
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? single_price_type['price_default_mode'] : single_price_type['price']} 
                                                                                    style = {{backgroundColor:'#FAFAFA', PointerEvents:'none'}} type = 'text'
                                                                                    className='input_class'
																					disabled
                                                                                />
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? (single_price_type['display_unit_default_mode'] ? single_price_type['display_unit_default_mode'] : default_pricing_unit) : (single_price_type['display_unit'] ? single_price_type['display_unit'] : default_pricing_unit)}
                                                                                    style = {{backgroundColor:'#FAFAFA', PointerEvents:'none'}} type = 'text'
                                                                                    className='input_class'
																					disabled
                                                                                />
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? single_price_type['tax_default_mode'] : single_price_type['tax']} 
                                                                                    style = {{backgroundColor:'#FAFAFA', PointerEvents:'none'}} type = 'text'
                                                                                    className='input_class'    
																					disabled
                                                                                />
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? single_price_type['margin_default_mode'] : single_price_type['margin']} 
                                                                                    style = {{backgroundColor:'#FAFAFA', PointerEvents:'none'}} type = 'text'
                                                                                    className='input_class' 
																					disabled
                                                                                />
                                                                            </td>
																			<td style={{width:'7%', padding:'0px', backgroundColor:'rgb(255, 250, 230)'}} className='td_style_bulk_edit'>
																				<div className='ml-3'>{(((single_price_type.price ? Number(single_price_type.price) : 0) / (1 - (single_price_type.margin ? Number(single_price_type.margin) : 0) / 100)) * (1 + (single_price_type.tax ? Number(single_price_type.tax) : 0) / 100)).toFixed(2)}</div>
																			</td>
                                                                        </tr>
                                                                    )):''
                                                                )):''
                                                            )):''
                                                        )):''
                                                    }
                                                    </tbody>
                                                </table>:''
                                            }
                                        </>
                                    )):''
                                }
                            </>:<span>None of the selected SKUs have mapped Sales Channels</span>
                        }
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Modify Sales Channel Values <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_modifed_sales_channels_values_json(existing_sales_channels_values_json)}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                        {
                            no_of_price_types_modifying ? 
                            <>
                                <div className='table_header' style={{width:'100%', marginTop:'16px'}}>
                                    <table style={{width:'100%'}}>
                                        <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                            <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                                <th className='th_style_bulk_edit' style={{width:'16%'}}>SKU Groups</th>
                                                <th className='th_style_bulk_edit' style={{width:'16%'}}>SKUs</th>
                                                <th className='th_style_bulk_edit' style={{width:'16%'}}>Sales Channels</th>
                                                <th className='th_style_bulk_edit' style={{width:'10%'}}>Price Types</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Pricing Mode</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Price</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Unit</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Tax%</th>
                                                <th className='th_style_bulk_edit' style={{width:'7%'}}>Margin%</th>
												<th className='th_style_bulk_edit' style={{width:'7%', backgroundColor:'rgb(255, 250, 230)'}}>Selling Price</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                {
                                    bulk_selected_hierarchical_data && bulk_selected_hierarchical_data.length ? bulk_selected_hierarchical_data.map((single_sub_category, idx) => (
                                        <>
                                            <div className='single_sub_category' onClick={() => update_subcats_collapsed(single_sub_category.id, 'sales_channels_modify', subcats_collapsed[single_sub_category.id] ? !subcats_collapsed[single_sub_category.id]['sales_channels_modify'] : true)}>
                                                <span>Sub Category: <span style={{fontWeight:'900'}}>{'\xa0'+single_sub_category.name}</span></span>
                                                <i style={{color:'#C5C7CF'}} className={subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['sales_channels_modify']?'fa fa-caret-up':'fa fa-caret-down'} />
                                            </div>
                                            {
                                                subcats_collapsed[single_sub_category.id] && subcats_collapsed[single_sub_category.id]['sales_channels_modify']?
                                                <table style={{width:'100%', pointerEvents: individual_props_confirmation_mode ? 'none' : 'auto'}}>
                                                    <tbody style={{width:'100%'}}>
                                                    {
                                                        single_sub_category && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group, idx1) => (
                                                            no_of_price_types_modifying_per_group[single_group.id] && single_group.sku && single_group.sku.length ? single_group.sku.map((single_sku, idx2) => (
                                                                no_of_price_types_modifying_per_sku[single_sku.id] && modifed_sales_channels_values_json && modifed_sales_channels_values_json[single_sku.id] && modifed_sales_channels_values_json[single_sku.id].length ? modifed_sales_channels_values_json[single_sku.id].map((single_sales_channel, idx3) => (
                                                                    single_sales_channel && single_sales_channel.price_types && single_sales_channel.price_types.length ? single_sales_channel.price_types.map((single_price_type, idx4) => (
                                                                        <tr className='tr_style_bulk_edit'>
                                                                            {idx2==0 && idx3==0 && idx4==0 ? <td className='td_style_bulk_edit' style={{backgroundColor:'#FAFAFA', width:'16%'}} rowSpan={no_of_price_types_modifying_per_group[single_group.id] ? no_of_price_types_modifying_per_group[single_group.id]  : 1}>{single_group.name}</td>:''}
                                                                            {idx3==0 && idx4==0 ? <td style={{backgroundColor:'#FAFAFA', width:'16%'}} className='td_style_bulk_edit' rowSpan={no_of_price_types_modifying_per_sku[single_sku.id] ? no_of_price_types_modifying_per_sku[single_sku.id] : 1} >{single_sku ? single_sku.name : ''}</td>:''}
                                                                            {idx4==0 ? <td style={{backgroundColor:'#FAFAFA', width:'16%'}} className='td_style_bulk_edit' rowSpan={single_sales_channel && single_sales_channel.price_types ? single_sales_channel.price_types.length : 1} >{single_sales_channel ? single_sales_channel.name : ''}</td>:''}
                                                                            <td style={{width:'10%', backgroundColor:'#FAFAFA'}} className='td_style_bulk_edit'>{single_price_type ? single_price_type.name : ''}</td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input  
                                                                                    value={single_price_type['pricing_mode']} 
                                                                                    style={{border: existing_sales_channels_values_json[single_sku.id] && existing_sales_channels_values_json[single_sku.id][idx3] && existing_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['pricing_mode'] != single_price_type['pricing_mode'] ? '1px solid #23C16B' : ''}}
                                                                                    onChange={(e) => {update_modifed_sales_channels_values_json(single_sku.id, idx3, idx4, 'pricing_mode', e.target.value)}} type='select' className='input_class'
                                                                                >
                                                                                    <option value='default'>Default</option>
                                                                                    <option value='custom'>Custom</option>
                                                                                </Input>
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    disabled={single_price_type['pricing_mode'] === 'default'} 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? single_price_type['price_default_mode'] : single_price_type['price']} 
                                                                                    style={{border: single_price_type['pricing_mode'] === 'custom' && existing_sales_channels_values_json[single_sku.id] && existing_sales_channels_values_json[single_sku.id][idx3] && existing_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['price'] != single_price_type['price'] ? '1px solid #23C16B' : '', backgroundColor: single_price_type['pricing_mode'] === 'default' ? '#FAFAFA' : ''}}
                                                                                    onChange={(e) => {Number(e.target.value) >= 0 ? update_modifed_sales_channels_values_json(single_sku.id, idx3, idx4, 'price', e.target.value) : alert.error("Negative numbers are not allowed!")}} type='number' className='input_class'/>
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    disabled={single_price_type['pricing_mode'] === 'default'} 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? (single_price_type['display_unit_default_mode'] ? single_price_type['display_unit_default_mode'] : default_pricing_unit) : (single_price_type['display_unit'] ? single_price_type['display_unit'] : default_pricing_unit)} 
                                                                                    style={{border: single_price_type['pricing_mode'] === 'custom' && existing_sales_channels_values_json[single_sku.id] && existing_sales_channels_values_json[single_sku.id][idx3] && existing_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['display_unit'] != single_price_type['display_unit'] ? '1px solid #23C16B' : '', backgroundColor: single_price_type['pricing_mode'] === 'default' ? '#FAFAFA' : ''}}
                                                                                    onChange={(e) => {update_modifed_sales_channels_values_json(single_sku.id, idx3, idx4, 'display_unit', e.target.value)}} type='select' className='input_class'>
                                                                                {
                                                                                    // default_pricing_dimension=='per_unit_area'?
                                                                                    // <>
                                                                                    //     <option value='sq_millimetre'>sq_millimetre</option>
                                                                                    //     <option value='sq_metre'>sq_metre</option>
                                                                                    //     <option value='sq_foot'>sq_foot</option>
                                                                                    // </>
                                                                                    // :
                                                                                    // (
                                                                                    //     default_pricing_dimension=='per_unit_length'?
                                                                                    //     <>
                                                                                    //         <option value='millimetre'>millimetre</option>
                                                                                    //         <option value='metre'>metre</option>
                                                                                    //         <option value='foot'>foot</option>
                                                                                    //     </>:
                                                                                    //     <option value='none'>per_unit</option>
                                                                                    // )
                                                                                    pricing_dimension_options && pricing_dimension_options.length ? pricing_dimension_options.map(single_option => (
                                                                                        <option value={single_option.id}>{single_option.id}</option>
                                                                                    )):''
                                                                                }
                                                                                </Input>
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    disabled={single_price_type['pricing_mode'] === 'default'} 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? single_price_type['tax_default_mode'] : single_price_type['tax']} 
                                                                                    style={{border: single_price_type['pricing_mode'] === 'custom' && existing_sales_channels_values_json[single_sku.id] && existing_sales_channels_values_json[single_sku.id][idx3] && existing_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['tax'] != single_price_type['tax'] ? '1px solid #23C16B' : '', backgroundColor: single_price_type['pricing_mode'] === 'default' ? '#FAFAFA' : ''}}
                                                                                    onChange={(e) => {Number(e.target.value) >= 0 ? update_modifed_sales_channels_values_json(single_sku.id, idx3, idx4, 'tax', e.target.value) : alert.error("Negative numbers are not allowed!")}} type='number' className='input_class'/>
                                                                            </td>
                                                                            <td style={{width:'7%', padding:'0px'}} className='td_style_bulk_edit'>
                                                                                <Input 
                                                                                    disabled={single_price_type['pricing_mode'] === 'default'} 
                                                                                    value={single_price_type['pricing_mode'] === 'default' ? single_price_type['margin_default_mode'] : single_price_type['margin']} 
                                                                                    style={{border: single_price_type['pricing_mode'] === 'custom' && existing_sales_channels_values_json[single_sku.id] && existing_sales_channels_values_json[single_sku.id][idx3] && existing_sales_channels_values_json[single_sku.id][idx3]['price_types'][idx4]['margin'] != single_price_type['margin'] ? '1px solid #23C16B' : '', backgroundColor: single_price_type['pricing_mode'] === 'default' ? '#FAFAFA' : ''}}
                                                                                    onChange={(e) => {Number(e.target.value) >= 0 ? update_modifed_sales_channels_values_json(single_sku.id, idx3, idx4, 'margin', e.target.value) : alert.error("Negative numbers are not allowed!")}} type='number' className='input_class'/>
                                                                            </td>
																			<td style={{width:'7%', padding:'0px', backgroundColor:'rgb(255, 250, 230)'}} className='td_style_bulk_edit'>
																				<div className='ml-3'>{(((single_price_type.price ? Number(single_price_type.price) : 0) / (1 - (single_price_type.margin ? Number(single_price_type.margin) : 0) / 100)) * (1 + (single_price_type.tax ? Number(single_price_type.tax) : 0) / 100)).toFixed(2)}</div>
																			</td>
                                                                        </tr>
                                                                    )):''
                                                                )):''
                                                            )):''
                                                        )):''
                                                    }
                                                    </tbody>
                                                </table>:''
                                            }
                                        </>
                                    )):''
                                }
                            </>:<span>None of the selected SKUs have mapped Sales Channels</span>
                        }
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Advanced Pricing Options</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['is_CBU', 'exclusion_list', 'is_OMC', 'not_priced', 'not_priced_text', 'pricing_from'])}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%', marginTop:'16px'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit' style={{width:'20%'}}>SKU Groups</th>
                                            <th className='th_style_bulk_edit' style={{width:'20%'}}>SKUs</th>
                                            {
                                                ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type)?
                                                <>
                                                    <th className='th_style_bulk_edit' style={{width:'20%'}}>Completely Built Unit</th>
                                                    <th className='th_style_bulk_edit' style={{width:'20%'}}>Override margin of constituents</th>
                                                </>:''
                                            }
                                            {
                                                sku_category_type === 'shutter_hardware'?
                                                <th className='th_style_bulk_edit' style={{width:'30%'}}>Shutter pricing mode</th>:""
                                            }
                                            <th className='th_style_bulk_edit' style={{width:'20%'}}>Not Priced - use custom text</th>
                                            {/* <th className='th_style_bulk_edit' style={{width:'9%'}}>Price</th> */}
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        {
                            Advanced_pricing_table(bulk_selected_hierarchical_data) 
                        }
                        </div>
                    </TabPane>
                    <TabPane tabId="tabs5" className='editing_all_skus_tabpane'>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>{individual_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of Additional Properties</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['default_properties', 'custom_properties', 'default_properties_changed', 'custom_properties_changed'])}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Modify Additional Properties <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json(['default_properties', 'custom_properties', 'default_properties_changed', 'custom_properties_changed'])}}>Reset Changes</span>}
                        </div>
                        <div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit' style={{width:'25%'}}>SKU Groups</th>
                                            <th className='th_style_bulk_edit' style={{width:'25%'}}>SKUs</th>
                                            <th className='th_style_bulk_edit' style={{width:'50%'}}>Additional Properties</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        {
                            individual_props_confirmation_mode ? Additional_Properties_Table(hierarchical_data_additional_props) : Additional_Properties_Table(bulk_selected_hierarchical_data)
                        }
                        </div>
                    </TabPane>
					<TabPane tabId="tabs6" className='editing_all_skus_tabpane'>
						<div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>{individual_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of {is_allow_material ? 'Display Image / 3D Model' : 'Material'}</span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {}}>Reset Changes</span>}
                        </div>
						<div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Modify {!is_allow_material ? 'Display Image / 3D Model' : 'Material'} <span style={{color:'#23C16B', fontWeight:'100'}}>{individual_props_confirmation_mode?'(Modifications are shown in green)':''}</span></span>
                            {individual_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_property_edited_sku_json([])}}>Reset Changes</span>}
                        </div>
						<div className='individual_property_editing'>
                            <div className='table_header' style={{width:'100%'}}>
                                <table style={{width:'100%'}}>
                                    <thead style={{width:'100%', backgroundColor:'#F5F6F8', fontWeight:'900'}}>
                                        <tr className='tr_style_bulk_edit' style={{width:'100%'}}>
                                            <th className='th_style_bulk_edit' style={{width:'25%'}}>SKU Groups</th>
                                            <th className='th_style_bulk_edit' style={{width:'25%'}}>SKUs</th>
                                            {
												!is_allow_material?
												<>
													<th className='th_style_bulk_edit' style={{width:'25%'}}>Display Image</th>
													<th className='th_style_bulk_edit' style={{width:'25%'}}>3D Model</th>
												</>:<th className='th_style_bulk_edit' style={{width:'50%'}}>Material</th>
											}
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        {
                            individual_props_confirmation_mode ? Dp_3DModel_Material_Table(hierarchical_data_dp_3dmodel_material) : Dp_3DModel_Material_Table(bulk_selected_hierarchical_data)
                        }
                        </div>
					</TabPane>
                </TabContent>
            </CardBody>
            <CardFooter className='global_modal_footer'>
                <Button className='white_button' onClick={() => {
                                                                    if(editing_individual_props){
                                                                        set_editing_individual_props(false)
                                                                        set_choosing_editing_mode(true)
                                                                    }else if(individual_props_confirmation_mode){
                                                                        set_individual_props_confirmation_mode(false)
                                                                        set_editing_individual_props(true)
                                                                        set_subcats_collapsed({})
                                                                        set_tabs(1)
                                                                    }
                                                                }}>Go back</Button>
                {
                    individual_props_confirmation_mode?
                    <Button className='blue_button' onClick={() => {publish_changes_and_exit()}}>Publish Changes and exit</Button>
                    :
                    <Button className='blue_button' onClick={() => {checks_before_bulk_edit()}}>Proceed to confirmation</Button>
                }
            </CardFooter>
        </>
    )
}

export default EditIndividualProperties;