import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import general_fetch from './fetch';
import debug_log from './debug_log';
import { SimplifyModifier } from './SimplifyModifier';

// THREE.OBJMTLLoader = function ( manager ) {

// 	this.manager = ( manager !== undefined ) ? manager : THREE.DefaultLoadingManager;

// };

// class OBJMTLLoader {

// 	constructor(manager){
//         super(( manager !== undefined ) ? manager : THREE.DefaultLoadingManager)
//     }

// 	load ( url, mtlurl, onLoad, onProgress, onError ) {
// 		var mtlLoader = new MTLLoader();
// 		mtlLoader.setCrossOrigin( this.crossOrigin );
// 		if(this.texture_path!=null)
// 		{
// 			mtlLoader.setResourcePath( this.texture_path );
// 			this.texture_path = null;
// 		}
// 		else
// 		{
// 			mtlLoader.setResourcePath( url.substr( 0, url.lastIndexOf( "/" ) + 1 ) );
// 		}

// 		mtlLoader.load( mtlurl, function( materials ) {
// 			let materialsCreator = materials;
// 		    materialsCreator.preload();

// 		    var objLoader = new OBJLoader();
// 		    objLoader.setMaterials( materialsCreator );
// 		    objLoader.load( url, function ( object ) {

// 		    	object.traverse( function ( object ) {

// 					if ( object instanceof THREE.Mesh ) {
// 						if(object.material.constructor.name=='Array'){
// 							for(var i=0;i<object.material.length;i++){
// 								var mat = object.material[i];
// 								mat.shininess = 40;
// 							}
// 						}
// 						else{
// 							object.material.shininess = 40;
// 						}
// 					}
// 				} );
// 		        onLoad( object );
// 		    }, onProgress, onError );

// 		},onProgress, onError);
// 	}

// 	setCrossOrigin( value ) {
// 		this.crossOrigin = value;
// 	}
// };

class OBJMTLLoader {
    constructor(manager) {
        this.manager = manager !== undefined ? manager : THREE.DefaultLoadingManager;
        this.crossOrigin = undefined;
        this.texture_path = null;
    }

    load(url, mtlurl, onLoad, onProgress, onError) {
        const mtlLoader = new MTLLoader();
        mtlLoader.setCrossOrigin(this.crossOrigin);

        if (this.texture_path != null) {
            mtlLoader.setResourcePath(this.texture_path);
            this.texture_path = null;
        } else {
            mtlLoader.setResourcePath(url.substring(0, url.lastIndexOf("/") + 1));
        }

        mtlLoader.load(mtlurl, materials => {
            const materialsCreator = materials;
            materialsCreator.preload();

            const objLoader = new OBJLoader();
            objLoader.setMaterials(materialsCreator);

            objLoader.load(url, object => {
                object.traverse(child => {
                    if (child instanceof THREE.Mesh) {
                        if (Array.isArray(child.material)) {
                            child.material.forEach(mat => {
                                mat.shininess = 40;
                            });
                        } else {
                            child.material.shininess = 40;
                        }
                    }
                });

                onLoad(object);
            }, onProgress, onError);
        }, onProgress, onError);
    }

    setCrossOrigin(value) {
        this.crossOrigin = value;
    }
}


export function show3DModelfn(sku, element ,server_path) {
	var camera, scene, renderer;
	var mouseX = 0, mouseY = 0;
	if(sku && sku.low_model_3d){
		let obj_src = server_path  + '/' + sku.low_model_3d.path;
		let glb_src = sku.low_model_3d.new_model && sku.low_model_3d.glb_src ? server_path  + '/' + sku.low_model_3d.glb_src : null;
		let scale = sku.low_model_3d.new_model ? sku.low_model_3d.scale : null;
		let mtl_src = server_path  + '/' + sku.low_model_3d.mtl_path;
		try {
			init(obj_src, glb_src,mtl_src,element, server_path); 
		} catch(err) {
			console.log(err)
		}
	}
}
var scene,camera,controls,container,windowHalfX,obj,windowHalfY,mouseX=0,mouseY=0, renderer;

function init(obj_src, glb_src, mtl_src, element, server_path) {
	let loader = glb_src ? new GLTFLoader() : new OBJMTLLoader();
	container = document.getElementById( element );
	container.innerHTML=""
	var CANVAS_HEIGHT = container.style.height.split("px")[0],
	CANVAS_WIDTH = CANVAS_HEIGHT;
	camera = new THREE.PerspectiveCamera( 45,1, 1, 100000 );
	camera.position.set(0,0,3000);

	// scene

	scene = new THREE.Scene();

	var ambient = new THREE.AmbientLight( 0xffffff );
	scene.add( ambient );
	ambient.intensity = 0.4;

	// var light = new THREE.HemisphereLight( 0xffffff, 0xffffff, 1 );
	// light.position.set(0, 4000, 0);

	// const spotLight = new THREE.SpotLight( 0xffffff );
	// spotLight.position.set( 100, 1000, 100 );

	var directionalLight = new THREE.DirectionalLight( 0xffffff );
	directionalLight.position.set( 1, 1, 1 );
	directionalLight.intensity = 0.8;
	scene.add( directionalLight );

	// texture

	var manager = new THREE.LoadingManager();
	manager.onProgress = function ( item, loaded, total ) {

		// console.log( item, loaded, total );

	};

	loader.crossOrigin = '';
	loader.texture_path =  glb_src ? server_path+"/model_uploads/" : server_path+"/textures/" ;
	
	var success_function = object => {
		console.log('object loaded')
		object = object.scene ? object.scene : object;
		var bbox = new THREE.Box3().setFromObject(object);
		var width = (Math.abs(bbox.max.x - bbox.min.x));
		var depth = (Math.abs(bbox.max.z - bbox.min.z));
		var height = (Math.abs(bbox.max.y - bbox.min.y));
		
		var size = bbox.getSize(new THREE.Vector3()).length();
    	var scale = 1.0 / size;

		var center = bbox.getCenter(new THREE.Vector3());
    	object.position.sub(center);

		
		scene.add( object );

		var modelBoundingSphere = new THREE.Sphere();
		bbox.getBoundingSphere(modelBoundingSphere);
		var modelCenter = modelBoundingSphere.center;
		var modelRadius = modelBoundingSphere.radius;
	
		camera.position.set(modelCenter.x, modelCenter.y, modelCenter.z + modelRadius * 2);
		camera.lookAt(modelCenter);


		renderer = new THREE.WebGLRenderer({ alpha: true });
		renderer.setClearColor (0xffffff, 1);

		renderer.setSize( CANVAS_WIDTH, CANVAS_HEIGHT);
		controls = new OrbitControls( camera,renderer.domElement );
		controls.update();
		// camera.position.z = 4*depth;
		// controls.target.y = depth/2;
		// camera.position.y = depth/2;
		// camera.position.x = -width/2;

		container.appendChild( renderer.domElement );
		container.children[0].style.width = "auto"
		container.children[0].style.display = "flex"
		container.children[0].style.margin = "auto"
		document.addEventListener( 'mousemove', onDocumentMouseMove, false );

		window.addEventListener( 'resize', onWindowResize, false );

		animate();
	}

	var logging_function = x => console.log(x);

	if (glb_src) {
		loader.load(glb_src, success_function, logging_function, logging_function)
	} else {
		console.log('obj loading..')
		loader.load(obj_src, mtl_src, success_function, logging_function, logging_function)
	}

}

function onWindowResize() {
	windowHalfX = container.style.height.split('px')[0] / 2;
	windowHalfY = windowHalfX;
	var CANVAS_WIDTH = windowHalfX*2,
	CANVAS_HEIGHT = CANVAS_WIDTH;

	camera.aspect = CANVAS_WIDTH / CANVAS_HEIGHT ;
	camera.updateProjectionMatrix();

	renderer.setSize(CANVAS_WIDTH,CANVAS_HEIGHT );
	// container.children[0].style.width = "auto"
	// container.children[0].style.display = "flex"
	// container.children[0].style.margin = "auto"
	container.style.width = "auto"
	container.style.display = "flex"
	container.style.margin = "auto"
}

function onDocumentMouseMove( event ) {
	mouseX = ( event.clientX - windowHalfX ) / 2;
	mouseY = ( event.clientY - windowHalfY ) / 2;
}

function animate() {
	requestAnimationFrame( animate );
	render();
}

function render() {
	// camera.lookAt(scene.position);
	// controls.update();
	renderer.render(scene, camera);
}

export async function upload_image({ type, sku_id, upl }) {
	var fd = new FormData();
	fd.append('upl', upl)
	sku_id && fd.append('sku_id', sku_id);

	var resp = await general_fetch({ url: `file_transfer/upload_top_view_${type}`, body:fd, is_form_data: true });
	debug_log('upload complete')
	return resp;
}

// const update_images = async sku_id => {
// 	try {
// 		let skus = await general_fetch({ url: 'sku/get' , body: {identifiers:JSON.stringify({ id : sku_id })} });
// 		let sku = skus[0];
// 		debug_log('getting sku details');		
// 		// let server_path = 'https://productionbackend.infurnia.com'
// 		let server_path = 'https://stagebackend.infurnia.com'
// 		if(sku && sku.low_model_3d){
// 			if (sku.material_id) {
// 				debug_log('material sku encountered ---> ', sku);
// 				return 1;
// 			}
// 			// if (sku.top_view_colour_id) {
// 			// 	debug_log('already tackled this..', sku.id, sku.top_view_colour_id);
// 			// 	return 1;
// 			// }
// 			try {
// 				let obj_src = server_path  + '/' + sku.low_model_3d.path;
// 				let glb_src = sku.low_model_3d.new_model ? server_path  + '/' + sku.low_model_3d.glb_src : null;
// 				let scale = sku.low_model_3d.new_model ? sku.low_model_3d.scale : null;
// 				let mtl_src = server_path + '/' + sku.low_model_3d.mtl_path;


// 				/*
// 					This code should run after add/update of any 3d model
// 				*/
// 				let { top_colour_image, top_grayscale_image } = await postUploadImageExtractionfn({ obj_src, mtl_src, server_path, glb_src, scale })
// 				debug_log('initiating upload')
// 				top_colour_image = dataURLtoBlob(top_colour_image)
// 				top_grayscale_image = dataURLtoBlob(top_grayscale_image)
// 				return await Promise.all([ upload_image({ type: 'colour', sku_id, upl: top_colour_image }), upload_image({ type: 'grayscale', sku_id, upl: top_grayscale_image }) ]);
// 			} catch(err) {
// 				debug_log('error in processing sku --> ', err, sku);
// 			}
// 		} else {
// 			throw 'invalid skus'
// 		}
// 	} catch(err) {
// 		console.log(err);
// 		return Promise.reject(err);
// 	}
// }

export function dataURLtoBlob(dataurl){
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}



const calculate_mesh_to_vertex_count = (node, updation_map) => {
	let counter = 0;
	if (node && node.geometry) {
		const initial_vertex_count = node.geometry.index ? node.geometry.index.count : node.geometry.attributes.position.count;
		// debug_log("inside calculate mesh to vertex count", node.geometry.index ? node.geometry.index.count : "no index", node.geometry.attributes.position.count)
		updation_map[node.uuid] = initial_vertex_count;
		counter += initial_vertex_count;
	}
	if (node.children) {
		for (let i = 0; i < node.children.length; i++) {
			const child = node.children[i];
			counter += calculate_mesh_to_vertex_count(child, updation_map);
		}
	}
	return counter;
}

const calculate_total_vertex_count = (node) => {
	let max_allowed_vertices_per_mesh = window.decimate_limit_per_mesh ? window.decimate_limit_per_mesh : 100000
	let counter = 0;
	if (node && node.geometry) {
		const initial_vertex_count = node.geometry.index ? node.geometry.index.count : node.geometry.attributes.position.count;
		counter += Math.min(max_allowed_vertices_per_mesh, initial_vertex_count);
	}
	if (node.children) {
		for (let i = 0; i < node.children.length; i++) {
			const child = node.children[i];
			counter += calculate_total_vertex_count(child);
		}
	}
	return counter;
}

export const decimate_model = async (input_file_path, target_vertex_count, set_page_loader) => {
	let max_allowed_vertices_per_mesh = window.decimate_limit_per_mesh ? window.decimate_limit_per_mesh : 100000
	try {
		const d_scene = new THREE.Scene();
		const custom_loader = new GLTFLoader();
		let require_decimation = false;

		let logging_function = x => x;
        let loaded_object = Promise.resolve();
		if (input_file_path) {
			debug_log('glb loading..')
			loaded_object = new Promise((resolve, reject) => {
				let err_function = err => {
					console.error('error in loading object', err);
					resolve(null);
				}
				custom_loader.load(input_file_path, resolve, logging_function, err_function)
			})
		}

        async function exportGLB(scene) {
            return new Promise((resolve) => {
                //   const exporter = new GLTFExporter();
                exporter.parse(
                    scene,
                    (result) => {
                        debug_log('decimate model', result)
                        resolve(result);
                    },
					(err) => {
						console.error('error in loading object', err);
						resolve(null);
					},
                    { binary: true } // Set binary option to true for GLB export
                );
            });
        }

		const data = await loaded_object;
		// custom_loader.load(input_file_path, async data => {
		debug_log(`data loaded from ${input_file_path} by custom loader`);
		// const gltf_loader = new GLTFLoader();
		// const gltf = await gltf_loader.parseAsync(data, '/');
		const gltf = data;
		debug_log(`gltf parsed from data inside gltfLoader.parse`, gltf);
		
		let mesh_to_target_vertex_count = {};

		const process_node_timeout = async (scene) => (
			new Promise((resolve) => setTimeout(async() => {
				await process_node(scene)
				resolve()
			}, 200))
		)

		const process_node = async (node) => {
			debug_log(`node --> ${node}`);
			if (node && node.isMesh && node.geometry) {
				const initial_vertex_count = node.geometry.index ? node.geometry.index.count : node.geometry.attributes.position.count;
				let meshwise_target_vertex_count = mesh_to_target_vertex_count[node.uuid];
				debug_log(`initial_vertex_count --> ${initial_vertex_count} || meshwise_target_vertex_count --> ${meshwise_target_vertex_count}`);
				// if (initial_vertex_count > meshwise_target_vertex_count) {
				// 	debug_log(`Mesh has ${initial_vertex_count} vertices, which is more than the target of ${meshwise_target_vertex_count} -- Simplifying`);
				// 	const modifier = new SimplifyModifier();
				// 	node.geometry = modifier.modify(node.geometry, initial_vertex_count - meshwise_target_vertex_count); // Modify the geometry of the current node
				// 	debug_log(`Simplified mesh has ${node.geometry.attributes.position.count} vertices`);
				// 	if (node.geometry.attributes.position.count <= meshwise_target_vertex_count) {
				// 		debug_log(`Simplification successful`);
				// 	} else {
				// 		debug_log(`Simplification unsuccessful`);
				// 	}
				// }
				if (initial_vertex_count > meshwise_target_vertex_count) {
					debug_log(`Mesh has ${initial_vertex_count} vertices, which is more than the target of ${meshwise_target_vertex_count} -- Simplifying`);
					const modifier = new SimplifyModifier();
					let current_vertex_count = initial_vertex_count;
				
					while (current_vertex_count > meshwise_target_vertex_count) {
						let reduction_target = Math.min(Math.floor(current_vertex_count * 0.1), current_vertex_count - meshwise_target_vertex_count);
						let current_vertex_count_shared = node.geometry.attributes.position.count
						debug_log(`reduction_target --> ${reduction_target}, current_vertex_count_shared --> ${current_vertex_count_shared}, current_vertex_count --> ${current_vertex_count} , ${Math.floor(reduction_target*current_vertex_count_shared/current_vertex_count)}`);
						node.geometry = modifier.modify(node.geometry, (Math.floor(reduction_target*current_vertex_count_shared/current_vertex_count) || 1));
						let current_vertex_count_simplified = node.geometry.index ? node.geometry.index.count : node.geometry.attributes.position.count;
						let current_vertex_count_shared_simplified = node.geometry.attributes.position.count
						if(current_vertex_count_simplified === current_vertex_count && current_vertex_count_shared_simplified === current_vertex_count_shared){
							break;
						}
						current_vertex_count = current_vertex_count_simplified
						// current_vertex_count = node.geometry.index ? node.geometry.index.count : node.geometry.attributes.position.count;
						debug_log(`Simplified mesh has ${current_vertex_count} vertices`);
					}
				
					if (current_vertex_count <= meshwise_target_vertex_count) {
						debug_log(`Simplification successful`);
					} else {
						debug_log(`Simplification unsuccessful`);
					}
				}
			}
			
			if (node.children) {
				const promises = node.children.map(child => process_node(child));
				await Promise.all(promises);
			}
			return 1;
		};
		
		let mesh_to_vertex_count = {};
		let total_vertex_count = 0;
		let total_vertex_count_greedy = 0
		let total_vertex_count_after_simplification = 0;
		let mesh_to_vertex_count_after_simplification = {};
		if (gltf.scene instanceof THREE.Object3D) {

			// var mergedGeometry = new THREE.BufferGeometry()
			// // Array to hold attribute buffers
			// var attributeBuffers = [];

			// // Traverse through each child of the scene (assuming each child is a mesh)
			// gltf.scene.traverse(function (child) {
			// 	if (child.isMesh) {
			// 		// Convert Geometry to BufferGeometry (if needed)
			// 		// if (child.geometry instanceof THREE.Geometry) {
			// 		// 	child.geometry = BufferGeometryUtils.fromGeometry(child.geometry);
			// 		// }

			// 		// Merge the geometry of each mesh into the mergedGeometry
			// 		attributeBuffers.push(child.geometry);
			// 	}
			// });

			// debug_log("attribute", attributeBuffers)

			// // Merge attribute buffers into the merged geometry
			// mergedGeometry = BufferGeometryUtils.mergeGeometries(attributeBuffers);

			// // Create a new mesh using the merged geometry
			// var mergedMesh = new THREE.Mesh(mergedGeometry);

			// // Add the merged mesh to the scene
			// gltf.scene.add(mergedMesh);

			// // Remove the original meshes (optional, depending on your use case)
			// gltf.scene.traverse(function (child) {
			// 	if (child.isMesh) {
			// 		gltf.scene.remove(child);
			// 	}
			// });

			//calculate mesh to vertex count
			total_vertex_count = calculate_mesh_to_vertex_count(gltf.scene, mesh_to_vertex_count);
			total_vertex_count_greedy = calculate_total_vertex_count(gltf.scene);
			let big_mesh_values = []
			let big_mesh_ids = []
			for(let mesh_id in mesh_to_vertex_count){
				if(mesh_to_vertex_count[mesh_id] > max_allowed_vertices_per_mesh){
					big_mesh_values.push(mesh_to_vertex_count[mesh_id])
					big_mesh_ids.push(mesh_id)
				}
			}
			let big_mesh_values_sum = 0;
			let possible_target_vertex_count = target_vertex_count > total_vertex_count_greedy ? total_vertex_count_greedy : target_vertex_count
			big_mesh_values.map(x => {big_mesh_values_sum = big_mesh_values_sum + x})
			let big_mesh_deficit = Math.ceil(big_mesh_values.length * max_allowed_vertices_per_mesh * ( 1 - (possible_target_vertex_count / total_vertex_count_greedy) ))
			let small_mesh_excess = 0
			let not_small_meshes = []
			debug_log(`big mesh values`, big_mesh_values, big_mesh_values_sum, big_mesh_deficit)
			debug_log(`mesh_to_vertex_count --> `, mesh_to_vertex_count, `total_vertex_count --> ${total_vertex_count}`, `total_vertex_count_indexed --> ${total_vertex_count_greedy}`);
			for (let mesh_id in mesh_to_vertex_count) {
				if((mesh_to_vertex_count[mesh_id]) > max_allowed_vertices_per_mesh){
					require_decimation = true;
					mesh_to_target_vertex_count[mesh_id] = Math.min(max_allowed_vertices_per_mesh, Math.floor(max_allowed_vertices_per_mesh - (big_mesh_values.length == 1 ? big_mesh_deficit : (((1-(mesh_to_vertex_count[mesh_id])/big_mesh_values_sum))/(big_mesh_values.length - 1) * big_mesh_deficit))))
					not_small_meshes.push({id: mesh_id, value: mesh_to_target_vertex_count[mesh_id]})
					// mesh_to_target_vertex_count[mesh_id] = Math.min(max_allowed_vertices_per_mesh, Math.floor(target_vertex_count * (big_mesh_values.length * max_allowed_vertices_per_mesh * mesh_to_vertex_count[mesh_id] / big_mesh_values_sum) / total_vertex_count_greedy));
				}else{
					// Having a min of 100 so meshes are not decimated to 0
					// mesh_to_target_vertex_count[mesh_id] = Math.max(100, Math.min(max_allowed_vertices_per_mesh, Math.floor(possible_target_vertex_count * Math.min(max_allowed_vertices_per_mesh,mesh_to_vertex_count[mesh_id]) / total_vertex_count_greedy)));
					let init_mesh_to_vertex_count = Math.floor(possible_target_vertex_count * mesh_to_vertex_count[mesh_id] / total_vertex_count_greedy);
					if(init_mesh_to_vertex_count > 200){
						mesh_to_target_vertex_count[mesh_id] = init_mesh_to_vertex_count
						not_small_meshes.push({id: mesh_id, value: mesh_to_target_vertex_count[mesh_id]})
					} else {
						let final_mesh_to_vertex_count = Math.min(200, mesh_to_vertex_count[mesh_id])
						mesh_to_target_vertex_count[mesh_id] = final_mesh_to_vertex_count;
						small_mesh_excess += (final_mesh_to_vertex_count - init_mesh_to_vertex_count)
					}
				}
			}

			let total_meshes = not_small_meshes.length
			let small_mesh_excess_per_mesh = Math.ceil(small_mesh_excess/total_meshes)
			not_small_meshes = not_small_meshes.sort((a, b) => a.value - b.value)
			for(let i in not_small_meshes){
				let mesh_id = not_small_meshes[i].id
				let expected_target = mesh_to_target_vertex_count[mesh_id] - small_mesh_excess_per_mesh
				if(expected_target < 200){
					mesh_to_target_vertex_count[mesh_id] = 200
					if(i !== total_meshes-1){
						small_mesh_excess_per_mesh += Math.ceil((200-expected_target)/(total_meshes - i - 1))
					}else{
						debug_log(`small_mesh_excess --> `, 200-expected_target);
					}
				}else{
					mesh_to_target_vertex_count[mesh_id] = expected_target
				}
				// mesh_to_target_vertex_count[mesh_id] = mesh_to_target_vertex_count[mesh_id] - small_mesh_excess_per_mesh
			}
			
			debug_log(`mesh_to_target_vertex_count --> `, mesh_to_target_vertex_count);
			if((total_vertex_count > target_vertex_count) || require_decimation){
				set_page_loader({show: true, text: "Vertex count too large. Decimating your model to an acceptable level. Please do not Refresh/Navigate the page"})
				// await sleep(200)
				// await process_node(gltf.scene);
				await process_node_timeout(gltf.scene)
				//calculate meshwise and total vertex count after simplification
				d_scene.add(gltf.scene);
				total_vertex_count_after_simplification = calculate_mesh_to_vertex_count(gltf.scene, mesh_to_vertex_count_after_simplification);
				debug_log(`mesh_to_vertex_count_after_simplification --> `, mesh_to_vertex_count_after_simplification, `total_vertex_count_after_simplification --> ${total_vertex_count_after_simplification}`);
			}else{
				return -1
			}
		}

		debug_log(`scene modified || beginning export`)
		const exporter = new GLTFExporter();
		debug_log(`exporter created`, exporter, exporter.parse);
		const result = await exportGLB(d_scene);
		// const result = await exporter.parseAsync(d_scene);
		debug_log(`exporter parsed`);
		const glb = result;
		
		debug_log(`export complete`);
		return glb;
		// resolve(ofp);
		// 	}, x => debug_log(`GLTF PROGRESS -->> `, x), reject);
	} catch(err) {
		debug_log(`error in decimate_model --> `, err);
		throw err;
	}
	// });
}

export async function postUploadImageExtractionfn({ obj_src, mtl_src, server_path, glb_src, scale }) {
	try {
	    var element = 'canvas_div_temp';
	    // debug_log('cp1')
		container = document.getElementById( element );
		container.innerHTML=""
		var CANVAS_HEIGHT = container.style.height.split("px")[0],
		CANVAS_WIDTH = CANVAS_HEIGHT;
		camera = new THREE.PerspectiveCamera( 45,1, 1, 100000 );
		camera.position.set(0,0,3000);
		

		// scene

		scene = new THREE.Scene();

		var ambient = new THREE.AmbientLight( 0xffffff );
		scene.add( ambient );
		ambient.intensity = 0.4;

		var light = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.3 );
		light.position.set(0, 4000, 0);

		var directionalLight = new THREE.DirectionalLight( 0xffeedd );
		directionalLight.position.set( 1, 1, 1 );
		directionalLight.intensity = 0.3;
		scene.add( directionalLight );

		// texture

		var manager = new THREE.LoadingManager();
		manager.onProgress = function ( item, loaded, total ) {
			// debug_log( item, loaded, total );
		};
		let loader = glb_src ? new GLTFLoader() : new OBJMTLLoader()();
		
		loader.crossOrigin = '';
		loader.texture_path =  glb_src ? server_path+"/model_uploads/" : server_path + "/textures/" ;
	    // debug_log('cp2')
		
		const calculateModelDimensions = (model) => {
			const bbox = new THREE.Box3().setFromObject(model);
			const { min, max } = bbox;
		
			const width = max.x - min.x;
			const height = max.y - min.y;
			const depth = max.z - min.z;
		
			return { width, height, depth };
		}

		let success_function = async (object, scale) => {
			try {
		    	debug_log('object loaded in renderer')
				let dim = calculateModelDimensions(object)
				await wait_for_texture_loads(object);
				var top_colour_image = await createCanvasWithObjectfn(object, 'top', scale, false);
				var top_grayscale_image = await createCanvasWithObjectfn(object, 'top', scale, true);
				debug_log('images obtained')
				return { top_colour_image, top_grayscale_image, ...dim };
			} catch(err) {
				return Promise.reject(err);
			}
		}
		let logging_function = x => x;

		let loaded_object = Promise.resolve();
		if (glb_src) {
			debug_log('glb loading..')
			loaded_object = new Promise((resolve, reject) => {
				let err_function = err => {
					console.error('error in loading object', err);
					resolve(null);
				}
				loader.load(glb_src, resolve, logging_function, err_function)
			})
		} else {
			debug_log('obj loading..')
			loaded_object = new Promise((resolve, reject) => {
				let err_function = err => {
					console.error('error in loading object', err);
					resolve(null);
				}
				loader.load(obj_src, mtl_src, resolve, logging_function, err_function)
			})
		}
		debug_log('object load init ');
		let object = await loaded_object;
		debug_log('object loaded image extraction init');
		if (object) {
		    object = object.scene ? object.scene : object;

			return await success_function(object, scale)
		} else { 
			return Promise.reject('error in loading model')
		}
	}	catch(err) {
		console.log(err);
		return Promise.reject(err);
	}    
}



var wait_for_texture_loads = async (object) => {
	return Promise.race([new Promise((res,rej) => {
		var t = setInterval (() => {
			var needs_wait = false;
			object.traverse(o => {
				if(o.isMesh){
					if(o.material.constructor.name == "Array"){
						o.material.map(p => {
							if(p.map && !p.map.image){
								needs_wait = true;
							}	
						})
					}else{
						if(o.material.map && !o.material.map.image){
							needs_wait = true;
						}
					}
				}
			})

			if(!needs_wait){
				clearInterval(t);
				res();
			}
		},10)
	})],new Promise((res,rej) => {
		setTimeout(() => {
			res();
		},10000)
	}))
}


const createCanvasWithObjectfn = async (object,view, scale, wireframe_only) => {
    // debug_log('cp4')
    return new Promise(async (resolve,reject) => {
        object = object.clone();
        // if (scale) {
        // 	debug_log("scale found, applying scale")
        // 	object.scale.set(scale, scale, scale);
        // }

        var canvasDiv = document.createElement('div')   

        var canvasOutput = document.createElement('canvas')
        canvasOutput.id = 'canvasOutput'

        var scene_temp = new THREE.Scene();
        try {
	        scene_temp.background = new THREE.Color( 0xf0f0f0 );
	        scene_temp.add( object );
	        object.rotation.set(0,0,0)
	        object.position.set(0,0,0)
	        if (wireframe_only){
		        object.traverse(o => {
				    if(o.isMesh){
				        var a = o.geometry;
				        var geo = new THREE.EdgesGeometry( a );
				        var mat = new THREE.LineBasicMaterial( { color: 0x000000, linewidth: 10 } );
				        var wireframe = new THREE.LineSegments( geo, mat );
				        wireframe.position.set(o.position.x,o.position.y,o.position.z);
				        wireframe.rotation.set(o.rotation.x,o.rotation.y,o.rotation.z);
				        wireframe.scale.set(o.scale.x,o.scale.y,o.scale.z);
				        wireframe.matrixWorldNeedsUpdate = true
				        o.parent.add( wireframe );

				        if(o.material.constructor.name == "Array"){
				        	o.material.map(p => {
				        		p.color.set(0xffffff,0xffffff,0xffffff)
				        		delete p.map;
				        		p.needsUpdate = true;
				        	})
				        }else{
				        	o.material.color.set(0xffffff,0xffffff,0xffffff)
			        		delete o.material.map;
			        		o.material.needsUpdate = true;
				        }
				    }
				})
	        }
	        debug_log('object loaded and processed ')
	        var bbox = new THREE.Box3().setFromObject(object)
	        var center = new THREE.Vector3();
	        bbox.getCenter(center);
	        

	        if(view == 'top'){
	            var width = bbox.max.x - bbox.min.x + 6
	            var height = bbox.max.z - bbox.min.z + 6
	        }else if(view == 'front'){
	            var width = bbox.max.x - bbox.min.x + 6
	            var height = bbox.max.y - bbox.min.y + 6
	        }else{
	            return 'view has to be either top or front'
	        }

	        // var imageHeight = 0.25*height
			var imageHeight = 400;
	       
	        var frustumSize = height
	        var aspect = width / height;

			if(width > height){
				imageHeight = 400/aspect
			}
	        
	        var camera_temp = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, 1, 200000 );
	        camera_temp.position.copy(center)

	        if(view == 'front'){
	            camera_temp.position.z += frustumSize/2
	        }else if(view == 'top'){
	            camera_temp.position.y += frustumSize/2
	            // camera_temp.position.z += 0.002
	            camera_temp.position.z += 0.05
	        }

	        scene_temp.add( camera_temp );

	        var pointLight = new THREE.PointLight();
	        pointLight.name="centreLight"
	        if(view == 'front'){
	            pointLight.position.set(center.x,center.y,center.z+1000)
	        }else if(view == 'top'){
	            pointLight.position.set(center.x,center.y+1000,center.z)
	        }
	        pointLight.intensity = 0.06;

	        var light = new THREE.AmbientLight(0xffffff, 0.9 );
	        light.position.set(center.x, center.y+1000, center.z);
	        scene_temp.add(light);
	        scene_temp.add(pointLight);
		 	scene_temp.background = null;


	        var renderer_temp = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
	        renderer_temp.setSize( imageHeight*aspect, imageHeight );
	        canvasDiv.appendChild( renderer_temp.domElement );

	        camera_temp.lookAt(center)
	        renderer_temp.render(scene_temp,camera_temp)
	        var img = new Image();
	        img.src = canvasDiv.children[0].toDataURL('image/png', 1);
	        img.width = imageHeight*aspect;img.height = imageHeight

	        // await new Promise(r => img.onload=r, img.src=canvasDiv.children[0].toDataURL());
			img.src=canvasDiv.children[0].toDataURL()
	        dispose_scene_content(scene_temp)

	        resolve(img.src);
        } catch(err) {
	        dispose_scene_content(scene_temp)
        	debug_log("error in image generation --> ", err, object);
        	reject(err);
        }
    })
}

function gray(imgObj) {
    var canvas = document.createElement('canvas');
    canvas.id='temp_canvas';
    var canvasContext = canvas.getContext('2d');
     
    var imgW = imgObj.width;
    var imgH = imgObj.height;
    canvas.width = imgW;
    canvas.height = imgH;
    // canvasContext.filter = "grayscale(100%)";
    canvasContext.drawImage(imgObj, 0, 0);
    var imgPixels = canvasContext.getImageData(0, 0, imgW, imgH);
     
    for(var y = 0; y < imgPixels.height; y++){
        for(var x = 0; x < imgPixels.width; x++){
            var i = (y * 4) * imgPixels.width + x * 4;
            var avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
            debug_log(avg)
            avg = avg > 50 ? 255 : 0;
            imgPixels.data[i] = avg; 
            imgPixels.data[i + 1] = avg; 
            imgPixels.data[i + 2] = avg;
        }
    }
    canvasContext.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
    return canvas.toDataURL();
}

function dispose_scene_content(scene) {
    try{
        for( var i = scene.children.length - 1; i >= 0; i--) {
            obj = scene.children[i];
            if (!(obj instanceof THREE.Camera)) {
                scene.remove(obj);
                disposeHierarchy (obj, disposeNode);
            }
        }
    }
    catch(err){
        console.log(err);
    }
}

function disposeHierarchy (node, callback) {
	for (var i = node.children.length - 1; i >= 0; i--) {
		var child = node.children[i];
		disposeHierarchy (child, callback);
		callback (child);
	}
}


function disposeNode (node) {
    try{
    	if (node instanceof THREE.Camera)
    	{
    		 node = undefined;
    	}
    	// else if (node instanceof THREE.Light)
    	// {
    	// 	node.dispose ();
    	// 	node = undefined;
    	// }
    	else if (node instanceof THREE.Mesh)
    	{
            let geometry = node.geometry;
            let material = node.material;
            let texture = node.material.map;

            geometry.dispose();
            if(material.constructor.name == "Array")
            {
                for (var i = 0; i < material.length; i++)
                material[i].dispose();
            }
            else
            material.dispose();


            // material.dispose();
            if(texture instanceof THREE.Texture) {
                texture.dispose();
            }
            node = undefined;
    	}
    	else if (node instanceof THREE.Object3D)
    	{
    		node = undefined;
    	}
    }
    catch(err){
        console.log(err);
    }
} 
