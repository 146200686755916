import React , { useState , useEffect, useContext }from 'react';
import {Modal, Button, Input, Card, CardBody, CardHeader, CardFooter, Col, Table, Row } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import { useAlert } from 'react-alert'
import Multiselect from '../../../components/Multiselect';
import { relativeTimeRounding } from 'moment';
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import { exclusion_list_options } from '../../../utils/cbu_exclusion_list';
import './sku_groups.css'
import { IModalBody, IModalFooter, IModalHeader } from '../../../components/IModal';
import KeyValuePair from '../../../components/KeyValuePair';
import is_valid_add_key from '../../../utils/is_valid_add_key';
import comp_file_top_level_elem from '../../../utils/comp_file_top_level_elem';
import {Tooltip} from 'antd';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import { handleError } from '../../../utils/error';

const cbu_valid_types = [ "corner_unit", "cabinet", "drawer_system", "user_element" ];
const all_units = [{id:'metre', text:'Metre'},{id:'millimetre', text:'Millimetre'}, {id:'foot', text:'Foot'}, {id:'sq_metre', text:'Square Metre'},{id:'sq_millimetre', text:'Square Millimetre'} ,{id:'sq_foot', text:'Square Foot'}, {id:'cu_metre', text:'Cubic Metre'},{id:'cu_millimetre', text:'Cubic Millimetre'} ,{id:'cu_foot', text:'Cubic Foot'}, {id:'kilogram', text:'kilogram'},{id:'gram', text:'Gram'} ,{id:'pound', text:'Pound'}, {id:'per_set', text:'Per Set'}, {id:'per_unit', text:'Per Unit'}]

const PtAddnPropsModal = ({open, toggle, update_single_sales, sc_item_id, price_type_id, additional_properties, disabled = false}) => {
	// {((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?true:false}
	const alert = useAlert()
	const [addn_props_default, set_addn_props_default] = useState([]);
	const [addn_props_custom, set_addn_props_custom] = useState([]);

	const all_allowed_key = {
		model_no:{
			id:'model_no',
			name:'Model No',
			type:'text',
		}
	}

	const submit = () => {
		let x_addi_prop = []
		if(addn_props_default && addn_props_default.length>1 && !is_valid_add_key(addn_props_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
		}else if(addn_props_custom && addn_props_custom.length>1 && !is_valid_add_key(addn_props_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
		}else{
			addn_props_default.map(x => {
				if(addn_props_default[0].key){
					x_addi_prop.push(x)
				}
			})
			addn_props_custom.map(x => {
				if (addn_props_custom[0].key) {
					x_addi_prop.push(x)
				}
			}) 
		}

		update_single_sales('sales_channel_prices',sc_item_id, price_type_id,'additional_properties',x_addi_prop);
		toggle()

	}

	useEffect(() => {
		set_addn_props_default(additional_properties)
		var x_default = [], x_custom = [];

		additional_properties && additional_properties.map(x_item => {
			// if(Object.keys(all_allowed_key).includes(x_item.key) && all_allowed_key[x_item.key].applicability && all_allowed_key[x_item.key].applicability.includes(sku_category_type)){
			if (Object.keys(all_allowed_key).includes(x_item.key)) {
				x_default.push(x_item)
			}else {
				x_custom.push(x_item)
			}
		})

		set_addn_props_custom(x_custom)
		set_addn_props_default(x_default)
	}, [additional_properties]);
	return (
		<Modal isOpen={open} toggle={toggle} className='modal-dialog-centered'>
			<IModalHeader toggle={toggle}>Additional Properties</IModalHeader>
			<IModalBody>
				<div style={{pointerEvents: disabled ? 'none': 'all'}}>
					<div className='mb-3'>Editing is disabled. To enable editing enter editing mode and set pricing mode to custom</div>
					<KeyValuePair item_json={addn_props_default} set_item_json={set_addn_props_default} mode='default' all_allowed_key={all_allowed_key} disable_input={disabled} />
					<KeyValuePair item_json={addn_props_custom} set_item_json={set_addn_props_custom} mode='custom' all_allowed_key={all_allowed_key} disable_input={disabled} />
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='blue_button' onClick={() => {submit()}}>Done</Button>
			</IModalFooter>
		</Modal>
	)
}

const AdvancedPricingDisabledModal = ({show_advanced_pricing_disabled_modal, set_show_advanced_pricing_disabled_modal, sku_category_type}) => {

	const closeModal = () => {
		set_show_advanced_pricing_disabled_modal(false)
	}

	return (
		<Modal size="sm" isOpen={show_advanced_pricing_disabled_modal} toggle={closeModal} centered>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header' style={{textAlign: 'center'}}>
					<div style={{margin: '0 auto'}}>Alert</div>
					<i onClick ={closeModal} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body' style={{overflow:'visible', display: 'flex', alignItems: 'center'}} >
					<Row style={{margin:0}}>
						<Col className='flex_property' style={{padding:0}}>
							<span style={{fontSize: '14px'}}>{sku_category_type != 'shutter_hardware' ? 'This SKU will be priced as Completely Built Unit in the Pricing Quotation of your designs. It will also not respect separate pricing declared for exclusion list items. To price an SKU from its constituent elements or use separate pricing for exclusion list items, you need to buy Advanced Pricing add-on. Please reach out to your customer success executive for more details.' : 'The shutter will be priced from shutter design SKU in the Pricing Quotation of your designs. To price a shutter from its constituent elements, you need to buy Advanced Pricing add-on. Please reach out to your customer success executive for more details.'}</span>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button 
						onClick ={closeModal}
						className='blue_button' type="button" 
					>OK</Button> 
				</CardFooter>
			</Card>  
		</Modal>
	)
}

const ManageSalesSku = ({confirmation_alert, selected_sku_properties, all_sales_channels, set_page_loader, active_group_name, active_division_id, fetch_flag ,handleClose , furniture  , fetch_sku_get_details, sku_category_type, item_json, set_item_json, update_sku_properties, send_analytics_event, is_owned}) => {

	const alert = useAlert()
	const [add_button_flag , set_add_button_flag] = useState(false)
	const [add_new_sales_channel_selected , set_add_new_sales_channel_selected] = useState('none')
	const [delete_sales_channel_selected , set_delete_sales_channel_selected] = useState('')
	// const [is_laoding, set_is_loading] = useState(false);
	const [prices , set_prices] = useState(null)
	const [original_prices, set_original_prices] = useState();
	const [sku_sales_channels , set_sku_sales_channels] = useState([])
	const [display_all_sales_channels , set_display_all_sales_channels] = useState([])
	const [display_unit_options , set_display_unit_options] = useState([])
	// const [sku_hardware_data , set_sku_hardware_data] = useState('')
	const [pricing_dimension_options, set_pricing_dimension_options] = useState([]);
	// const [(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) , set_(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)] = useState(false)
	const [is_not_priced , set_is_not_priced] = useState(false)
	const [is_not_priced_backend , set_is_not_priced_backend] = useState(false)
	const [not_priced_custom_text, set_not_priced_custom_text] = useState(null);
	const [shutter_price_flag , set_shutter_price_flag] = useState('')
	const [is_OMC , set_is_OMC] = useState(false)
	const [selected_sales_array , set_selected_sales_array] = useState(false)
	const [ exclusion_list, set_exclusion_list ] = useState([]);
	const [index_not_priced, set_index_not_priced] = useState(null);
	const [index_not_priced_custom_text, set_index_not_priced_custom_text] = useState(null);
	const [editing_price_field_json, set_editing_price_field_json] = useState({});
	const [show_price_type_edit_button, set_show_price_type_edit_button] = useState(true)
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const [on_change_default_pricing, set_on_change_default_pricing] = useState(false);
	const [is_advanced_pricing_open, set_is_advanced_pricing_open] = useState(false);
	const [pt_additional_properties_modal, set_pt_additional_properties_modal] = useState(false);
	const toggle_pt_additional_properties_modal = () => set_pt_additional_properties_modal(!pt_additional_properties_modal)
	const [active_pt_additional_properties, set_active_pt_additional_properties] = useState({});
	const [is_advanced_pricing_enabled, set_is_advanced_pricing_enabled] = useState(false);
	const [show_advanced_pricing_disabled_modal, set_show_advanced_pricing_disabled_modal] = useState(false);

	const update_item_json = (index, key, value) => {
		let a = [...item_json];
		a[index].value = value;
		set_item_json(a);
	}

	// useEffect(() => {
	// 	const fetch_advanced_pricing_data = async () => {
	// 		var resp = await general_fetch({url: 'flagged_feature/get_all'})
	// 		var advanced_pricing_features = resp.find(x => x.type && x.type == "infurnia_controlled" && x.id && x.id == "advanced_pricing_features")
	// 		// console.log("kp look here", advanced_pricing_features.store_status == "default" ? advanced_pricing_features.default_status : advanced_pricing_features.store_status)
	// 		set_is_advanced_pricing_enabled(advanced_pricing_features.store_status == "default" ? advanced_pricing_features.default_status != "disabled" : advanced_pricing_features.store_status != "disabled")
	// 		console.log("kp look here", !(advanced_pricing_features.store_status == "default" ? advanced_pricing_features.default_status != "disabled" : advanced_pricing_features.store_status != "disabled"))
	// 		console.log("completely buil unit", (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))
	// 	}

	// 	fetch_advanced_pricing_data()
	// }, [])

	useEffect(() => {
		console.log("item", item_json)
		let temp = false;
		if(item_json && item_json.length){
			item_json.map((x,idx) => {console.log("textx", x)
									if(x.key=='no_pricing_enabled'){
										temp = true
										set_index_not_priced(idx)
									  	if(x.value==true){
											set_is_not_priced_backend(x.value)
											set_is_not_priced(x.value)
									  }else{
										set_is_not_priced_backend(false)
										set_is_not_priced(false)
									  }
								}
								if(x.key == 'no_pricing_custom_text'){
									set_not_priced_custom_text(x.value)
									console.log("text", not_priced_custom_text)
									set_index_not_priced_custom_text(idx)
								}
							   }
			)
		}
		if(!temp){
			let a = [...item_json];
            a.push({key:'no_pricing_enabled', value:false})
			set_index_not_priced(a.length-1)
			a.push({key:'no_pricing_custom_text', value:''})
			set_index_not_priced_custom_text(a.length-1)
			set_item_json(a);
		}
	},[item_json])

	useEffect(() => {
		if(is_not_priced_backend){
			set_is_advanced_pricing_open(true)
		}
	}, [is_not_priced_backend]);

	const onclick_update_not_priced  = () => {
		if(is_not_priced){
			console.log("index", index_not_priced, index_not_priced_custom_text, "is_not", is_not_priced)
			update_item_json(index_not_priced, 'no_pricing_enabled', is_not_priced)
			update_item_json(index_not_priced_custom_text, 'no_pricing_custom_text', not_priced_custom_text)
		}
		else {
			update_item_json(index_not_priced, 'no_pricing_enabled', is_not_priced)
		}
		console.log("inde", item_json);
		update_sku_properties();
		fetch_sku_get_details();
		// set_is_loading(true);
		console.log("selected", selected_sku_properties)
		// reload()
	}

	const fetch_advanced_pricing_data = async (is_cbu, prices) => {
		var resp = await general_fetch({url: 'store/get_info', body: {'include_add_ons': true, 'include_flagged_feature_add_on_map': true}})
		var advanced_pricing_features = false;
		try{
			advanced_pricing_features = resp.add_ons.active.map(item => item.id).includes(resp.flagged_feature_add_on_map.find(x => x.flagged_feature_id == "advanced_pricing_features").add_on_id)
		}
		catch(err) {
			console.log(err)
		}
		// console.log("kp look here", advanced_pricing_features.store_status == "default" ? advanced_pricing_features.default_status : advanced_pricing_features.store_status)
		set_is_advanced_pricing_enabled(advanced_pricing_features)
		
		set_show_advanced_pricing_disabled_modal(!advanced_pricing_features && (sku_category_type != "shutter_hardware" ? ((is_cbu && (prices && prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.exclusion_list && prices['default_price'].frontened_price.exclusion_list.length ? (prices['default_price'].frontened_price.exclusion_list[0].id ? prices['default_price'].frontened_price.exclusion_list : exclusion_list_options.filter(item => prices['default_price'].frontened_price.exclusion_list.includes(item.id)) ) : []) && (prices && prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.exclusion_list && prices['default_price'].frontened_price.exclusion_list.length ? (prices['default_price'].frontened_price.exclusion_list[0].id ? prices['default_price'].frontened_price.exclusion_list : exclusion_list_options.filter(item => prices['default_price'].frontened_price.exclusion_list.includes(item.id)) ) : []).length > 0) || (!is_cbu)) : (!selected_sku_properties.pricing_from) )) // 
	}

	const fetch_sku_sales_channels_all_prices = async () => {
		try {
			
			let sales_channels_details = [], prices_details = {}

			let body1 = {
				"sku_ids": [selected_sku_properties.id],
				"filter_by_user": false,
				"filter_by_store": org_bu_view == 'org' ? true : false,
				"filter_by_business_unit_id": org_bu_view == 'bu' ? user.current_business_unit_id : null, 
				"reduce_by_id": false,
				"include_ancestry_info": false, 
				"include_material_info": false, 
				"include_material_template_info": false, 
				"include_pricing_info": true, 
				"include_sales_channel_info": true, 
				"include_brand_info": false, 
				"include_model_3d_info": false, 
				"include_display_pic_info": false,
				"include_tag_mapping": false, 
				"include_tag_info": false, 
				"include_serial_number_info": false
			}
			let sku_details  = await general_fetch({ url: 'sku/get_by_id' , body : body1 });
			prices_details = sku_details.skus[0].pricing
			let t = Object.keys(prices_details.sales_channel_prices) && Object.keys(prices_details.sales_channel_prices).length ? Object.keys(prices_details.sales_channel_prices).map((sales_channel_id) => {
				let temp = Object.keys(prices_details.sales_channel_prices[sales_channel_id]) && Object.keys(prices_details.sales_channel_prices[sales_channel_id]).length ? Object.keys(prices_details.sales_channel_prices[sales_channel_id]).map((price_type_id) => {
					prices_details.sales_channel_prices[sales_channel_id][price_type_id]['price_type'] = sku_details.price_types[price_type_id]
				}):''
			}) : ''
			sales_channels_details = sku_details.skus[0].sales_channel_ids && sku_details.skus[0].sales_channel_ids.length ?  sku_details.skus[0].sales_channel_ids.map(x => sku_details.sales_channels[x]) : []
			console.log('sales_channels_details', sales_channels_details, prices_details)
			Promise.all([sales_channels_details, prices_details]).then((values) => {
				sales_channels_details.push({id:'default_price'})
				var front_price_data=null;
				sales_channels_details && sales_channels_details.map((sc_item) => {

					if(sc_item.id==='default_price'){
						if(prices_details[sc_item.id] && prices_details[sc_item.id].immediate_price && prices_details[sc_item.id].immediate_price.use_referred_price){
							front_price_data = prices_details[sc_item.id].inherited_price;
							front_price_data={...front_price_data, pricing_mode:'default', price:front_price_data ? (Math.round(calculate_display_price(front_price_data.price, front_price_data.display_unit) * 10000000000) / 10000000000) : 0}
							prices_details[sc_item.id] = {...prices_details[sc_item.id], frontened_price:front_price_data}
						}else{
							front_price_data = prices_details[sc_item.id]?prices_details[sc_item.id].immediate_price:'';
							front_price_data={...front_price_data, pricing_mode:'custom', price:front_price_data ? (Math.round(calculate_display_price(front_price_data.price, front_price_data.display_unit) * 10000000000) / 10000000000) : 0}
							prices_details[sc_item.id] = {...prices_details[sc_item.id], frontened_price:front_price_data}
						}
					}else{
						prices_details && prices_details['sales_channel_prices'] && prices_details['sales_channel_prices'][sc_item.id] && Object.keys(prices_details['sales_channel_prices'][sc_item.id]).length &&  Object.keys(prices_details['sales_channel_prices'][sc_item.id]).map((price_type_id, idx) => {
							if(prices_details['sales_channel_prices'][sc_item.id][price_type_id] && prices_details['sales_channel_prices'][sc_item.id][price_type_id].actual===false) {
								front_price_data = prices_details['sales_channel_prices'][sc_item.id][price_type_id].inherited_price;
								front_price_data={...front_price_data, pricing_mode:'default', price:front_price_data ? (Math.round(calculate_display_price(front_price_data.price, front_price_data.display_unit) * 10000000000) / 10000000000) : 0}
								prices_details['sales_channel_prices'][sc_item.id][price_type_id] = {...prices_details['sales_channel_prices'][sc_item.id][price_type_id], frontened_price:front_price_data}
							}
							else if(prices_details['sales_channel_prices'][sc_item.id][price_type_id] && prices_details['sales_channel_prices'][sc_item.id][price_type_id].immediate_price && prices_details['sales_channel_prices'][sc_item.id][price_type_id].immediate_price.use_referred_price){
								front_price_data = prices_details['sales_channel_prices'][sc_item.id][price_type_id].inherited_price;
								front_price_data={...front_price_data, pricing_mode:'default', price:front_price_data ? (Math.round(calculate_display_price(front_price_data.price, front_price_data.display_unit) * 10000000000) / 10000000000) : 0}
								prices_details['sales_channel_prices'][sc_item.id][price_type_id] = {...prices_details['sales_channel_prices'][sc_item.id][price_type_id], frontened_price:front_price_data}
							}else{
								front_price_data = prices_details['sales_channel_prices'][sc_item.id][price_type_id]?prices_details['sales_channel_prices'][sc_item.id][price_type_id].immediate_price:'';
								front_price_data={...front_price_data, pricing_mode:'custom', price:front_price_data ? (Math.round(calculate_display_price(front_price_data.price, front_price_data.display_unit) * 10000000000) / 10000000000) : 0}
								prices_details['sales_channel_prices'][sc_item.id][price_type_id] = {...prices_details['sales_channel_prices'][sc_item.id][price_type_id], frontened_price:front_price_data}
							}
						})
					}
					
				})
				set_sku_sales_channels(sales_channels_details);
				console.log('prices_details', prices_details)
				set_original_prices(JSON.parse(JSON.stringify(prices_details)))
				set_prices(JSON.parse(JSON.stringify(prices_details)));
				fetch_advanced_pricing_data(prices_details && prices_details['default_price'] && prices_details['default_price'].inherited_price && prices_details['default_price'].inherited_price.cbu, prices_details)

			})
			set_page_loader({
				show:false,
				text:''
			})
		} catch(err) {
			set_page_loader({
				show:true,
				text:'Error! Please Refresh.'
			})
			console.log(err);
			handleError(err)
		}
	}

	const onclick_update_price_for_dp = async (key) => {
		if(prices[key] && Number(prices[key].frontened_price.tax)<0){
			alert.error('Tax cannot have negative value')
		}else{
			try {
				// if(prices[key].frontened_price.pricing_mode === 'default'){
				// 	var body = {
				// 		sku_id:selected_sku_properties.id,
				// 		price_id: prices[key].frontened_price.id,
				// 		use_referred_price:prices[key].frontened_price.use_referred_price
				// 	}
				// }else{
				// 	var body = {
				// 		sku_id:selected_sku_properties.id,
				// 		price_id: prices[key].frontened_price.id,
				// 		tax:prices[key].frontened_price.tax, 
				// 		margin: prices[key].frontened_price.margin,
				// 		price:calculate_actual_price(prices[key].frontened_price.price, prices[key].frontened_price.display_unit), 
				// 		display_unit:prices[key].frontened_price.display_unit, 
				// 		use_referred_price:prices[key].frontened_price.use_referred_price,
				// 	};
				// }
				let body = {}
				// if(prices[key].frontened_price && prices[key].frontened_price.pricing_mode === 'default'){
				// 	body = {
				// 		'business_unit_id': org_bu_view=='bu' ? user.current_business_unit_id : null, 
				// 		'use_referred_price':prices[key].frontened_price.use_referred_price?1:0,
				// 		'sku_id': selected_sku_properties.id
				// 	}
				// }else 
				if(prices[key].frontened_price){
					body = {
						'business_unit_id': org_bu_view=='bu' ? user.current_business_unit_id : null, 
						'sku_id': selected_sku_properties.id,
						'tax':prices[key].frontened_price.tax?prices[key].frontened_price.tax:0, 
						'margin': prices[key].frontened_price.margin?prices[key].frontened_price.margin:0,
						'price':calculate_actual_price(prices[key].frontened_price.price, prices[key].frontened_price.display_unit)?calculate_actual_price(prices[key].frontened_price.price, prices[key].frontened_price.display_unit):0, 
						'display_unit':prices[key].frontened_price.display_unit,
						'additional_properties': [],
						'cbu': prices[key].frontened_price.cbu,
						'override_margin': prices[key].frontened_price.override_margin,
						'exclusion_list': prices[key].frontened_price.exclusion_list,
						'use_referred_price':0
					}
				}

				set_page_loader({
					show:true,
					text:'Updating prices...'
				})
				var prices_details = await general_fetch({ url: 'price/update' , body });
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "sales_channel", "sku_property_edited")
				fetch_sku_sales_channels_all_prices()
				set_page_loader({
					show:false,
					text:''
				})
				set_on_change_default_pricing(false)
				alert.success('Default Price Updated Successfully')
			} catch(err) {
				console.log(err);
				alert.error('Could not update price')
				set_page_loader({
					show:false,
					text:''
				})
			}
		}	
	}

	const onclick_update_price_for_sc = async (sc_item_id,price_type_id) => {
		if(prices['sales_channel_prices'][sc_item_id][price_type_id] && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && Number(prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.tax)<0){
			alert.error('Tax cannot have negative value')
		}else if(prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.pricing_mode === 'default' && (prices['sales_channel_prices'][sc_item_id][price_type_id].actual===false || (prices['sales_channel_prices'][sc_item_id][price_type_id].actual && prices['sales_channel_prices'][sc_item_id][price_type_id].immediate_price.use_referred_price))){
			alert.info('Please change pricing mode to custom to edit pricing values .')
		}else{
			try {
				let body = {}
				if(prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.pricing_mode === 'default'){
					// var updates= JSON.stringify({use_referred_price:prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.use_referred_price})
					body = {
						'sku_id':selected_sku_properties.id,
						'business_unit_id': org_bu_view=='bu' ? user.current_business_unit_id : null, 
						'sales_channel_id':sc_item_id,
						'price_type_id': price_type_id,
						'use_referred_price':prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.use_referred_price?1:0,

					}
				}else{
					// var updates= JSON.stringify({tax:prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.tax, margin: prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.margin,price:calculate_actual_price(prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.price, prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.display_unit), display_unit:prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.display_unit, use_referred_price:prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.use_referred_price});
					body = {
						'sku_id':selected_sku_properties.id,
						'business_unit_id': org_bu_view=='bu' ? user.current_business_unit_id : null, 
						'sales_channel_id':sc_item_id,
						'price_type_id': price_type_id,
						'tax':prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.tax?prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.tax:0, 
						'margin': prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.margin?prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.margin:0,
						'additional_properties': prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.additional_properties?prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.additional_properties:0,
						'price':prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && calculate_actual_price(prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.price, prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.display_unit)?calculate_actual_price(prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.price, prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.display_unit):0, 
						'display_unit':prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.display_unit ? prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.display_unit : 'none', 
						'use_referred_price':prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item_id][price_type_id].frontened_price.use_referred_price?1:0,
					};
				}
				set_page_loader({
					show:true,
					text:'Updating prices...'
				})
				var prices_details = await general_fetch({ url: 'price/update' , body });
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "sales_channel", "sku_property_edited")
				fetch_sku_sales_channels_all_prices()
				update_editing_price_field_json(sc_item_id, price_type_id, false)
				set_page_loader({
					show:false,
					text:''
				})
				alert.success('Price Updated Successfully')
			} catch(err) {
				console.log(err);
				alert.error('Could not update price')
				set_page_loader({
					show:false,
					text:''
				})
			}
		}	
	}

	const onclick_update_component_file = async () => {
		if(selected_sku_properties ){
			try {
				var body = {sku_id : selected_sku_properties.id , component_file: JSON.stringify(selected_sku_properties.component_file)}
				if(selected_sku_properties && selected_sku_properties.modular)
						body['modular'] = true;
				var reponse_for_update = await general_fetch({ url: 'sku/update' , body });
				alert.success("Sku Updated Successfully")
				fetch_sku_get_details()
			} catch(err) {
				alert.error("Failed to update Sku")
				console.log(err);
			}
		}else{
			alert.error("Missing Details")
		}	
	}

	const onclick_add_sales_channel = async (add_new_sales_channel_selected) => {
		try{
			if(add_new_sales_channel_selected){
				var body = {sku_ids : [selected_sku_properties.id], sales_channel_id : add_new_sales_channel_selected}
				var reponse = await general_fetch({url :'sales_channel/add_skus' , body })
				alert.success("Sales Channel mapped successfully")
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "sales_channel", "sku_property_edited")
				set_prices(null)
				set_add_new_sales_channel_selected('none')
				fetch_sku_sales_channels_all_prices()
				set_editing_price_field_json({})
				set_show_price_type_edit_button(true)
			}	
		} catch(err) {
			console.log(err)
		}
	}

	const onclick_delete_sales_channel_execute = async () => {
		try{
			if(delete_sales_channel_selected){
				var body = {sku_ids : [selected_sku_properties.id], sales_channel_id : delete_sales_channel_selected}
				var response = await general_fetch({url :'sales_channel/remove_skus' , body , req_resp:true})
				if(response && response.response_code == 1){
					alert.success('Sales Channel unmapped successfully')
					send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "sales_channel", "sku_property_edited")
					set_delete_sales_channel_selected('')
					fetch_sku_sales_channels_all_prices()
				}else if(response.code == 'SKU_NOT_IN_SALES_CHANNEL'){
					alert.error('Sku not in Sales Channel');
				}
			}
		} catch(err) {
			console.log(err)
			handleError(err);
		}
	}

	useEffect(() => {
		
		if(selected_sku_properties && selected_sku_properties.sku_group && selected_sku_properties.sku_group.sku_sub_category && selected_sku_properties.sku_group.sku_sub_category.sku_category && selected_sku_properties.sku_group.sku_sub_category.sku_category.sku_category_type && selected_sku_properties.sku_group.sku_sub_category.sku_category.sku_category_type.pricing_dimension_options){
			set_pricing_dimension_options(selected_sku_properties.sku_group.sku_sub_category.sku_category.sku_category_type.pricing_dimension_options.values ? selected_sku_properties.sku_group.sku_sub_category.sku_category.sku_category_type.pricing_dimension_options.values : [])
			console.log('set_pricing_dimension_options', selected_sku_properties.sku_group.sku_sub_category.sku_category.sku_category_type.pricing_dimension_options.values)
		}
		if(selected_sku_properties && selected_sku_properties.component_file){
			// var component_file = selected_sku_properties.component_file
			// var element = component_file.find(o => cbu_valid_types.includes(o.type));
			// var element = comp_file_top_level_elem(selected_sku_properties);
			// var is_sbu = element && element.data && element.data.non_modular ? element.data.non_modular :false;
			// var is_omc = element && element.data && element.data.override_margin ? element.data.override_margin :false;
			// var sales_id = element && element.data && element.data.overridden_sales_channel_id ? element.data.overridden_sales_channel_id :[];
			// var sales_id_details = all_sales_channels.filter(x=> sales_id.includes(x.id))
			// let current_exclusion_list = element && element.data ? element.data.exclusion_list : [];
			// if (current_exclusion_list && current_exclusion_list.length) {
			// 	set_exclusion_list(current_exclusion_list);
			// }
			// set_(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)(is_sbu)
			// set_is_OMC(is_omc)
			// const fetch_advanced_pricing_data = async () => {
			// 	var resp = await general_fetch({url: 'store/get_info', body: {'include_add_ons': true, 'include_flagged_feature_add_on_map': true}})
			// 	var advanced_pricing_features = false;
			// 	try{
			// 		advanced_pricing_features = resp.add_ons.active.map(item => item.id).includes(resp.flagged_feature_add_on_map.find(x => x.flagged_feature_id == "advanced_pricing_features").add_on_id)
			// 	}
			// 	catch(err) {
			// 		console.log(err)
			// 	}
			// 	// console.log("kp look here", advanced_pricing_features.store_status == "default" ? advanced_pricing_features.default_status : advanced_pricing_features.store_status)
			// 	set_is_advanced_pricing_enabled(advanced_pricing_features)
				
			// 	set_show_advanced_pricing_disabled_modal(!advanced_pricing_features && (sku_category_type != "shutter_hardware" ? ((is_sbu && (prices && prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.exclusion_list && prices['default_price'].frontened_price.exclusion_list.length ? (prices['default_price'].frontened_price.exclusion_list[0].id ? prices['default_price'].frontened_price.exclusion_list : exclusion_list_options.filter(item => prices['default_price'].frontened_price.exclusion_list.includes(item.id)) ) : []) && (prices && prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.exclusion_list && prices['default_price'].frontened_price.exclusion_list.length ? (prices['default_price'].frontened_price.exclusion_list[0].id ? prices['default_price'].frontened_price.exclusion_list : exclusion_list_options.filter(item => prices['default_price'].frontened_price.exclusion_list.includes(item.id)) ) : []).length > 0) || (!is_sbu)) : (!selected_sku_properties.pricing_from) )) // 
			// }
			// fetch_advanced_pricing_data()
			// if(is_sbu) set_is_not_priced(false)
			// set_selected_sales_array(sales_id_details)
		}
		if(sku_category_type==='shutter_hardware') {
			set_shutter_price_flag(selected_sku_properties.pricing_from?selected_sku_properties.pricing_from:'constituents')
		}
	},[selected_sku_properties])

	// useEffect(() => {
	// 	if (sku_hardware_data && sku_hardware_data.pricing) {
	// 		var pricing_system = sku_hardware_data.pricing.pricing_system;
	// 		var options=[];
	// 		if (pricing_system == 'per_unit_length') {
	// 			options = [{id:'metre', text:'Metre'},{id:'millimetre', text:'Millimetre'} ,{id:'foot', text:'Foot'}];
	// 		} else if (pricing_system == 'per_unit_area') {
	// 			options = [{id:'sq_metre', text:'Square Meter'},{id:'sq_millimetre', text:'Square Millimetre'} ,{id:'sq_foot', text:'Square Foot'}];
	// 		} else if (pricing_system == 'per_unit_volume') {
	// 			options = [{id:'cu_metre', text:'Cubic Meter'},{id:'cu_millimetre', text:'Cubic Millimetre'} ,{id:'cu_foot', text:'Cubic Foot'}];
	// 		} else if (pricing_system == 'per_unit_weight') {
	// 			options = [{id:'kilogram', text:'kilogram'},{id:'gram', text:'Gram'} ,{id:'pound', text:'Pound'}];
	// 		} else {
	// 			options = [{id:'per_unit', text:'Per Unit'}]
	// 		}
	// 	} else {
	// 		options = [{id:'per_unit', text:'Per Unit'}]
	// 	}
	// 	set_display_unit_options(options);
		
	// },[sku_hardware_data])

	useEffect(() => {

		if(prices){
			let faulty_case_units = new Set()
			if(prices.default_price && prices.default_price.frontened_price && prices.default_price.frontened_price.display_unit){
				faulty_case_units.add(prices.default_price.frontened_price.display_unit)
			}

			if(prices.sales_channel_prices && Object.keys(prices.sales_channel_prices) && Object.keys(prices.sales_channel_prices).length){
				Object.keys(prices.sales_channel_prices).map(sc_id => {
					let single_sales_channel = prices.sales_channel_prices[sc_id]
					if(single_sales_channel && Object.keys(single_sales_channel) && Object.keys(single_sales_channel).length){
						Object.keys(single_sales_channel).map(price_type_id => {
							let single_price_type = single_sales_channel[price_type_id]
							if(single_price_type && single_price_type.frontened_price && single_price_type.frontened_price.display_unit){
								faulty_case_units.add(single_price_type.frontened_price.display_unit)
							}
						})
					}
				})
			}

			let faulty_case_units_array = Array.from(faulty_case_units)
			faulty_case_units_array = faulty_case_units_array && faulty_case_units_array.length ? faulty_case_units_array.map(x => all_units.find(o => o.id === x)) : []
			console.log('faulty_case_units_array', faulty_case_units_array)

			if(pricing_dimension_options && pricing_dimension_options.length){
				let options = new Set()
				pricing_dimension_options.map((pricing_dimension) => {
					if (pricing_dimension == 'per_unit_length') {
						['metre', 'millimetre', 'foot'].forEach(x => options.add(x))
					} else if (pricing_dimension == 'per_unit_area') {
						['sq_metre', 'sq_millimetre', 'sq_foot'].forEach(x => options.add(x))
					} else if (pricing_dimension == 'per_unit_volume') {
						['cu_metre', 'cu_millimetre', 'cu_foot'].forEach(x => options.add(x))
					} else if (pricing_dimension == 'per_unit_weight') {
						['kilogram', 'gram', 'pound'].forEach(x => options.add(x))
					}else if (pricing_dimension == 'per_set'){
						options.add('per_set')
					} else {
						options.add('per_unit')
					}
				})
				options = new Set([...options, ...faulty_case_units])
				let options_array = Array.from(options)
				options_array = options_array && options_array.length ? options_array.map(x => all_units.find(o => o.id === x)) : []
				console.log('set_pricing_dimension_options', options, options_array);
				set_display_unit_options(options_array)
			}else{
				let options = new Set(['per_unit'])
				options = new Set([...options, ...faulty_case_units])
				let options_array = Array.from(options)
				options_array = options_array && options_array.length ? options_array.map(x => all_units.find(o => o.id === x)) : []
				set_display_unit_options(options_array)
			}
		}
	}, [pricing_dimension_options, prices]);

	useEffect(() => {
		var x_filtered = [...all_sales_channels];
		if(all_sales_channels && all_sales_channels.length && sku_sales_channels && sku_sales_channels.length){
			var x_sales_ids = sku_sales_channels.map(x => x.id)
			var x_filtered = all_sales_channels.filter(x => !(x_sales_ids.includes(x.id)))
		}
		set_display_all_sales_channels(x_filtered)
	},[all_sales_channels, sku_sales_channels])

	useEffect(() => {
		if(display_all_sales_channels && display_all_sales_channels.length){
			// set_add_new_sales_channel_selected(display_all_sales_channels[0].id)
			set_add_new_sales_channel_selected('none')
		}
	},[display_all_sales_channels])

	useEffect(() => {
		console.log('user=======>', selected_sku_properties)
	},[selected_sku_properties])

	const onclick_handleclose = () => {
		handleClose();
		set_add_button_flag(false)
		set_prices(null)
		set_add_new_sales_channel_selected('none')
		set_delete_sales_channel_selected('')
		set_sku_sales_channels([])
		// set_sku_hardware_data('')
		set_pricing_dimension_options([])
		// set_(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)(false)
		// set_is_OMC(false)
		set_on_change_default_pricing(false)
	}
	
	const onclick_back = () => {
		set_add_button_flag(false)
	}

	const onclick_open_add_buton_flag = () => {
		set_add_button_flag(true)
	}

	const onclick_delete_sales_channel = (id) =>{
		set_delete_sales_channel_selected(id)
		set_show_price_type_edit_button(true)
		set_editing_price_field_json({})
	}

	useEffect(() => {
		if(delete_sales_channel_selected){
			onclick_delete_sales_channel_execute();
		}	
	},[delete_sales_channel_selected])

	useEffect(() => {
		if(selected_sku_properties &&  selected_sku_properties.id){
			fetch_sku_sales_channels_all_prices()
		}
	},[fetch_flag, selected_sku_properties])

	const calculate_display_price = (qty, unit) => {
        if (!unit) {
            return qty;
        }

        if (unit.indexOf('sq_') > -1) {
            console.log('checking for sq')
            var actual_unit =  unit.split('sq_')[1];
            return calculate_display_price(calculate_display_price(qty, actual_unit), actual_unit)
        }

        if (unit.indexOf('cu_') > -1) {
            var actual_unit =  unit.split('cu_')[1];
            return calculate_display_price(calculate_display_price(calculate_display_price(qty, actual_unit), actual_unit), actual_unit)
        }

        if (unit == 'metre') {
            return qty * 1000;
        } else if (unit == 'foot') {
            return qty * 304.8;
        } else {
            return qty;
        }
    }

    //converts to mm
    const calculate_actual_price = (qty, unit) => {
        if (!unit) {
            return qty;
        }

        if (unit.indexOf('sq_') > -1) {
            var actual_unit =  unit.split('sq_')[1];
            return calculate_actual_price(calculate_actual_price(qty, actual_unit), actual_unit)
        }

        if (unit.indexOf('cu_') > -1) {
            var actual_unit =  unit.split('cu_')[1];
            return calculate_actual_price(calculate_actual_price(calculate_actual_price(qty, actual_unit), actual_unit), actual_unit)
        }

        if (unit == 'metre') {
            return qty / 1000;
        } else if (unit == 'foot') {
            return qty / 304.8;
        } else {
            return qty;
        }   
    }

	const update_single_sales = (key,sales_channel_id,price_type_key,operator,value,unit) => {
		var price_temp = {...prices}
		if(prices && key==='default_price'){
			// if(operator==='pricing_mode' && value==='default'){
			// 	//price_temp[key].frontened_price.id = price_temp['default'].inherited_price.id
			// 	prices[key].frontened_price.price = price_temp['default_price'].inherited_price.price
			// 	prices[key].frontened_price.display_unit = price_temp['default_price'].inherited_price.display_unit
			// 	prices[key].frontened_price.tax = price_temp['default_price'].inherited_price.tax
			// 	prices[key].frontened_price.margin = price_temp['default_price'].inherited_price.margin
			// 	prices[key].frontened_price.pricing_mode = 'default_price'
			// 	prices[key].frontened_price.use_referred_price = 1
			// }else if(operator==='pricing_mode' && value==='custom'){
				// prices[key].frontened_price.id = prices[key].immediate_price.id
				// prices[key].frontened_price.price = prices[key].immediate_price.price
				// prices[key].frontened_price.display_unit = prices[key].immediate_price.display_unit
				// prices[key].frontened_price.tax = prices[key].immediate_price.tax
				// prices[key].frontened_price.margin = prices[key].immediate_price.margin
				// prices[key].frontened_price.pricing_mode = 'custom'
				// prices[key].frontened_price.use_referred_price = 0
			// }else 
			// if(operator==='price' && operator==='tax' && operator==='margin'){
				prices[key].frontened_price.id = prices[key].immediate_price.id
				prices[key].frontened_price.pricing_mode = 'custom'
				prices[key].frontened_price.use_referred_price = 0
				if(operator != 'exclusion_list')
					prices[key].frontened_price[operator] =value;
				else
					prices[key].frontened_price[operator] = value.map(item => item.id)
				set_on_change_default_pricing(true)
			// }
			// else{
			// 	prices[key].frontened_price[operator] = (value==='none'?null:value);
			// 	console.log('price_temp====>', price_temp);
			// }
		}else if(prices){
			if(operator==='pricing_mode' && value==='default'){
				//price_temp[key].frontened_price.id = price_temp['default'].inherited_price.id
				prices[key][sales_channel_id][price_type_key].frontened_price.price = price_temp['default_price'].inherited_price.price ? (Math.round(calculate_display_price(price_temp['default_price'].inherited_price.price , price_temp['default_price'].inherited_price.display_unit) * 10000000000) / 10000000000) : 0
				prices[key][sales_channel_id][price_type_key].frontened_price.display_unit = price_temp['default_price'].inherited_price.display_unit
				prices[key][sales_channel_id][price_type_key].frontened_price.tax = price_temp['default_price'].inherited_price.tax
				prices[key][sales_channel_id][price_type_key].frontened_price.margin = price_temp['default_price'].inherited_price.margin
				prices[key][sales_channel_id][price_type_key].frontened_price.additional_properties = price_temp['default_price'].inherited_price.additional_properties
				prices[key][sales_channel_id][price_type_key].frontened_price.pricing_mode = 'default'
				prices[key][sales_channel_id][price_type_key].frontened_price.use_referred_price = true
			}else if(operator==='pricing_mode' && value==='custom'){
				prices[key][sales_channel_id][price_type_key].frontened_price.id = prices[key][sales_channel_id][price_type_key].immediate_price.id
				prices[key][sales_channel_id][price_type_key].frontened_price.price = prices[key][sales_channel_id][price_type_key].immediate_price.price ? (Math.round(calculate_display_price(prices[key][sales_channel_id][price_type_key].immediate_price.price, prices[key][sales_channel_id][price_type_key].immediate_price.display_unit) * 10000000000) / 10000000000) : 0
				prices[key][sales_channel_id][price_type_key].frontened_price.display_unit = prices[key][sales_channel_id][price_type_key].immediate_price.display_unit
				prices[key][sales_channel_id][price_type_key].frontened_price.tax = prices[key][sales_channel_id][price_type_key].immediate_price.tax
				prices[key][sales_channel_id][price_type_key].frontened_price.margin = prices[key][sales_channel_id][price_type_key].immediate_price.margin
				prices[key][sales_channel_id][price_type_key].frontened_price.additional_properties = prices[key][sales_channel_id][price_type_key].immediate_price.additional_properties
				prices[key][sales_channel_id][price_type_key].frontened_price.pricing_mode = 'custom'
				prices[key][sales_channel_id][price_type_key].frontened_price.use_referred_price = false
			}else if(operator==='price' && operator==='tax' && operator==='margin' || operator == 'additional_properties'){
				prices[key][sales_channel_id][price_type_key].frontened_price[operator] =value;
			}else{
				prices[key][sales_channel_id][price_type_key].frontened_price[operator]= value==='none'?null:value;
				console.log('price_temp====>', price_temp);
			}
		}
		set_prices(price_temp);
	}

	const onchange_CBU = () => {
		// var new_cbu_value = !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) 
		if(prices && prices['default_price'] && prices['default_price'].inherited_price){
			var new_cbu_value = !(prices['default_price'].inherited_price.cbu)
			// set_(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)(new_cbu_value);
			// let element = comp_file_top_level_elem(selected_sku_properties)
			// if(!element.data){
			// 	element.data = {}
			// }
			// element.data.non_modular = new_cbu_value;

			// onclick_update_component_file()
			update_single_sales('default_price', '', '', 'cbu', new_cbu_value, '');
			onclick_update_price_for_dp('default_price');
		}
	}

	const onchange_not_priced = () => {
		set_is_not_priced(!is_not_priced)
	}
	
	const update_exclusion_list = (list) => {
		// set_exclusion_list(list);
		
		// let element = comp_file_top_level_elem(selected_sku_properties)
		// if(!element.data){
		// 	element.data = {}
		// }
		// element.data.exclusion_list = list;

		// onclick_update_component_file()
		update_single_sales('default_price', '', '', 'exclusion_list', list, '')
		onclick_update_price_for_dp('default_price')
	}

	const onchange_OMC = () => {
		if(prices && prices['default_price'] && prices['default_price'].inherited_price){
			var new_omc_value = !(prices['default_price'].inherited_price.override_margin);
			// set_is_OMC(new_omc_value);

			// let element = comp_file_top_level_elem(selected_sku_properties)
			// if(!element.data){
			// 	element.data = {}
			// }
			// element.data.override_margin = new_omc_value;
			
			// onclick_update_component_file()
			update_single_sales('default_price', '', '', 'override_margin', new_omc_value, '');
			onclick_update_price_for_dp('default_price');
		}
	}

	const onclick_update_shutter_logic = async(value) => {
		try {
				set_page_loader({
					show:true,
					text:'Updating Please wait...'
				})
				let body = {sku_id : selected_sku_properties.id , pricing_from:value}
				if(selected_sku_properties && selected_sku_properties.modular)
						body['modular'] = true;
				var resp = await general_fetch({ url: 'sku/update', body});
				fetch_sku_get_details();
				alert.success('SKU updated successfully')
				set_page_loader({
					show:false,
					text:''
				})
			}catch(err) {
				console.log(err);
				alert.error('Error , Api Failed')
				set_page_loader({
					show:true,
					text:'Please refresh'
				})
			}
	}

	const onchange_shutter_price_logic = (value) => {
		set_shutter_price_flag(value);
		onclick_update_shutter_logic(value)
	}

	const update_editing_price_field_json = (sales_channel_id, price_type_id, value) => {
		let a = JSON.parse(JSON.stringify(editing_price_field_json))
		a[sales_channel_id+price_type_id] = value
		set_editing_price_field_json(a)
		console.log('Updated editing price', a)
		if(value){
			set_show_price_type_edit_button(false)
		}else{
			set_show_price_type_edit_button(true)
		}
	}

	const toggle_advanced_pricing = () => {set_is_advanced_pricing_open(!is_advanced_pricing_open)}

	// useEffect(() => {
	// 	set_is_loading(false)
	// },[is_not_priced_backend])

	// const onchange_OMC = () => {
	// 	if(selected_sales_array && selected_sales_array.length){
	// 		var new_omc_value = !is_OMC;
	// 		var sales_ids = new_omc_value?selected_sales_array.map(x => x.id):[]
	// 		set_is_OMC(new_omc_value);
	// 		selected_sku_properties.component_file.map(o =>{
	// 			if(cbu_valid_types.includes(o.type)){
	// 				o.data.override_margin = new_omc_value;
	// 				o.data.overridden_sales_channel_id = sales_ids;
	// 			}
	// 		})
	// 		onclick_update_component_file()
	// 	}else{
	// 		alert.error('Please Select Sales Channel')
	// 	}
	// }

	// const onselect_sales_options = (selected_list) => {
	// 	set_selected_sales_array(selected_list)
	// }	

    return (
		<React.Fragment>
			<AdvancedPricingDisabledModal show_advanced_pricing_disabled_modal={show_advanced_pricing_disabled_modal} set_show_advanced_pricing_disabled_modal={set_show_advanced_pricing_disabled_modal} sku_category_type={sku_category_type}/>
			<PtAddnPropsModal open={pt_additional_properties_modal} toggle={toggle_pt_additional_properties_modal} update_single_sales={update_single_sales} sc_item_id={active_pt_additional_properties.sc_item_id} price_type_id={active_pt_additional_properties.price_type_id} additional_properties={active_pt_additional_properties.additional_properties} disabled={((prices && prices['sales_channel_prices'] && prices['sales_channel_prices'][active_pt_additional_properties.sc_item_id] && prices['sales_channel_prices'][active_pt_additional_properties.sc_item_id][active_pt_additional_properties.price_type_id] && prices['sales_channel_prices'][active_pt_additional_properties.sc_item_id][active_pt_additional_properties.price_type_id].frontened_price && prices['sales_channel_prices'][active_pt_additional_properties.sc_item_id][active_pt_additional_properties.price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[active_pt_additional_properties.sc_item_id+active_pt_additional_properties.price_type_id]))?true:false}/>
			<CardBody className='global_modal_body DMSans'>
				<div style={{fontSize:'16px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform:'capitalize', marginBottom:'10px'}}>Manage Default Pricing</div>
				{/* <div style={{fontSize:'16px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform:'capitalize', marginBottom:'10px'}}>Manage Sales Channel - {selected_sku_properties?selected_sku_properties.name:''}</div> */}
				<div style={{backgroundColor: 'white', border: '1px solid #D5DAE0', borderRadius: '4px', marginBottom:'13px', padding:"20px", fontSize:'14px'}}>
					{/* <div style= {{textAlign : "left", marginBottom:'10px'}}>
						<span style = {{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold'}}>Manage Default Pricing</span>
					</div> */}
					{
						is_not_priced_backend ?
							<div className='sku_pricing_info_container'>Item is <b>Not Priced</b></div>
							:
							<React.Fragment>
								{
									selected_sku_properties && selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) ?
									<div className='flex_property' style={{ opacity: !is_owned ? '0.5' : '1' }}>
										<div className='mb-3' style={{ display: 'flex', fontSize: '12px' }}>
											<div className='lines1_elipsis flex_center' key={1} onClick={() => { if (!((prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) || is_not_priced_backend || !is_owned)) { confirmation_alert(onchange_CBU, () => { }, 'This action causes changes to sensitive pricing related data. Are you sure you want to continue?', true, 'Change CBU status') } }} style={(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && !is_not_priced_backend ? { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#0078ff', padding: '6px 10px', cursor: 'pointer', fontWeight: 700, color: '#0078ff' } : { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderRightColor: 'transparent', padding: '6px 10px', cursor: 'pointer', }} >
												<div>Price as Completely built unit</div>
											</div>
											<Tooltip title={is_advanced_pricing_enabled?'':'To use this feature, you need to buy Advanced Pricing add-on. Please reach out to your customer success executive for more details'} placement='top' mouseEnterDelay={0}>
												<div className='lines1_elipsis flex_center' key={2} onClick={ is_advanced_pricing_enabled? () => {if (!(!(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) || is_not_priced_backend || !is_owned)) { confirmation_alert(onchange_CBU, () => { }, 'This action causes changes to sensitive pricing related data. Are you sure you want to continue?', true, 'Change CBU status') } } : null} style={!(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && !is_not_priced_backend ? { borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: is_advanced_pricing_enabled?'#0078ff':'#CCCCCC', padding: '6px 10px', cursor: is_advanced_pricing_enabled?'pointer':'default', fontWeight: 700, color: is_advanced_pricing_enabled?'#0078ff':'#CCCCCC'} : { borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: is_advanced_pricing_enabled?'#C5C7CF':'#CCCCCC', borderLeftColor: 'transparent', padding: '6px 10px', cursor: is_advanced_pricing_enabled?'pointer':'default', color: is_advanced_pricing_enabled?'':'#CCCCCC'}} >
													<div>Priced from constituent elements</div>
												</div>
											</Tooltip>
										</div>
									</div>
										:
									['shutter_hardware'].includes(sku_category_type) ?
										<div className='flex_property' style={{ opacity: !is_owned ? '0.5' : '1' }}>
											<div className='mb-3' style={{ display: 'flex', fontSize: '12px' }}>
												<div className='lines1_elipsis flex_center' key={1} onClick={() => { if (!(shutter_price_flag == 'design' || is_not_priced_backend || !is_owned)) { onchange_shutter_price_logic('design')} }} style={shutter_price_flag == 'design' ? { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#0078ff', padding: '4px', cursor: 'pointer', fontWeight: 700, color: '#0078ff' } : { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid', borderColor: '#C5C7CF', borderRightColor: 'transparent', padding: '4px', cursor: 'pointer', }} >
													<div>Shutter Priced from  shutter design</div>
												</div>
												<Tooltip title={is_advanced_pricing_enabled?'':'To use this feature, you need to buy Advanced Pricing add-on. Please reach out to your customer success executive for more details'} placement='top' mouseEnterDelay={0}>
													<div className='lines1_elipsis flex_center' key={2} onClick={is_advanced_pricing_enabled ? () => { if (!(shutter_price_flag == 'constituents' || is_not_priced_backend || !is_owned)) { onchange_shutter_price_logic('constituents')} } : null} style={shutter_price_flag == 'constituents' ? { borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: is_advanced_pricing_enabled?'#0078ff':'#CCCCCC', padding: '4px', cursor: is_advanced_pricing_enabled?'pointer':'default', fontWeight: 700, color: is_advanced_pricing_enabled?'#0078ff':'#CCCCCC' } : { borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid', borderColor: is_advanced_pricing_enabled?'#C5C7CF':'#CCCCCC', borderLeftColor: 'transparent', padding: '4px', cursor: is_advanced_pricing_enabled?'pointer':'default', color: is_advanced_pricing_enabled?'':'#CCCCCC'}} >
														<div>Shutter priced from constituents shutter design</div>
													</div>
												</Tooltip>
											</div>
										</div>
										: ''
								}
								{
									prices && (!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents' ) || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)))) ?
									<Row style={{ margin: 0, marginBottom: "10px", fontSize: '12px', display: "flex" }}>
										{/* <div style={{flex:2, marginRight:"15px"}}>
								<div>Default Pricing Mode</div>
								<Input
									style={{fontSize:'12px', cursor:"pointer"}}
									type='select' 
									id="demo-simple-select"
									variant="outlined"
									
									value = {prices['default_price'] && prices['default_price'].frontened_price ?prices['default_price'].frontened_price.pricing_mode:''}
									onChange = {(e) => update_single_sales('default_price','','','pricing_mode',e.target.value)}
								>
									{selected_sku_properties && !is_owned?<option value='default'>Default</option>:''}
									<option value='custom'>Custom</option>
								</Input>
							</div> */}
										<div style={{ flex: 2, marginRight: "15px" }}>
											<div>Default Price</div>
											<Input
												// latest disabled={(selected_sku_properties && selected_sku_properties.component_file && ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)) || (sku_category_type == 'shutter_hardware' && shutter_price_flag == 'constituents')}
												// disabled={prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?true:false}
												style={{ fontSize: '12px' }}
												type='number' required id="default_price" variant="outlined"
												value={prices && prices['default_price'] && prices['default_price'].frontened_price ? prices['default_price'].frontened_price.price : ''}
												onChange={/*prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?null:*/(e) => Number(e.target.value)>=0 ? update_single_sales('default_price', '', '', 'price', e.target.value, prices['default_price'].frontened_price.display_unit) : alert.error('Please enter a positive number')}
											/>
										</div>
										<div style={{ flex: 2, marginRight: "15px" }}>
											<div>Default Unit</div>
											<Input
												// disabled={prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?true:false}
												// latest disabled={(selected_sku_properties && selected_sku_properties.component_file && ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)) || (sku_category_type == 'shutter_hardware' && shutter_price_flag == 'constituents')}
												style={{ fontSize: '12px', cursor: "pointer" }}
												type='select'
												id="default_unit"
												variant="outlined"

												value={prices && prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.display_unit ? prices['default_price'].frontened_price.display_unit : 'none'}
												onChange={/*prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?null:*/(e) => update_single_sales('default_price', '', '', 'display_unit', e.target.value)}
											>
												{/* <option value='none'>None</option> */}
												{
													display_unit_options && display_unit_options.length ?
														display_unit_options.map((unit) => (
															<option key={unit.id} value={unit.id}>{unit.text}</option>
														))
														: ""
												}
											</Input>
										</div>

											<div style={{ flex: 2, marginRight: "15px" }}>
												<div>Tax (in %)</div>
												<Input
													// latest disabled={(selected_sku_properties && selected_sku_properties.component_file && ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)) || (sku_category_type == 'shutter_hardware' && shutter_price_flag == 'constituents')}
													// disabled={prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?true:false}
													style={{ fontSize: '12px' }}
													type='number' required id="default_tax" variant="outlined"
													value={prices && prices['default_price'] && prices['default_price'].frontened_price ? prices['default_price'].frontened_price.tax : ''}
													onChange={/*prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?null:*/(e) => Number(e.target.value)>=0 ? update_single_sales('default_price', '', '', 'tax', e.target.value, prices['default_price'].frontened_price.display_unit) : alert.error('Please enter a positive number')}
												/>
											</div>
											
											
											{/* <div style={{ flex: 2, marginRight: "15px" }}>
												<div>Margin (in %)</div>
												<Input
													// disabled={prices['default_price'] &&  prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?true:false}
													style={{ fontSize: '12px' }}
													type='number' required id="default_margin" variant="outlined"
													value={prices['default_price'] && prices['default_price'].frontened_price ? prices['default_price'].frontened_price.margin : ''}
												/>
											</div> */}

											<div style={{ flex: 2, marginRight: "15px" }}>
												<div>Margin (in %)</div>
												<Input
													// disabled={prices['default_price'] &&  prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?true:false}
													// latest disabled={((selected_sku_properties && selected_sku_properties.component_file && ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)) || (sku_category_type == 'shutter_hardware' && shutter_price_flag == 'constituents')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.override_margin)}
													style={{ fontSize: '12px' }}
													type='number' required id="default_margin" variant="outlined"
													value={prices && prices['default_price'] && prices['default_price'].frontened_price ? prices['default_price'].frontened_price.margin : ''}
													// onChange={/*prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?null:*/(e) => update_single_sales('default_price', '', '', 'margin', e.target.value, prices['default_price'].frontened_price.display_unit)}
													onChange={/*prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.pricing_mode==='default'?null:*/(e) => Number(e.target.value)>=0 ? (Number(e.target.value) >= 100 ? alert.error('Margin% = (1 - Cost Price/Selling Price) x 100. Margin% can not be 100 or more. Please enter a valid value.') : update_single_sales('default_price', '', '', 'margin', e.target.value, prices['default_price'].frontened_price.display_unit)) : alert.error('Please enter a positive number') }
													/>
											</div>

											<div style={{ flex: 2, marginRight: "15px" }}>
												<div>Selling Price</div>
												<div style={{ height: '32px', backgroundColor: '#FFFAE6', borderRadius: '8px', padding: '0px 8px' }} className='flex_property'>{prices && prices['default_price'] && prices['default_price'].frontened_price ? ((prices['default_price'].frontened_price.price / (1 - prices['default_price'].frontened_price.margin / 100)) * (1 + prices['default_price'].frontened_price.tax / 100)).toFixed(2) : ''}</div>
											</div>

											<div style={{ flex: 2, display: 'flex', justifyContent: "flex-end", alignItems: 'flex-end' }}>
												<Button disabled={!on_change_default_pricing} id='update_default_pricing' className='update_pricing_button' onClick={() => onclick_update_price_for_dp("default_price")} style={{}} >Update</Button>
											</div>
									</Row>
									:
							<div className='sku_pricing_info_container'>Priced as sum of all constituent elements</div>

								}

								{/* {
									['shutter_hardware'].includes(sku_category_type) ?
										<Row style={{ marginTop: '20px', marginBottom: '10px', opacity: !is_owned ? '0.5' : '1', pointerEvents: !is_owned ? 'none' : 'auto' }} onChange={(e) => onchange_shutter_price_logic(e.target.value)}>
											<Col xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingRight: 0, minWidth: '200px' }}>
												<Input value='constituents' checked={shutter_price_flag === 'constituents' ? true : false} style={{ position: 'unset', margin: '0px 10px 0px 0px' }} name='shutter_price_flag' type='radio'></Input>
												<span >Shutter Priced from constituents</span>
											</Col>
											<Col xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingRight: 0, minWidth: '200px' }}>
												<Input value='design' checked={shutter_price_flag === 'design' ? true : false} style={{ position: 'unset', margin: '0px 10px 0px 0px' }} name='shutter_price_flag' type='radio'></Input>
												<span >Shutter priced from shutter design</span>
											</Col>
										</Row>
										: ""
								} */}

								{
									(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && selected_sku_properties && selected_sku_properties.component_file && ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) ?
										<div className='flex_center'>
											{/* <div style={{flex:2, display:'flex', alignItems:'center',justifyContent:'flex-start', paddingRight:0, minWidth:'200px', opacity:(is_not_priced_backend || is_OMC || user.store_id != selected_sku_properties.store_id)?'.5':'1' }}>
									<Input 
										checked={(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)} 
										onClick ={() => is_not_priced_backend ? null : confirmation_alert(onchange_CBU, ()=>{}, 'This action causes changes to sensitive pricing related data. Are you sure you want to continue?', true, 'Change CBU status')}
										style={{position:'unset', margin:'0px 10px 0px 0px'}} 
										type='checkbox'
										disabled = {(user.store_id != selected_sku_properties.store_id) || is_OMC || is_not_priced_backend ? true : false}
									>
									</Input>
									<span >Price as completely built unit -</span>
								</div> */}
											<div className='flex_center' style={{ flex: 6 }}>
												<div style={{ display: 'flex', alignItems: "center", textAlign: 'right', opacity: ((prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && is_owned && is_advanced_pricing_enabled) ? '1' : '.5', margin: 0, marginRight:'24px', padding: 0, flexDirection: 'row-reverse' }}>
													Exclude these from pricing
												</div>
												<Tooltip title={!is_advanced_pricing_enabled ? 'To use this feature, you need to buy Advanced Pricing add-on. Please reach out to your customer success executive for more details' : ''} placement='bottom' mouseEnterDelay={0}>
													<div
														id='select_sales'
														style={{ flex: 1, display: 'flex', alignItems: "center", opacity: ((prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && is_owned && is_advanced_pricing_enabled) ? '1' : '.5', margin: '0px 10px 0px 0px', paddingLeft: 0, pointerEvents: ((prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && is_owned) ? 'auto' : 'none' }}
														onClick={((prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) && is_advanced_pricing_enabled) ? null : e => { console.log("clicked exclude"); e.stopPropagation() }}
													>
														<MultiSelectDropdown
															container_size='m'
															count_style={{padding: '0px', margin: 'unset', backgroundColor: 'unset', color: 'grey', borderRadius: '0', borderLeft: '1px solid #ced4da'}}
															item_txt=' items'
															options={exclusion_list_options && exclusion_list_options.length ? JSON.parse(JSON.stringify(exclusion_list_options)) : []}
															selectedValues={prices && prices['default_price'] && prices['default_price'].frontened_price && prices['default_price'].frontened_price.exclusion_list && prices['default_price'].frontened_price.exclusion_list.length ? (prices['default_price'].frontened_price.exclusion_list[0].id ? prices['default_price'].frontened_price.exclusion_list : exclusion_list_options.filter(item => prices['default_price'].frontened_price.exclusion_list.includes(item.id)) ) : []}
															onSelect={update_exclusion_list}
															onDeselect={update_exclusion_list}
															displayValue="name" //Property name 
															id='select_sales'
															placeholder='Select items'
															disable={((!is_owned) || is_not_priced_backend || !is_advanced_pricing_enabled) ? true : false}
														/>
													</div>
												</Tooltip>
											</div>
											<div style={{ flex: 1 }}></div>
											{/* <Col xs={3} style={{display:'flex', alignItems:'center',justifyContent:'flex-start', borderLeft:'1px solid black', opacity:(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)||is_not_priced_backend?'.5':'1'}}>
									<Input checked={is_OMC} onClick={(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)?null:onchange_OMC} style={{position:'unset', margin:'0px 10px 0px 0px'}} type='checkbox'></Input>
									<span >Override margins of constituents</span>
								</Col> */}
										</div>
										: ''
								}
							</React.Fragment>
					}
					{/* {
						<Row style = {{paddingTop:'5px', opacity: (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) || is_laoding||is_OMC || !is_owned ? '.5' : '1', pointerEvents:(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) || is_laoding||is_OMC || !is_owned ? 'none' : 'auto'}}>
							<Col xs={3} style={{display:'flex', alignItems:'center',justifyContent:'flex-start', paddingRight:0, minWidth:'200px'}}>
								<Input checked={is_not_priced} onClick={(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu)?null:onchange_not_priced} style={{position:'unset', margin:'0px 10px 0px 0px'}} type='checkbox'></Input>
								<span >Not Priced - use Custom Text</span>
							</Col>
							<Col xs={3} style={{display:'flex', alignItems:"center", margin:'0px 0px 0px -24px'}}>
								<Input style = {{width:'76%', height:'90%', alignItems:'centre'}} type = 'text' value = {!is_not_priced?'':not_priced_custom_text} placeholder = 'Enter Custom text' disabled = {!is_not_priced?true:false} onChange = {(e) => set_not_priced_custom_text(e.target.value)}/>
							</Col>
							<Col xs={3} style={{display:'flex', alignItems:"center", margin:'0px 10px 0px -68px', opacity:(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) || is_laoding||is_OMC?'.5':'1'}}>
								<Button onClick={() => (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu) || is_laoding||is_OMC ? null : onclick_update_not_priced()} style={{backgroundColor:"inherit", color:"#1070CA", border:'1px solid #1070CA', fontSize:"14px", fontWeight:600,height:"28px", display:"flex", alignItems:'center'}} >Update</Button>
							</Col>
						</Row>
					} */}
				</div>
				<div className='advanced_pricing_header_dropdown' onClick={toggle_advanced_pricing}>
					<div>Advanced Pricing Options <span style={{color:'#676878'}}>{"(Sales channel and other options)"}</span></div>
					<div className='flex_property'>
						<div style={{/*display: is_advanced_pricing_open ? 'none' : 'block'*/ color:'#C5C7CF'}} className='mr-3'>{sku_sales_channels && sku_sales_channels.length && sku_sales_channels.filter(x => x.id!='default_price').length > 1 ? `${sku_sales_channels.filter(x => x.id!='default_price').length} Sales Channels Mapped` : `${sku_sales_channels.filter(x => x.id!='default_price').length} Sales Channel Mapped`}</div>
						<i className={is_advanced_pricing_open ? 'fa fa-caret-up' : 'fa fa-caret-down'} style={{color: '#676878', fontSize:'12px'}} />
					</div>
				</div>
				<div className='advanced_pricing_content_container' style={{display:is_advanced_pricing_open ? 'block' : 'none'}}>
					{
						['cabinet_furniture', 'wardrobe_furniture'].includes(sku_category_type) && selected_sku_properties && selected_sku_properties.component_file  && !(comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')  ?
							<div style={{display:'flex', alignItems:'center',justifyContent:'flex-start', opacity:is_not_priced_backend || !is_owned ?'.5':'1', marginBottom:'10px'}}>
								<Input checked={(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.override_margin)} disabled={is_not_priced_backend || !is_owned}  onClick={onchange_OMC} style={{position:'unset', margin:'0px 10px 0px 0px'}} type='checkbox'></Input>
								<span >Override margin and tax of constituents</span>
							</div>
						:''
					}
					<div className='flex_property' style={{opacity: !is_owned ?'.5':'1', marginBottom:'10px'}}>
						<div style={{flex: 2, display:'flex', alignItems:'center',justifyContent:'flex-start', paddingRight:0, minWidth:'200px'}}>
							<Input checked={is_not_priced} disabled={ !is_owned} onClick={ !is_owned ?null:onchange_not_priced} style={{position:'unset', margin:'0px 10px 0px 0px'}} type='checkbox'></Input>
							<span >Not Priced - use Custom Text</span>
						</div>
						<div style={{flex: 6, display:'flex', alignItems:"center"}}>
							<Input style = {{marginRight: '15px', fontSize:'12px'}} type = 'text' value = {!is_not_priced?'':not_priced_custom_text} placeholder = 'Enter Custom text' disabled = {!is_not_priced?true:false} onChange = {(e) => set_not_priced_custom_text(e.target.value)}/>
						</div>
						<div style={{flex: 1, display:'flex', alignItems:"center"}}>
							<Button disabled={!is_owned} onClick={() => onclick_update_not_priced()} style={{}} className='update_pricing_button' >Update</Button>
						</div>
					</div>
					<hr style={{color:'#E7EAED', margin:'16px -20px 16px -20px'}}/>
					{
						// prices && ((['shutter_hardware'].includes(sku_category_type) && shutter_price_flag == 'design') || (selected_sku_properties && (!selected_sku_properties.component_file || selected_sku_properties.component_file && ['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type))))?
						<>
							<div style= {{textAlign : "left", marginBottom:'7px'}}>
								<span style = {{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold'}}>Existing Sales Channel Entries</span>
							</div>
							<div style={{border: '1px solid #D5DAE0', borderRadius: '4px', marginBottom:'20px', padding:"0px", boxShadow: '0px 0px 1px rgb(67 90 111 / 47%)',borderRadius: '5px'}}>
								<table style={{width:'100%'}}>
									<thead style={{width:'100%'}}>
									<tr className='tr_style' style={{}}>
										<th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6", paddingLeft:'20px'}}*/ style={{flex:4, padding:'0px 12px'}}>Sales Channels</th>
										<th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6", paddingLeft:'20px'}}*/ style={{flex:2, padding:'0px 12px'}}>Price Field</th>
										<th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:2, padding:'0px 12px'}}>Pricing Mode</th>
										{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) ? <th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:2, padding:'0px 12px'}}>Price</th> : ''}
										{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) ? <th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:2, padding:'0px 12px'}}>Unit</th> : ''}
										{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) || (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.override_margin) ? <th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:2, padding:'0px 12px'}}>Tax (in %)</th> : ''}
										{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) || (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.override_margin) ? <th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:2, padding:'0px 12px'}}>Margin (in %)</th> : ''}
										{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) ? <th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:2, padding:'0px 12px', backgroundColor:'#FFFAE6'}}>Selling Price</th> : ''}
										<th className='th_style flex_property' /*style={{border:0, borderRight:"1px solid #dee2e6"}}*/ style={{flex:1, padding:'0px 12px'}} title='Additional Properties'>Addn Prop</th>
										<th className='th_style flex_property' /*style={{ textAlign:'center',border:0, paddingRight:'20px'}}*/ style={{flex:1, padding:'0px 12px'}}>Actions</th>
									</tr>
									</thead>
									<tbody style={{width:'100%'}}>		
									{
										prices && sku_sales_channels && sku_sales_channels.length>0 ? sku_sales_channels.filter(x => x.id!='default_price').map((sc_item, index)=>(
											prices && prices['sales_channel_prices'] && prices['sales_channel_prices'][sc_item.id] && Object.keys(prices['sales_channel_prices'][sc_item.id]).length ? Object.keys(prices['sales_channel_prices'][sc_item.id]).map((price_type_id, idx) => (
											<tr id={'existing_sales_channel' + sc_item.name + idx} className={!show_price_type_edit_button && editing_price_field_json[sc_item.id+price_type_id] ? 'tr_style tr_focus' : 'tr_style display_onhover'} key={price_type_id} style={{cursor:'pointer'}}>
												<td className='td_style flex_property x1' style={{flex:4, padding: '0px 12px', borderRight: "1px solid #f2f4f6"}} /*style={{border:0,whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '180px',paddingLeft:'20px', display:'flex'}}*/ align="left">
													<div title={sc_item.name} style={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', marginTop:'4px' }}>{idx===0?sc_item.name:''}</div>
													{/* {(idx===0 && show_price_type_edit_button)?<i style={{cursor:"pointer", color:'#435A6F', marginLeft:'10px', alignItems:'center', color:'red'}} onClick = {() => onclick_delete_sales_channel(sc_item.id)} className='invisible1 fa fa-trash'/>:''} */}
												</td>
												<td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6"}} /*style={{border:0,whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '180px',paddingLeft:'20px'}}*/ align="left"><div className='mr-2'>{prices['sales_channel_prices'][sc_item.id][price_type_id].price_type.name}</div></td>

												{/* <td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6"}} align="center">
													<Input 
														// disabled={((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?true:false}
														style={{fontSize:'12px', border:'none', padding: '0px'}} required id="standard-required" 
														variant="outlined" 
														value = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.additiional_properties && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.additiional_properties.model_no ? prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.additiional_properties.model_no : ''}
														onChange = {(e) => update_single_sales('sales_channel_prices',sc_item.id, price_type_id,'additiional_properties',{model_no: e.target.value})}
													/>
												</td> */}
												
												<td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6", backgroundColor:editing_price_field_json[sc_item.id+price_type_id]?'initial':'var(--disabled_input_background)'}} /*style={{border:0}}*/ align="center">
													<Input
														style={{fontSize:'12px', border:'none', padding: '0px', cursor:"pointer"}}
														type='select'
														id="demo-simple-select"
														variant="outlined"
														disabled = {editing_price_field_json[sc_item.id+price_type_id]?false:true}
														value = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode?prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode:'0'}
														onChange = {(e) => update_single_sales('sales_channel_prices',sc_item.id, price_type_id,'pricing_mode',e.target.value)}
													>		
														<option value='default'>Default</option>
														<option value='custom'>Custom</option>
													</Input>
												</td>
												
												{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) ? <td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6", backgroundColor:!((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?'initial':'var(--disabled_input_background)'}} /*style={{border:0}}*/ align="center">
													<Input 
														disabled={((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?true:false}
														style={{fontSize:'12px', border:'none', padding: '0px'}} type="number" required id="standard-required" 
														variant="outlined" 
														value = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price ? prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.price:0}
														onChange = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default'?null:((e) => Number(e.target.value)>=0 ? update_single_sales('sales_channel_prices',sc_item.id, price_type_id,'price',e.target.value,prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.display_unit) : alert.error('Please enter a positive number'))}
													/>
												</td> : ''}

												{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) ? <td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6", backgroundColor:!((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?'initial':'var(--disabled_input_background)'}} /*style={{border:0}}*/ align="center">
													<Input 
														disabled={((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?true:false}
														style={{fontSize:'12px', border:'none', padding: '0px', cursor:"pointer"}}
														type='select' 
														id="demo-simple-select"
														variant="outlined"
														value = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.display_unit?prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.display_unit:'none'}
														onChange = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default'?null:(e) => update_single_sales('sales_channel_prices',sc_item.id, price_type_id,'display_unit',e.target.value)}
														
													>
														{/* <option value="none">None</option> */}
														{
															display_unit_options && display_unit_options.length?
																display_unit_options.map((unit) =>(
																	<option key={unit.id} value={unit.id}>{unit.text}</option>
																)) 
															:""

														}
													</Input>
												</td> : ''}

												{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) || (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.override_margin) ? <td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6", backgroundColor:!((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?'initial':'var(--disabled_input_background)'}} /*style={{border:0}}*/ align="center">
													<Input 
														disabled={((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?true:false}
														style={{fontSize:'12px', border:'none', padding: '0px'}} type="number" required id="standard-required" 
														variant="outlined" 
														value = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price ? prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.tax:0}
														onChange = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price &&  prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default'?null:((e) => Number(e.target.value)>=0 ? update_single_sales('sales_channel_prices',sc_item.id, price_type_id,'tax',e.target.value,prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.display_unit) : alert.error('Please enter a positive number'))}
													/>
												</td> : ''}
												
												{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) || (prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.override_margin) ? <td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6", backgroundColor:!((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?'initial':'var(--disabled_input_background)'}} /*style={{border:0}}*/ align="center">
													<Input 
														disabled={((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id]))?true:false}
														style={{fontSize:'12px', border:'none', padding: '0px'}} type="number" required id="standard-required" 
														variant="outlined" 	
														value = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price ?prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.margin:0}
														onChange = {prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default'?null:((e) => Number(e.target.value)>=0 ? (Number(e.target.value) >= 100 ? alert.error('Margin% = (1 - Cost Price/Selling Price) x 100. Margin% can not be 100 or more. Please enter a valid value.') : update_single_sales('sales_channel_prices',sc_item.id, price_type_id,'margin',e.target.value,prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.display_unit)) : alert.error('Please enter a positive number'))}
													/>
												</td> : ''}
												{!((['shutter_hardware'].includes(sku_category_type) && !(selected_sku_properties.component_file && comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type != 'obj_unit') && shutter_price_flag == 'constituents') || (['partition_configuration_furniture', 'drawer_fascia_configuration_furniture', 'cabinet_partition_configuration_furniture', 'cabinet_shutter_system_furniture', 'shutter_system_furniture', 'basket_collection_hardware', 'drawer_system_collection_hardware', 'drawer_inserts_collection_hardware'].includes(sku_category_type)) || (selected_sku_properties.component_file && (['cabinet_furniture', 'wardrobe_furniture', 'drawer_set_furniture'].includes(sku_category_type) || (comp_file_top_level_elem(selected_sku_properties) && comp_file_top_level_elem(selected_sku_properties).type == 'user_element')) && !(prices && prices['default_price'] && prices['default_price'].inherited_price && prices['default_price'].inherited_price.cbu))) ? <td className='td_style flex_property' style={{flex:2, padding: '0px 12px', borderRight: "1px solid #f2f4f6", backgroundColor:'#FFFAE6'}} /*style={{border:0}}*/ align="center">
													{prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price ? ((prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.price/(1-prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.margin/100))*(1+prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.tax/100)).toFixed(2) : ''}
												</td> : ''}
			
												<td className='td_style flex_property' style={{ flex: 1, padding: '0px 12px', borderRight: "1px solid #f2f4f6", justifyContent: 'center'}} /*style={{border:0}}*/ align="center">
													<i style={{color: '#0078ff'}} className={((prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.pricing_mode==='default')||(!editing_price_field_json[sc_item.id+price_type_id])) ? 'fa fa-eye' : 'fa fa-edit'} onClick={() => { set_active_pt_additional_properties({ sc_item_id: sc_item.id, price_type_id: price_type_id, additional_properties: prices['sales_channel_prices'][sc_item.id][price_type_id] && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price && prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.additional_properties ? prices['sales_channel_prices'][sc_item.id][price_type_id].frontened_price.additional_properties : [] }); toggle_pt_additional_properties_modal() }}></i>
												</td>

												<td className='td_style flex_property x1' style={{flex:1, padding: '0px 12px', borderRight: "1px solid #f2f4f6"}} /*style={{display:"flex", alignItems:'center', justifyContent:"center",border:0, paddingRight:'20px'}}*/>
													{
														show_price_type_edit_button?
														<>
															<img src='/assets/icons/pencil.svg' className='mr-2' onClick={() => {update_editing_price_field_json(sc_item.id, price_type_id, true)}} />
															{(idx===0 && show_price_type_edit_button)?<img src='/assets/icons/remove.svg' onClick = {() => onclick_delete_sales_channel(sc_item.id)}/>:''}
														</>
														// <i className='invisible1 fa fa-edit' onClick={() => {update_editing_price_field_json(sc_item.id, price_type_id, true)}} style={{backgroundColor:"inherit", color:"#1070CA", fontSize:"14px", fontWeight:600}}/>
														:(
															editing_price_field_json[sc_item.id+price_type_id]?
															<>
																<i className='fas fa-check mr-3 cp' onClick={() => onclick_update_price_for_sc(sc_item.id,price_type_id)} style={{color:'#23C16B'}}/>
																<i className='fa fa-times cp' style={{color:'#FF5247'}} onClick={() => {update_editing_price_field_json(sc_item.id, price_type_id, false);set_prices(JSON.parse(JSON.stringify(original_prices)))}}/>
															</>
															:''
														)
													}
												</td>
											
											</tr>
													))
													:''
												))
												:''
											}
									</tbody>
								</table>
								{/* {
									add_button_flag === true?
									<div>
										<Row container spacing={3}>
											<Col item xs={6}>
												
													<Input 
														style={{fontSize:'12px', cursor:"pointer"}}
														type='select'
														labelId="demo-customized-select-label"
														id="demo-customized-select"
														variant="outlined"
														value={add_new_sales_channel_selected}	
														onChange={(e) => set_add_new_sales_channel_selected(e.target.value)}
													>
													{	
														display_all_sales_channels && display_all_sales_channels.map((all_sales, idx) => ( 
															<option key={idx} value= {all_sales.id} >{all_sales.name}</option>
													))}
													</Input>
											
											</Col>
											<Col item xs={6} style = {{textAlign:"right"}}>
												<Button className='blue_button' style= {{marginRight:"10px"}} onClick = {onclick_add_sales_channel}>Add to Sales Channels</Button>
												<Button className='white_button' onClick = {onclick_back} >Back</Button>
											</Col>
										</Row>
									</div> 
									: */}
										<div style = {{marginBottom:' 10px', display:"flex", justifyContent:'flex-start', alignItems:'center', borderBottom:'1px solid #f2f4f6'}}>
											<Input 
												className='edit_properties_dropdown'
												type='select'
												id="sales_channel_select"
												style={{width:'200px', marginRight:'15px', border:'none', borderRight: '1px solid #f2f4f5'}}
												value={add_new_sales_channel_selected}	
												onChange={(e) => {set_add_new_sales_channel_selected(e.target.value); if(e.target.value !== 'none')onclick_add_sales_channel(e.target.value)}}
											>
												<option value= {'none'} >Select a Channel to add</option>
												{	
													display_all_sales_channels && display_all_sales_channels.map((all_sales, idx) => ( 
														<option key={idx} value= {all_sales.id} >{all_sales.name}</option>
												))}
											</Input>
											{/* <span id='add_sales_channel_button' onClick={onclick_add_sales_channel} style={{cursor:"pointer",backgroundColor:'#1070CA', borderRadius:'50%', width:'20px', height:'20px', display:"flex", alignItems:"center", justifyContent:'center', opacity:add_new_sales_channel_selected?'unset':.5}}><i style={{fontSize:"10px", color:"white"}} className='fa fa-check'/></span> */}
										</div>
									{/* <div style= {{color:"#1070CA", cursor:'pointer', marginBottom:"20px", paddingLeft:"15px", fontWeight:600}} onClick = {onclick_open_add_buton_flag} >Select another Sales Channel</div> */}
							</div>
						</>
						// :""
					}
				</div>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='white_button' onClick = {onclick_handleclose} >Close</Button>
			</CardFooter>	
		</React.Fragment>
    );
}

export default ManageSalesSku ; 