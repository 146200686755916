async function cloneFiles(originalFiles) {
    const clonedFiles = await Promise.all(originalFiles.map(file => {
        console.log("kpp the file is (in clonefiles)", file)
        return new File([file], file.name, {
            type: file.type,
            lastModified: file.lastModified,
        });
    }));
    return clonedFiles;
}

export const areFilesSame = (file1, file2) => {
    return (file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified)
}

export default cloneFiles;