import React, { useContext, useEffect, useState } from "react";
import './style.css';
import { fetch_material_templates } from "../../utils/api/material";
import { handleError, logToSentry } from "../../utils/error";
import SingleSelect from "../SingleSelect";
import build_path from "../../utils/path";
import { COLORS, hexToRgb, rgbToHex } from "../../utils/colors";
import { useAlert } from "react-alert";
import { Input } from "reactstrap";
import { UncontrolledCollapse } from "../ICollpsable";
import UploadImage from "../UploadFile/UploadImage";
import { addTexture, getTexture } from "../../utils/api/texture";
import TriggerTooltip from "../TriggerTooltip";
import { WasmLoadedContext } from "../Contexts/WasmLoadedContext";
import Icon from "../Icon";
import { get_image_dimesions } from "../../utils/get_image_dimensions";

const grouped_properties = {
    // "Base" : [
    // 	"map",
    // 	"texture_wrapping",
    // 	"texture_width",
    // 	"texture_height",
    // 	"color"
    // ],
    // "Other maps" : [
    // 	"displacement_map",
    // 	"normal_map"
    // ],
    "Reflection": [
        "specular",
        "roughness",
        "metalness"
    ],
    "Refraction": [
        "transmission",
        "refractive_index",
    ],
    // "Opacity" : [
    // 	"opacity",
    // ],
    "Emmissivity": [
        "emissive_intensity",
    ]
}

const properties_order = {
    // "map": 0,
    // "texture_wrapping" : 1,
    // "texture_width" : 2,
    // "texture_height": 3,

    // "bump_map": 0,
    // "normal_map": 1,

    "specular": 0,
    "roughness": 1,
    "metalness": 2,

    "transmission": 0,
    "refractive_index": 1,

    "emissive_intensity": 0,

}

const material_properties_meta_data = {
    "specular": {
        min: 0,
        max: 1,
        type: 'number',
        support_component: 'slider',
        name: "Specular",
    },
    "roughness": {
        min: 0,
        max: 1,
        type: 'number',
        support_component: 'slider',
        name: "Roughness",
    },
    "metalness": {
        min: 0,
        max: 1,
        type: 'number',
        support_component: 'slider',
        name: "Metalness",
    },

    "transmission": {
        min: 0,
        max: 1,
        type: 'number',
        support_component: 'slider',
        name: "Transmission",
    },
    "refractive_index": {
        min: 0,
        max: 100,
        type: 'number',
        support_component: 'select',
        support_component_options: [
            {name: 'Custom', value: ''},
            {name: 'Crystal', value: '2'},
            {name: 'Glass', value: '1.5'},
            {name: 'Ice', value: '1.309'},
            {name: 'Water', value: '1.325'}
        ],
        name: "Refraction Index (IOR)",
        showPlaceholder: (parsed_properties) => parsed_properties["transmission"] == 0,
        getPlaceholder: (parsed_properties) => <div style={{color: COLORS.gray5}}>{"To enable,  transmission must be > 0."}</div>,
        tooltip:
            <div>
                <div className='mb-2'><b>IOR: Index of Refraction</b></div>
                <div style={{color: "#A7A8B2"}}>
                    Defines how much internal reflection the transparent material has.<br />
                    EG: diamond has more IOR, than glass.
                </div>
            </div>
    },

    "emissive_intensity": {
        min: 0,
        max: 100,
        type: 'number',
        support_component: 'slider',
        name: "Emissive Intensity",
    },
}

const keysGroupedObject = Object.keys(grouped_properties)

const MaterialTemplateMapUploadWithSlider = ({ map, set_map, map_name, set_map_name, map_url, set_map_url, property, property_slider, change_property, parsed_properties, customId }) => {
    const alert = useAlert()
    
    const onClickUpload = () => {
        let uploadButton = document.getElementById('map_upload' + (customId || ''))
        if (uploadButton) {
            uploadButton.click()
        } else {
            logToSentry(`upload button not found, trying to find element with id - ${'map_upload' + (customId || '')}`)
        }
    }

    const updateMap = async (map) => {
        if (map) {
            set_map(map)
            set_map_name(map.name)
            // set_map_name(map.name)
            try {
                // set_page_loader({
                // 	show:true,
                // 	text:'Please wait, uploading texture...'
                // })
                let resp_texture = await addTexture(map);
                if (resp_texture && resp_texture.id) {
                    if (resp_texture.Image?.image) {
                        set_map_url(build_path(global.config.static_path, resp_texture.Image.image))
                        // console.log(resp_texture.Image.image, "   ---- texture url")
                    }
                    change_property(property, resp_texture.id)
                }
            } catch (err) {
                handleError(err)
            }
            // set_page_loader({
            // 	show:false,
            // 	text:''
            // })
        } else {
            set_map(null)
            set_map_name('')
            // set_map_name("")
            set_map_url("")
            change_property(property, null)
        }
    }

    return (
        <div className="flex_property">
            <UploadImage isVisible={false} selectedImage={map} setSelectedImage={updateMap} inputId={'map_upload' + (customId || '')} />
            <img height={'80px'} width={'92px'} className="mr-2" src={map_url ? map_url : '/assets/img/icons/no_image.svg'}></img>
            <div style={{ flex: 1, minWidth: '0px' }}>
                {
                    !map_url ?
                        <div className="flex_between">
                            <div style={{fontWeight: 500, color: '#676878'}}>Require (.jpg / .jpeg / .png)</div>
                            <img src='/assets/icons/add.svg' style={{ cursor: 'pointer' }} onClick={onClickUpload} />
                        </div>
                        :
                        <div className="flex_property">
                            <div>{map_name}</div>
                            <div className="flex_property" style={{ marginLeft: 'auto' }}>
                                <img src='/assets/icons/change.svg' className='mr-2' style={{ cursor: 'pointer' }} onClick={onClickUpload} />
                                <img src='/assets/icons/remove.svg' className='mr-2' style={{ cursor: 'pointer' }} onClick={() => { updateMap(null) }} />
                            </div>
                        </div>
                }
                <div className="lines1_elipsis mt-1">Intensity</div>
                <div className="flex_property" style={{gap: '8px'}}>
                    <div style={{flex: 1, minWidth: '0px'}}>
                        <MaterialTemplateInputSlider value={parsed_properties[property_slider]} onChange={(value) => change_property(property_slider, value)} onMouseUp={(value) => change_property(property_slider, value)} minValue={0} maxValue={100} />
                    </div>
                    <Input
                        // disabled={disabled_input ? true : false}
                        // min={type == 'number' ? property.value.min_value : null}
                        // max={type == 'number' ? property.value.max_value : null}
                        step={/* (type == 'number' && property.value.max_value == 1) ? '0.1' :  */'1'}
                        type={'number'}
                        value={parsed_properties[property_slider]}
                        className="inf_material_input_common_styles"
                        style={{ width: '80px' }}
                        onChange={e => { (Number(e.target.value) <= 100 && Number(e.target.value) >= 0 ? change_property(property_slider, e.target.value) : alert.error("Please enter a number in the range")) }}
                    />
                </div>
            </div>
        </div>
    )
}

const MaterialTemplateMapUpload = ({ map, set_map, map_name, set_map_name, map_url, set_map_url, change_property, customId }) => {

    const onClickUpload = () => {
        let uploadButton = document.getElementById('map_upload' + (customId || ''))
        if (uploadButton) {
            uploadButton.click()
        } else {
            logToSentry(`upload button not found, trying to find element with id - ${'map_upload' + (customId || '')}`)
        }
    }

    const updateMap = async (map) => {
        if (map) {
            set_map(map)
            set_map_name(map.name)
            // set_map_name(map.name)
            try {
                // set_page_loader({
                // 	show:true,
                // 	text:'Please wait, uploading texture...'
                // })
                let resp_texture = await addTexture(map);
                if (resp_texture && resp_texture.id) {
                    if (resp_texture.Image?.image) {
                        set_map_url(build_path(global.config.static_path ,resp_texture.Image.image))
                        // console.log(resp_texture.Image.image, "   ---- texture url")
                    }
                    change_property(resp_texture.id)
                }
            } catch (err) {
                handleError(err)
            }
            // set_page_loader({
            // 	show:false,
            // 	text:''
            // })
        } else {
            set_map(null)
            set_map_name('')
            // set_map_name("")
            set_map_url("")
            change_property(null)
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <UploadImage isVisible={false} selectedImage={map} setSelectedImage={updateMap} inputId={'map_upload' + (customId || '')} />
            <div className='flex_property'>
                {
                    !map_url ?
                        <div onClick={onClickUpload}>
                            <img src='/assets/icons/upload_icon.svg' style={{ cursor: 'pointer' }} />
                        </div>
                        :
                        <div className="flex_property" style={{ gap: '8px' }}>
                            <img width={'32px'} height={'32px'} src={ map_url ? map_url : '/assets/img/icons/no_image.svg'} />
                            <div>{map_name}</div>
                        </div>
                }
            </div>
            {
                map_url ?
                    <div className="flex_property" style={{ marginLeft: 'auto' }}>
                        <img src='/assets/icons/change.svg' className='mr-2' style={{ cursor: 'pointer' }} onClick={onClickUpload} />
                        <img src='/assets/icons/remove.svg' className='mr-2' style={{ cursor: 'pointer' }} onClick={() => { updateMap(null) }} />
                    </div>
                    : ''
            }
        </div>
    )
}

const MaterialTemplateInputSlider = ({ value, onChange, onMouseUp, minValue = 0, maxValue = 100 }) => {
    return (
        <>
            <div className='flex_property pt-3'>
                <div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
                <input
                    type="range"
                    min={/* property.value.min_value ? property.value.min_value : */ minValue}
                    max={/* property.value.max_value ? property.value.max_value : */ maxValue}
                    step={/* (property && property.value && property.value.max_value && property.value.max_value < 2) ? '0.1' :  */ maxValue < 2 ? 0.1 : 1}
                    value={/* parsed_properties[property] */ value}
                    // onChange={handleChange}
                    onChange={(e) => onChange(Number(e.target.value))}
                    onMouseUp={(e) => onMouseUp(Number(e.target.value))}
                    className='slider'
                />
                <div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
            </div>
            <div style={{ fontSize: '12px', display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '100%', marginTop: '4px', color: '#676878' }}>
                <span>{/* property.value.min_value ? property.value.min_value : */ minValue}</span>
                <span>{/* property.value.max_value ? property.value.max_value : */ maxValue}</span>
            </div>
        </>
    )
}

const MaterialTemplateInputSelect = ({value, onChange, select_options}) => {
    return(
        <>
            <Input type='select' className="inf_material_input_common_styles" value={value != null ? Math.round(value * 1000) / 1000 : ''} onChange={(e) => onChange(Number(e.target.value))}>
                {
                    select_options && select_options.length ? select_options.map((option, idx) => (
                        <option value={option.value} key={idx}>{option.name}</option>
                    ))
                        : ''
                }
            </Input>
        </>
    )
}

const MaterialTemplateInput = ({ property, parsed_properties, change_property }) => {

    const alert = useAlert()

    const safelyAccessPropertyValue = (propertyJson, propertyKey, returnKeyWhenEmpty = true) => {
        try {
            if (propertyJson && propertyKey) {
                if (propertyJson[propertyKey] !== undefined && propertyJson[propertyKey] !== null) {
                    return propertyJson[propertyKey]
                }
            }

            if (returnKeyWhenEmpty) {
                return propertyKey
            }
            return ""
        } catch (err) {
            console.error(`Error in safelyAccessPropertyValue `, err)
            handleError(err);
        }
    }

    return (
        <div className="inf_material_property_row" style={{ gap: '16px' }}>
            <div className="flex_property" style={{ width: '100px' }}>
                <div style={{ flex: 1, minWidth: '0px' }} className="lines1_elipsis" title={safelyAccessPropertyValue(material_properties_meta_data[property], "name")}>{safelyAccessPropertyValue(material_properties_meta_data[property], "name")}</div>
                { safelyAccessPropertyValue(material_properties_meta_data[property], "tooltip", false) ? <TriggerTooltip title={safelyAccessPropertyValue(material_properties_meta_data[property], "tooltip", false)} /> : '' }
            </div>
            {
                safelyAccessPropertyValue(material_properties_meta_data[property], "showPlaceholder", false) && safelyAccessPropertyValue(material_properties_meta_data[property], "showPlaceholder", false)(parsed_properties ? parsed_properties : {}) ?
                    safelyAccessPropertyValue(material_properties_meta_data[property], "getPlaceholder", false) && safelyAccessPropertyValue(material_properties_meta_data[property], "getPlaceholder", false)(parsed_properties ? parsed_properties : {})
                :
                    <>
                        <div style={{flex: 1, minWidth: '0px'}}>
                            {
                                safelyAccessPropertyValue(material_properties_meta_data[property], "support_component") === "slider" ?
                                <MaterialTemplateInputSlider value={parsed_properties[property]} onChange={change_property} onMouseUp={change_property} minValue={safelyAccessPropertyValue(material_properties_meta_data[property], 'min')} maxValue={safelyAccessPropertyValue(material_properties_meta_data[property], 'max')} />
                                : safelyAccessPropertyValue(material_properties_meta_data[property], "support_component") === "select" ?
                                <MaterialTemplateInputSelect value={parsed_properties[property]} onChange={change_property} select_options={safelyAccessPropertyValue(material_properties_meta_data[property], "support_component_options")} />
                                : ''
                            }
                        </div>
                        <Input
                            // disabled={disabled_input ? true : false}
                            // min={type == 'number' ? property.value.min_value : null}
                            // max={type == 'number' ? property.value.max_value : null}
                            step={/* (type == 'number' && property.value.max_value == 1) ? '0.1' :  */'1'}
                            type={'number'}
                            value={parsed_properties[property]}
                            className="inf_material_input_common_styles"
                            style={{ width: '100px' }}
                            onChange={e => { (Number(e.target.value) <= safelyAccessPropertyValue(material_properties_meta_data[property], 'max') && Number(e.target.value) >= safelyAccessPropertyValue(material_properties_meta_data[property], "min") ? change_property(e.target.value) : alert.error("Please enter a number in the range")) }}
                            />
                     </>
            }
        </div>
    )
}

const MaterialTemplateTexturePropertiesNumberInput = ({ property, display_name, parsed_properties, change_property }) => {
    return (
        <div className="flex_property inf_material_input_common_styles" style={{ gap: '8px', border: '1px solid #ced4da' }}>
            <div>{display_name}</div>
            <Input type="number" style={{ border: 'none', height: '100%', padding: '0px', fontSize: '12px' }} value={parsed_properties[property]} onChange={e => { change_property(Number(e.target.value)); }} />
        </div>
    )
}

const MaterialTemplateTextureProperties = ({ parsed_properties, change_property }) => {
    return (
        <div className="flex_property" style={{ gap: '16px' }}>
            <div className="flex_property" style={{ flex: 2, minWidth: '0px' }}>
                <div className='mr-2' style={{ minWidth: '52px' }}>Wrapping</div>
                <Input className="inf_material_input_common_styles" type="select" value={parsed_properties["texture_wrapping"]} onChange={e => { change_property("texture_wrapping", e.target.value) }}>
                    <option value="repeat">Repeat</option>
                    <option value="mirrored_repeat">Mirrored Repeat</option>
                </Input>
            </div>
            <div className="flex_property" style={{ flex: 3, minWidth: '0px', gap: '16px' }}>
                <MaterialTemplateTexturePropertiesNumberInput property={"texture_width"} display_name={"W"} parsed_properties={parsed_properties} change_property={(value) => change_property("texture_width", value)} />
                <MaterialTemplateTexturePropertiesNumberInput property={"texture_height"} display_name={"H"} parsed_properties={parsed_properties} change_property={(value) => change_property("texture_height", value)} />
            </div>
        </div>
    )
}

const AddAndUpdateMaterialProperties = ({ customId, initial_material_details, callbackOnMaterialPropertiesUpdate, callbackOnBasePropertyUpdate, callbackOnMaterialTemplateUpdate, callbackOnMaterialPreviewUpdate }) => {

    const alert = useAlert()
    // const [wasm_loaded, set_wasm_loaded] = useState(false);
    const {wasm_loaded, set_wasm_loaded} = useContext(WasmLoadedContext)
    const [material_preview_src, set_material_preview_src] = useState();
    const [ mode, set_mode ] = useState("image");

    const [material_templates, set_material_templates] = useState([]);
    const [selected_material_template, set_selected_material_template] = useState(null);

    const [is_color, set_is_color] = useState(true);

    const [base_map, set_base_map] = useState('');
    const [ base_map_properties, set_base_map_properties ] = useState({url: '', name: ''});
    // const [base_map_url, set_base_map_url] = useState('');
    // const [ base_map_name, set_base_map_name ] = useState('');

    const [ bump_map, set_bump_map ] = useState('');
    const [ bump_map_properties, set_bump_map_properties ] = useState({url: '', name: ''});
    // const [ bump_map_url, set_bump_map_url ] = useState('');
    // const [ bump_map_name, set_bump_map_name ] = useState('');

    const [ normal_map, set_normal_map ] = useState('');
    const [ normal_map_properties, set_normal_map_properties ] = useState({url: '', name: ''});
    // const [ normal_map_url, set_normal_map_url ] = useState('');
    // const [ normal_map_name, set_normal_map_name ] = useState('');

    const [parsed_properties, set_parsed_properties] = useState({
        "texture_wrapping": 'repeat',
        "texture_width": 1000,
        "texture_height": 1000,
        "color": "#000000",
        "opacity": 1,
        "specular": 0,
        "roughness": 0,
        "metalness": 0,
        "refractive_index": 0,
        "clearcoat": 0,
        "transmission": 0,
        "bump_scale": 0,
        "normal_map_strength": 0,
        "map": null,
        "ao_map": null,
        "bump_map": null,
        "normal_map": null,
        "metalness_map": null,
        "roughness_map": null,
        "emissive_intensity": 0
    });

    // const [ texture_initialized, set_texture_initialized ] = useState({count: 0});

    const [re_render, set_re_render] = useState(false);
    const forceReRender = () => set_re_render(!re_render)

    const getAllMaterialTemplates = async () => {
        try {
            let _material_templates = await fetch_material_templates()
            set_material_templates(_material_templates)
            return _material_templates
        } catch (err) {
            console.error(`Error in getAllMaterialTemplates `, err)
            handleError(err);
        }
    }

    const setMapProperties = async(map_id, map_type = "base") => {
        try{
            let map_properties = await getTexture(map_id)
            if(map_properties && map_properties.id){
                if(map_type == "base"){
                    base_map_properties.url = (map_properties?.Image?.image ? build_path(global.config.static_path, map_properties.Image.image) : '')
                    base_map_properties.name = (map_properties.name)
                    set_base_map_properties(JSON.parse(JSON.stringify(base_map_properties)))
                }
                if(map_type == "bump"){
                    bump_map_properties.url = (map_properties?.Image?.image ? build_path(global.config.static_path, map_properties.Image.image) : '')
                    bump_map_properties.name = (map_properties.name)
                    set_bump_map_properties(JSON.parse(JSON.stringify(bump_map_properties)))
                }
                if(map_type == "normal"){
                    normal_map_properties.url = (map_properties?.Image?.image ? build_path(global.config.static_path, map_properties.Image.image) : '')
                    normal_map_properties.name = (map_properties.name)
                    set_normal_map_properties(JSON.parse(JSON.stringify(normal_map_properties)))
                }
            }else{
                logToSentry(`Invalid map properties for map_id - ${map_id}`)
            }
        }catch(err){
            console.error(`Error in setMapProperties `, err)
            handleError(err);
        }
    }

    const onChangeMaterialTemplate = (material_template) => {
        try {
            if (material_template && material_template.id) {
                let template_properties = Object.keys(material_properties_meta_data)

                if (material_template.properties && Array.isArray(material_template.properties)) {
                    for (let i = 0; i < material_template.properties.length; i++) {
                        const property = material_template.properties[i];
                        if (material_properties_meta_data[property.label]) {
                            parsed_properties[property.label] = property?.value?.value
                        }

                    }
                    set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
                    set_selected_material_template({ id: material_template.id })
                    updateMaterialPreview()
                } else {
                    logToSentry(`Invalid Properties for selected template with id - , ${(material_template.id)}`)
                }

            } else {
                logToSentry(`Invalid material template - , ${JSON.stringify(material_template)}`)
            }
        } catch (err) {
            console.error(`Error in onChangeMaterialTemplate `, err)
            handleError(err);
        }
    }

    const change_property = async(property, value) => {
        try {
            if (parsed_properties && set_parsed_properties) {
                parsed_properties[property] = value
                set_parsed_properties(JSON.parse(JSON.stringify(parsed_properties)))
                updateMaterialPreview(["map", "bump_map", "normal_map"].includes(property) ? false : true)
                
                if(property === "map" && value){
                    // if(!texture_initialized.count){
                        let {image_width, image_height} = await get_image_dimesions(base_map_properties.url);
                        // console.log("image dimensions - ", image_dimesions)
                        let aspect_ratio = (image_width/image_height).toFixed(3)
                        let curr_aspect_ratio = (parsed_properties.texture_width/parsed_properties.texture_height).toFixed(3)
                        if(Math.abs(curr_aspect_ratio - aspect_ratio) > 0.0001){
                            change_property("texture_height", 1000)
                            change_property("texture_width", 1000*aspect_ratio)
                            setTimeout(() => {alert.info("Width and Height updated as per image. Please check!")}, 0)
                        }
                    // }
                    // texture_initialized.count++
                }
            }
        } catch (err) {
            console.error(`Error in change_property `, err)
            handleError(err);
        }
    }

    const getHeader = (property) => {
        try {
            let properties_list = grouped_properties[property];
            let preview = ''
            let defaultState = false
            let initialized = false
            properties_list.map(x => {
                preview = preview + `${x.replace('_', ' ')}: ${parsed_properties[x] !== undefined ? typeof (parsed_properties[x]) == 'number' ? Math.round(parsed_properties[x] * 100) / 100 : parsed_properties[x] : '-'} `
                if (parsed_properties[x]) {
                    defaultState = true
                }
            })
            preview = `(${preview})`

            let header = <div className='flex_property'>{property} &nbsp; <div style={{ textTransform: 'capitalize', color: COLORS.gray5 }}>{preview}</div></div>

            return header
        } catch (err) {
            console.error(`Error in getHeader `, err)
            handleError(err);
        }
    }

    const getCurrentColor = () => {
        try{
            if(parsed_properties.color && typeof(parsed_properties.color === 'string')){
                return parsed_properties.color
            }else if(parsed_properties.color && typeof(parsed_properties.color === 'object')){
                rgbToHex(parsed_properties.color)
            }
        }catch(err){
            console.error(`Error in getCurrentColor `, err)
            handleError(err);
        }
    }

    const updateMaterialPreview = async(is_redraw = true, _mode = mode) => {
        try{
            if(_mode === "image" || wasm_loaded == -1){
                return
            }
            if (window.enable_wasm && wasm_loaded !== 1) {
                await window.wasm_loaded_promise
                // window.wasm_loaded_promise.then(() => {
                    // if (!initialized) {
                    //     authenticate();
                    // }
                    // setTimeout(async() => {
                        try {
                            if (window.Module.create_material_preview_context("webgl_sphere_canvas")) {
                                window.Module.init_material_preview_texture(window.location.origin);
                                set_wasm_loaded(1)
                            }
                        } catch (err) {
                            console.error("Context could not be created")
                            set_wasm_loaded(-1)
                        }
                    // }, 0)
                    // window.Module.create_webgl_context_sphere()
                // })
            }
            let color = {r:255,g:255,b:255};
            let metalness = 0;
            let roughness = 0;
            let specular = 0;
            let normal_map_strength = 0;
            let bump_map_strength = 0;
            let _is_cube = _mode === "cube";
            let normal_map_url = normal_map_properties.url;
            let bump_map_url = bump_map_properties.url; 
            let base_map_url = base_map_properties.url;

            if(parsed_properties){
                if(parsed_properties["color"]){
                    color = hexToRgb(parsed_properties["color"]);
                }
                if(parsed_properties["metalness"]){
                    metalness = parsed_properties["metalness"];
                }
                if(parsed_properties["roughness"]){
                    roughness = parsed_properties["roughness"];
                }
                if(parsed_properties["specular"]){
                    specular = parsed_properties["specular"];
                }
                if(parsed_properties["normal_map_strength"]){
                    normal_map_strength = parsed_properties["normal_map_strength"];
                }
                if(parsed_properties["bump_scale"]){
                    bump_map_strength = parsed_properties["bump_scale"];
                }
            }
            // console.log("material properties", base_map_url ? base_map_url : '-', normal_map_url ? normal_map_url : '-', bump_map_url ? bump_map_url : '-', metalness, roughness, specular, color.r/255, color.g/255, color.b/255, normal_map_strength, bump_map_strength, _is_cube);
            if(is_redraw){
                try{
                    window.Module.draw_material_preview(metalness, roughness, specular, color.r/255, color.g/255, color.b/255, normal_map_strength, bump_map_strength, _is_cube);
                }catch(err){
                    console.error("cant update preview", err)
                }
            }else{
                try{
                    // window.Module.init_material_preview_texture(window.Module.API.react_app_path, image_src, color.r/255, color.g/255, color.b/255, metalness, roughness, specular);
                    window.Module.prepare_texture(!is_color && base_map_url ? base_map_url : '', normal_map_url ? normal_map_url : '', bump_map_url ? bump_map_url : '');
                    window.Module.draw_material_preview(metalness, roughness, specular, color.r/255, color.g/255, color.b/255, normal_map_strength, bump_map_strength, _is_cube);
                    window.Module.draw_material_preview(metalness, roughness, specular, color.r/255, color.g/255, color.b/255, normal_map_strength, bump_map_strength, _is_cube);
                }catch(err){
                    console.error("cant init preview", err)
                }
            }

            if (document.getElementById('webgl_sphere_canvas')) {
				set_material_preview_src(document.getElementById('webgl_sphere_canvas').toDataURL())
			}

        }catch(err){
            console.error(`Error in updateMaterialPreview `, err)
            handleError(err);
        }
    }

    useEffect(() => {
        if(callbackOnMaterialPropertiesUpdate){
            callbackOnMaterialPropertiesUpdate(parsed_properties)
        }
    }, [parsed_properties]);

    useEffect(() => {
        if(callbackOnBasePropertyUpdate){
            callbackOnBasePropertyUpdate(is_color ? "color" : "texture")
        }
        updateMaterialPreview(false)
    }, [is_color]);

    useEffect(() => {
        if(callbackOnMaterialTemplateUpdate){
            if(selected_material_template && selected_material_template.id){
                callbackOnMaterialTemplateUpdate(selected_material_template.id)
            }
        }
    }, [selected_material_template]);

    useEffect(() => {
        (async() => {
            let all_material_templates = await getAllMaterialTemplates()

            // Risky code below - No checks placed
            if(initial_material_details){
                initial_material_details = JSON.parse(JSON.stringify(initial_material_details))
                let material_properties = (initial_material_details.properties)
                set_parsed_properties(material_properties)
                if(material_properties.map){
                    // texture_initialized.count++;
                    set_is_color(false)
                    await setMapProperties(material_properties.map, "base")
                }

                if(material_properties.bump_map){
                    await setMapProperties(material_properties.bump_map, "bump")
                }

                if(material_properties.normal_map){
                    await setMapProperties(material_properties.normal_map, "normal")
                }

                set_selected_material_template(all_material_templates.find(x => x.id === initial_material_details.material_template_id))
            }else{
                onChangeMaterialTemplate(all_material_templates[0])
            }
            updateMaterialPreview(false)
        })()
    }, []);


    return (
        <div className="inf_material_properties_parent_container">
            <div className="inf_material_properties_individual_container">
                {/* Material Preview */}
                <div className="flex_between">
                    <div>Material Preview</div>
                    <div>
                        {/* Cube/Sphere Selection */}
                    </div>
                </div>
                <div className="flex_center" style={{background: '#282828', flexDirection: 'column', position: 'relative', padding: '24px 30px'}}>
                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', gap: '8px', position: 'absolute', right: '4px', top: '24px', padding: '4px', fontSize: '20px' }}>
                        <Icon name={'image_placeholder'} style={{ cursor: 'pointer', color: mode === "image" ? '#C5C7CF' : '#676878' }} onClick={() => { set_mode("image") }} />
                        <Icon name={'material_cube'} style={{ cursor: 'pointer', color: mode === "cube" ? '#C5C7CF' : '#676878' }} onClick={() => { set_mode("cube"); updateMaterialPreview(false, "cube") }} />
                        <Icon name={'material_sphere'} style={{ cursor: 'pointer', color: mode === "sphere" ? '#C5C7CF' : '#676878' }} onClick={() => { set_mode("sphere"); updateMaterialPreview(false, "sphere") }} />
                    </div>
                    {
                        !(window.enable_wasm && (wasm_loaded === 1)) || mode == "image" ?
                            !is_color ?
                                base_map_properties.url
                                ?
                                <img style={{ height: '250px', width: '250px' }} alt='Texture Image' src={base_map_properties.url} />
                                :
                                <div style={{ height: '250px', width: '250px' }} />
                                :
                                <div style={{ width: '250px', height: '250px', backgroundColor: getCurrentColor() }} />
                            :
                            <>
                                <img style={{ height: '250px', width: '250px' }} src={material_preview_src} />
                            </>
                    }
                    <div className="mt-2" style={{ fontSize: '12px', color: COLORS.gray6, textAlign: 'center', bottom: '0px' }}>{mode == "image" ? "(Preview based on Base Texture and Base Color)" :"(Preview based on Base Texture, Base Color, Metalness, Roughness and specular)"}</div>
                </div>

            </div>
            <div className="inf_material_properties_individual_container">
                {/* Texture Related Info */}
                <UncontrolledCollapse header={"Base (Color/Texture)"} className='mb-2' defaultState={true}>
                    <div className='flex_property' style={{ gap: '16px' }}>
                        <div className="flex_property" style={{ flex: 2, minWidth: '0px' }}>
                            <div className='mr-2' style={{ minWidth: '52px' }}>From</div>
                            <Input className="inf_material_input_common_styles" type='select' value={is_color ? 'color' : 'texture'} onChange={(e) => { set_is_color(e.target.value === 'color') }} style={{ width: 'calc( 100% - ', fontSize: '12px' }}>
                                <option value={'color'}>Color</option>
                                <option value={'texture'}>Texture</option>
                            </Input>
                        </div>
                        <div className="flex_property" style={{ flex: 3, minWidth: '0px' }}>
                            {
                                is_color ?
                                    <Input
                                        className="inf_material_input_common_styles"
                                        // disabled={disabled_input ? true : false}
                                        type={'color'} /* value={input_value != null ? (type === 'color' && typeof (input_value) === "object") ? rgbToHex(input_value) : input_value : ''} */
                                        value={getCurrentColor()}
                                        style={{ width: '80px' }}
                                        onChange={e => ( /* validate_string */ change_property("color", e.target.value))}
                                    />
                                    :
                                    <MaterialTemplateMapUpload map={base_map} set_map={set_base_map} map_name={base_map_properties.name} set_map_name={(name) => {base_map_properties.name = name; set_base_map_properties(JSON.parse(JSON.stringify(base_map_properties)))}} map_url={base_map_properties.url} set_map_url={(url) => {base_map_properties.url = url; set_base_map_properties(JSON.parse(JSON.stringify(base_map_properties)))}} change_property={(value) => change_property("map", value)} customId={`base_map${customId || ''}`} />
                            }
                        </div>
                    </div>
                    {
                        is_color ? ''
                            :
                            <div className="mt-2">
                                <MaterialTemplateTextureProperties parsed_properties={parsed_properties} change_property={change_property} />
                            </div>
                    }
                    <div>

                    </div>
                </UncontrolledCollapse>

                <UncontrolledCollapse header={"Bump map"} className='mb-2' defaultState={true}>
                    <MaterialTemplateMapUploadWithSlider map={bump_map} set_map={set_bump_map} map_name={bump_map_properties.name} set_map_name={(name) => {bump_map_properties.name = name; set_bump_map_properties(JSON.parse(JSON.stringify(bump_map_properties)))}} map_url={bump_map_properties.url} set_map_url={(url) => {bump_map_properties.url = url; set_bump_map_properties(JSON.parse(JSON.stringify(bump_map_properties)))}} property={"bump_map"} property_slider={"bump_scale"} parsed_properties={parsed_properties} change_property={change_property} customId={`bump_map${customId || ''}`} />
                </UncontrolledCollapse>

                <UncontrolledCollapse header={"Normal map"} className='mb-2' defaultState={true}>
                    <MaterialTemplateMapUploadWithSlider map={normal_map} set_map={set_normal_map} map_name={normal_map_properties.name} set_map_name={(name) => {normal_map_properties.name = name; set_normal_map_properties(JSON.parse(JSON.stringify(normal_map_properties)))}} map_url={normal_map_properties.url} set_map_url={(url) => {normal_map_properties.url = url; set_normal_map_properties(JSON.parse(JSON.stringify(normal_map_properties)))}} property={"normal_map"} property_slider={"normal_map_strength"} parsed_properties={parsed_properties} change_property={change_property} customId={`normal_map${customId || ''}`} />
                </UncontrolledCollapse>

            </div>
            <div className="inf_material_properties_individual_container">
                {/* Template Properties */}
                <div className="flex_between">
                    <div>Material template</div>
                    {/* <a href="infurnia.com">Material Property Guide</a> */}
                </div>
                <div className='mb-3' style={{}}>
                    <SingleSelect
                        options={material_templates ? material_templates : []}
                        selectedValue={selected_material_template}
                        onItemClick={(selectedValue) => { onChangeMaterialTemplate(selectedValue) }}
                        placeholder={"Select Material Type"}
                        displayValue={'name'}
                        id={"material_template_select" + (customId ? customId : '')}
                    />
                </div>
                {
                    keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.map((label, idx) => {
                        return (
                            <UncontrolledCollapse key={idx} header={/*header_properties && header_properties[label] ? header_properties[label] :*/ getHeader(label)} className='mb-2'>
                                {
                                            /* properties && properties.length ? properties.filter(x => grouped_properties[label].includes(x.label)).sort((a,b) => properties_order[a.label] - properties_order[b.label]) */grouped_properties[label].map((property, idx) => {

                                    return (
                                        <MaterialTemplateInput
                                            key={idx}
                                            property={property}
                                            parsed_properties={parsed_properties}
                                            change_property={(value) => change_property(property, value)}
                                        />
                                    )

                                })
                                    // :''
                                }
                            </UncontrolledCollapse>
                        )
                    })
                        : ''
                }
            </div>
        </div>
    )
}

export default AddAndUpdateMaterialProperties