import React, { useState, useEffect, useContext } from 'react';

import { fetch_all_level_preferences, set_preferences } from "../../utils/api/designing_preference"
import { UncontrolledCollapse } from '../../components/ICollpsable';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../../components/ITable';
import { Button, Input } from 'reactstrap';
// import default_cam_structure from '../../models/cam_structure';
import camJsonData from '../../assets/core_jsons/cam_structure.json'
import { UserContext } from '../../components/Contexts/UserContext';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import general_fetch from '../../utils/fetch';
import debug_log from '../../utils/debug_log';
import { useAlert } from 'react-alert';
import get_top_level_pref_value from '../../utils/get_top_level_pref_value';
import { handleError } from '../../utils/error';

const default_cam_structure = camJsonData
// const default_cam_structure = JSON.parse(camJsonData)

const invalidCamInput = (x) => {
    if (!x && x !== 0 && x !== false) {
        return true
    }
    return false
}

const get_pref_value = (preference, value) => {

    if (preference.type === 'number' && value !== '') {
        return Number(value)
    } else if (preference.type === 'bool') {
        return value === 'true'
    }

    return value
}

const toJSON = (str) => {
    if(typeof(str) === "string"){
        try {
            let json_str = JSON.parse(str);
            return json_str;
        } catch (err) {
            return [];
        }
    }

    return str
}

const is_depth_per_pass_visible = (camPreferences) => {
    if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"]){
        if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value){
            if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value === "depth_per_pass"){
                return true
            }
            return false
        }else{
            if(get_top_level_pref_value(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].value) === "depth_per_pass"){
                return true
            }
            return false
        }
    }

    return true
}

const is_number_of_steps_visible = (camPreferences) => {
    if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"]){
        if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value){
            if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value === "number_of_steps"){
                return true
            }
            return false
        }else{
            if(get_top_level_pref_value(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].value) === "number_of_steps"){
                return true
            }
            return false
        }
    }

    return true
}

const is_final_pass_depth_visible = (camPreferences) => {
    if(camPreferences["kdt_nc.router.final_pass"] !== undefined){
            return camPreferences["kdt_nc.router.final_pass"]
    }

    return true
}

const visibilityModule = {
    "cam_preferences~pocket_preferences~homag_pocket~depth_per_pass": is_depth_per_pass_visible,
    "cam_preferences~pocket_preferences~homag_pocket~number_of_steps": is_number_of_steps_visible,
    "kdt_nc.router.final_pass_depth": is_final_pass_depth_visible
}

// Visibility Route for preferences will be preference_full_name. For settings we can define a route.
// If a and b depend on value of c. then a, b, c should all be part of same parent.
// c should have the flag is_dependents - true. informing that a rerender is required on parent level to reflect the changes.
// Since the code is optimized for re-renders if the flag is not present the parent will not rerender and hence no changes will be seen.

const isPrefVisible = (pref, camPreferences, route) => {
    let visiblity_route;
    
    if(pref && pref.visiblity_route){
        visiblity_route = pref.visiblity_route;
    }else if(pref && pref.preference_full_name){
        visiblity_route = pref.preference_full_name
    }

    if(route){
        visiblity_route = route
    }

    if(visiblity_route && camPreferences && visibilityModule[visiblity_route]){
        return visibilityModule[visiblity_route](camPreferences)
    }
    return true
}

const ParentPreference = ({ parentPreference, full_name = '', isCollapsable = true, defaultState = false, displayState = true, camSettings, camPreferences }) => {
    const [dummy_state, set_dummy_state] = useState({});

    const reRender = () => {
        set_dummy_state(JSON.parse(JSON.stringify(dummy_state)))
    }

    return (
        parentPreference ?
            isCollapsable ?
                <UncontrolledCollapse header={parentPreference.name} defaultState={defaultState} style_header={{ fontSize: '14px', display: displayState?'':'none' }} className={displayState?'mb-3':''}>
                    {
                        parentPreference ? parentPreference.children.map((preference) => (
                            preference.type !== "parent" ?
                                <LeafPreference leafPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} displayState={preference.display} camSettings={camSettings} camPreferences={camPreferences} reRender={reRender}/>
                                :
                                <ParentPreference parentPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} defaultState = {preference.expand} displayState={preference.display} camSettings={camSettings} camPreferences={camPreferences} />
                        ))
                            : ''
                    }
                </UncontrolledCollapse>
                :
                parentPreference ? parentPreference.children.map((preference) => (
                    preference.type !== "parent" ?
                        <LeafPreference leafPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} displayState={preference.display} camSettings={camSettings} camPreferences={camPreferences} reRender={reRender}/>
                        :
                        <ParentPreference parentPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} defaultState = {preference.expand} displayState={preference.display} camSettings={camSettings} camPreferences={camPreferences} />
                ))
                    : ''
            : ''

    )
}

const LeafPreferenceSingleRow = ({ leafPreferenceCopy, leafPreference, unit, delete_row, index, full_name, camSettings }) => {
    const [unit_copy, set_unit_copy] = useState({});

    const handleChange = (event, col, idx) => {
        let temp = JSON.parse(JSON.stringify(unit_copy))
        temp[col.id] = event.target.value;
        set_unit_copy(temp)
        // todo - change the below line
        // leafPreference.tool_list[idx][col.id] = (col.type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value
        camSettings[full_name][idx][col.id] = (col.type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value
    }

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(unit))
        for(let i in leafPreference.fields){
            let col = leafPreference.fields[i]
            if(invalidCamInput(unit[col.id])){
                let default_value = col.default_value
                temp[col.id] = default_value
                camSettings[full_name][index][col.id] = default_value
            }
        }

        set_unit_copy(temp)
    }, [unit]);

    // useEffect(() => {
    // 	let validity = true;
    // 	// todo check for validity
    // 	if(leafPreference.tool_list){
    // 		for(let row in leafPreference.tool_list){
    // 			if(leafPreference.fields && leafPreference.fields.length){
    // 				for(let i in leafPreference.fields){
    // 					let field = leafPreference.fields[i]
    // 						if( field.mandatory && invalidInput(row[field.id])){
    // 							validity = false;
    // 							break;
    // 						}
    // 				}
    // 				if(!validity){
    // 					break;
    // 				}
    // 			}
    // 		}
    // 	}

    // 	is_valid[full_name] = validity
    // }, [unit_copy]);

    return (
        <React.Fragment>
            {
                leafPreference["fields"] && leafPreference["fields"].length > 0 &&
                leafPreference["fields"].map((col, idx) =>
                    <ITD key={idx}>
                        {/* {col.type === 'checkbox' ? <Checkbox_input_for_restore_modal unit={unit} col={col} reRenderModal={reRenderModal} /> : ''} */}
                        {col.type === 'number' || col.type === 'text' ? <Input className={`${col.mandatory && invalidCamInput(unit_copy[col.id]) ? 'invalid_input_cam' : ''}`} type={col.type} value={unit_copy[col.id]} onChange={(e) => handleChange(e, col, index)}></Input> : ''}
                        {/* {col.type === 'image' ? <img style={{ color: '#FFFFFF', maxHeight: '100%', maxWidth: '100%', height: "auto", width: '130px' }} title={unit[col.id] ? unit[col.id] : ''} src={unit[col.id] ? unit[col.id] : "-"} /> : ''}
						{col.type === 'multi_data' ?
							<React.Fragment>
								{
									unit[col.id] && unit[col.id].length && unit[col.id].map((x, idx) => (
										<div key={idx}>{x}</div>
									))
								}
							</React.Fragment>
							: ''
						} */}
                        {col.type === 'select' ? <Input className={`${col.mandatory && invalidCamInput(unit_copy[col.id]) ? 'invalid_input_cam' : ''}`} type={col.type} value={unit_copy[col.id]} onChange={(e) => handleChange(e, col, index)}>
                            {
                                col.value_options && col.value_options.length ? col.value_options.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))
                                    : ''
                            }
                        </Input> : ''}
                    </ITD>
                )
            }
            <ITD><i style={{ color: 'red', cursor: 'pointer' }} className='fa fa-trash' onClick={delete_row}></i></ITD>
        </React.Fragment>
    )
}

const LeafPreferenceInput = ({ preference, camSettings, full_name, displayState, camPreferences, reRender }) => {
    const [value, set_value] = useState('');

    const handleChange = (e) => {
        set_value(e.target.value)
        if(preference.preference_full_name){
            camPreferences[preference.preference_full_name]["final_value"] = get_pref_value(preference, e.target.value)
        }else{
            camSettings[full_name] = get_pref_value(preference, e.target.value)
        }

        if(preference.has_dependents && reRender){
            // ReRender the page
            reRender()

        }
    }

    useEffect(() => {
        if (camSettings) {
            if(preference.preference_full_name){
                if(camPreferences){
                    let cam_pref = camPreferences[preference.preference_full_name]
                    if(cam_pref){
                        set_value(get_top_level_pref_value(cam_pref.value))
                    }
                }
            }
            else{
                if (!invalidCamInput(camSettings[full_name]) && value != camSettings[full_name]) {
                    set_value(camSettings[full_name])
                } else if (invalidCamInput(camSettings[full_name] && !invalidCamInput(preference.default_value))) {
                    camSettings[full_name] = preference.default_value
                    set_value(get_pref_value(preference, preference.default_value))
                    reRender()
                }
            }
        }
    }, [camSettings, camPreferences]);

    return (
        isPrefVisible(preference, preference.preference_full_name ? camPreferences : camSettings, preference.preference_full_name ? "" : full_name) ? 
        <React.Fragment>
            {
                preference.type === 'number' || preference.type === "text" ?

                    <div className='mb-2 flex_property' style={{padding: '0px 20px', display: displayState?'':'none'}}>
                        <div style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, flex: 2 }}>{preference.name}</div>
                        <Input className={`${preference.mandatory && invalidCamInput(value) ? 'invalid_input_cam' : ''}`} style={{ flex: 1, minWidth: '40px' }} type={preference.type} value={value} onChange={handleChange} />
                    </div>
                    :
                    preference.type === 'select' ?
                        <div className='mb-2 flex_property' style={{padding: '0px 20px', display: displayState?'':'none'}}>
                            <div style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, flex: 2 }}>{preference.name}</div>
                            <Input className={`${preference.mandatory && invalidCamInput(value) ? 'invalid_input_cam' : ''}`} style={{ flex: 1, minWidth: '40px' }} type={preference.type} value={value} onChange={handleChange}>
                                {
                                    preference.preference_full_name && camPreferences && camPreferences[preference.preference_full_name] && camPreferences[preference.preference_full_name].value_options ?
                                        camPreferences[preference.preference_full_name].value_options.map(option => (
                                            <option key={option} value={option}>{preference.value_options && preference.value_options.find(x => x.id === option) ? preference.value_options.find(x => x.id === option).name : option}</option>
                                        ))
                                        :
                                        preference.value_options.map(option =>
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        )
                                }
                            </Input>
                        </div>
                        :
                        preference.type === 'bool' ?
                            <div className='mb-2 flex_property' style={{padding: '0px 20px', display: displayState?'':'none'}}>
                                <div style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, flex: 2 }}>{preference.name}</div>
                                <Input className={`${preference.mandatory && invalidCamInput(value) ? 'invalid_input_cam' : ''}`} style={{ flex: 1, minWidth: '40px' }} type={'select'} value={value === true || value === 'true' ? 'true' : 'false'} onChange={handleChange}>
                                    <option value={'true'}>True</option>
                                    <option value={'false'}>False</option>
                                </Input>
                            </div>
                            : ''
            }

        </React.Fragment>
        :''
    );
}


const LeafPreference = ({ leafPreference, full_name, displayState = true, camSettings, camPreferences, reRender }) => {
    const [leafPreferenceCopy, setLeafPreferenceCopy] = useState({});

    const reRenderLeafPreferenceCopy = () => {
        setLeafPreferenceCopy(JSON.parse(JSON.stringify(leafPreference)))
    }

    const add_row = () => {
        let sample_row = {}
        if (leafPreference.fields && leafPreference.fields.length) {
            for (let i in leafPreference.fields) {
                let field = leafPreference.fields[i]
                sample_row[field.id] = field.default_value ? field.type === 'number' ? Number(field.default_value) : field.default_value : ''
            }
        }
        if (camSettings[full_name] && Array.isArray(camSettings[full_name])) {
            camSettings[full_name].push(sample_row)
        } else if(camSettings[full_name] && typeof(camSettings[full_name] === 'string')) {
            camSettings[full_name] = toJSON(camSettings[full_name])
            camSettings[full_name].push(sample_row)
        }else{
            camSettings[full_name] = [sample_row]
        }
        reRenderLeafPreferenceCopy()
    }

    const delete_row = (idx) => {
        if (camSettings[full_name] && Array.isArray(camSettings[full_name])) {
            camSettings[full_name].splice(idx, 1)
        } else if(camSettings[full_name] && typeof(camSettings[full_name] === 'string')) {
            camSettings[full_name] = toJSON(camSettings[full_name])
            camSettings[full_name].splice(idx, 1)
        }
        reRenderLeafPreferenceCopy()
    }

    useEffect(() => {
        reRenderLeafPreferenceCopy()
    }, [leafPreference]);


    return (
        <div className='mb-3'>
            {
                leafPreference.type === "table" ?
                    <React.Fragment>
                        <div className='mb-2' style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, textTransform: 'capitalize', display: displayState?'':'none' }}>{leafPreference.name}</div>
                        <ITable coloumnSeparated={true} rowSeparated={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto', whiteSpace: 'nowrap', display: displayState?'':'none'}}>
                            <ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
                                {
                                    leafPreference.fields ? leafPreference.fields.map((col, idx) =>
                                        <ITH key={idx}><div className='flex_property'> {/*col.type === 'checkbox' ? <Checkbox className='mr-2' indeterminate={is_indeterminate(col.id)} checked={checkAll(col.id)} onClick={(e) => { onCheckAllChange(e, col.id) }}></Checkbox> : ''*/} <div>{col.name}{col.mandatory ? '*' : ''}</div></div></ITH>
                                    )
                                        : ''
                                }
                                <ITH>Actions</ITH>
                            </ITableHeader>
                            <ITableBody>
                                {
                                    camSettings && camSettings[full_name] && toJSON(camSettings[full_name]).map((unit, idx) => (
                                        <ITableRow key={idx}>
                                            <LeafPreferenceSingleRow leafPreferenceCopy={leafPreferenceCopy} leafPreference={leafPreference} unit={unit} delete_row={() => delete_row(idx)} index={idx} full_name={full_name} camSettings={camSettings} />
                                        </ITableRow>
                                    ))
                                }
                            </ITableBody>
                        </ITable>
                        <div className='mt-4 ml-3' style={{ display: 'flex', color: '#2688F7', fontWeight: '700', fontSize: '14px', textAlign: 'left', display: displayState?'':'none' }}>
                            <div onClick={add_row} style={{ cursor: 'pointer' }}>+ Add Row</div>
                        </div>
                    </React.Fragment>
                    :
                    <LeafPreferenceInput preference={leafPreference} camSettings={camSettings} displayState = {displayState} full_name={full_name} camPreferences={camPreferences} reRender={reRender}/>

            }

        </div>
    )

}

// const camValidation = {
// 	"kdt_nc.general.home_x": {
// 		mandatory: true
// 	},
// 	"kdt_nc.general.home_y": {
// 		mandatory: true
// 	},
// 	"kdt_nc.general.home_z": {
// 		mandatory: true
// 	},
// 	"kdt_nc.general.safe_z": {
// 		mandatory: true
// 	},
// 	"kdt_nc.general.spindle_speed": {
// 		mandatory: true
// 	},
// 	"kdt_nc.drill.tool_list":{
// 		"type": 'table',
// 		"mandatory_fields": ["tool_name", "slot", "diameter", "feed_rate"]
// 	},
// 	"kdt_nc.saw.tool_list":{
// 		"type": 'table',
// 		"mandatory_fields": ["tool_name", "slot", "diameter", "feed_rate"]
// 	},
// 	"kdt_nc.router.tool_list":{
// 		"type": 'table',
// 		"mandatory_fields": ["tool_name", "slot", "diameter", "feed_rate"]
// 	},
// }

function parseValidationRules(input) {
    const camValidation = {};

    function parseNode(node, prefix) {
        const nodeId = prefix ? prefix + '.' + node.id : node.id;
        if (node.type === 'parent' && node.children) {
            node.children.forEach(child => parseNode(child, nodeId));
        } else if (node.type === 'table' && node.fields) {
            const mandatoryFields = node.fields
                .filter(field => field.mandatory)
                .map(field => field.id);
            camValidation[nodeId] = {
                type: 'table',
                mandatory_fields: mandatoryFields
            };
        } else {
            camValidation[nodeId] = {
                mandatory: node.mandatory
            };
        }
    }
    for (let i in input) {
        parseNode(input[i], '');
    }
    // console.log("cam vailidation json", camValidation);

    return camValidation;
}

const camValidation = parseValidationRules(default_cam_structure)


const CamSettings = ({ store_details, get_store_details, set_page_loader }) => {
    const [camSettings, setCamSettings] = useState(null);
    const [camPreferences, setCamPreferences] = useState(null);
    const [is_valid, set_is_valid] = useState({});
    const [display_default_cam_structure, set_display_default_cam_structure] = useState(null);
    const [search_string_cam_settings, set_search_string_cam_settings] = useState('');

    const user = useContext(UserContext)
    const org_bu_view = useContext(OrgBuContext)

    const alert = useAlert()

    const updateCam = async () => {
        // let validity_check = true
        // for(let i in is_valid){
        // 	if(!is_valid[i]){
        // 		validity_check = false;
        // 		break;
        // 	}
        // }
        let isValidCamPref = true
        let design_preference_id_and_values = []
        for(let i in camPreferences){
            let pref = camPreferences[i];
            // todo - can further check if final value is equal to default value to optimize and avoid the update
            if(isPrefVisible(null, camPreferences, i) && typeof(pref.final_value) != 'undefined' && pref.id){
                if(invalidCamInput(pref.final_value)){
                    isValidCamPref = false;
                    break;
                }
                design_preference_id_and_values.push({designing_preference_id: pref.id, value: pref.final_value})
            }
        }

        debug_log("cam preferences in update", camSettings, design_preference_id_and_values)
        if (isCamPrefValid(camSettings) && isValidCamPref) {
            set_page_loader({
                show: true,
                text: 'Please wait'
            });

            try {
                var updates = JSON.stringify({ cam_settings: camSettings })
                let body = { updates: updates }
                // let resp = await general_fetch({url:'store/update_info', body})

                if (org_bu_view == 'bu') {
                    body.business_unit_id = user.current_business_unit_id
                    let resp = await general_fetch({ url: 'business_unit/update_info', body })
                } else {
                    let resp = await general_fetch({ url: 'store/update_info', body })
                }
                await set_preferences(design_preference_id_and_values)
                
                getCamPreferences()
                get_store_details()

                alert.success('CAM settings updated successfully')
            } catch (err) {
                console.log(err)
            }

            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        } else {
            alert.error("Please fill all the mandatory fields.")
        }
    }

    const getCamPreferences = async () => {
        try{
            let all_preferences = await fetch_all_level_preferences([], false);
            let cam_preferences = all_preferences.filter(x => x.fully_qualified_name.startsWith("cam_preferences"))
            let cam_preferences_map = {};
            for (let i in cam_preferences) {
                let cam_pref = cam_preferences[i]
                cam_preferences_map[cam_pref.fully_qualified_name] = cam_pref
            }
            debug_log("cam preference map", cam_preferences_map)
            setCamPreferences(cam_preferences_map)
        } catch (err) {
            console.error("Error in getCamPreferences", err)
            handleError(err)
        }
    }



    useEffect(() => {
        if (store_details && store_details.id) {
            if (store_details.cam_settings) {
                setCamSettings(store_details.cam_settings)
            } else {
                setCamSettings({})
            }
            getCamPreferences()
        }
    }, [store_details]);

    useEffect(() => {
        // console.log("KP set here");
        let temp = JSON.parse(JSON.stringify(default_cam_structure))
        temp.map(item => JSON.parse(JSON.stringify(item)))
        set_display_default_cam_structure(temp)
    }, [default_cam_structure])

    function searchCamSettings(obj, par=false) {
        if(obj.type != 'parent'){
            if(obj.name && obj.name.toLowerCase().includes(search_string_cam_settings && search_string_cam_settings.toLowerCase())){
                obj.display = true;
                obj.exact_match = search_string_cam_settings.length?true:false;
            }
            else{
                obj.display = par;
            }
            if(obj.display && obj.flag == undefined && !obj.flag){
                obj.flag = true;
            }
            // console.log("KP OBJ", obj.name, obj.display)
            return obj
        }

        else {
            if(obj.type == 'parent'){
                if(par){
                    // console.log("kp obj.name", obj.name);
                    obj.display = true;
                    obj.children.map(x => {
                        let temp = searchCamSettings(x, true)
                        obj.expand = obj.expand || (temp.expand || temp.exact_match);
                        return temp;
                    })
                    // var garb = obj.children.map(x => {
                    //     let temp = searchCamSettings(x)
                    //     obj.expand = obj.expand || (temp.expand || temp.exact_match);
                    // });
                }
                else {
                    if(obj.name && obj.name.toLowerCase().includes(search_string_cam_settings && search_string_cam_settings.toLowerCase())){
                        obj.display = true
                        obj.exact_match = search_string_cam_settings.length?true:false
                        // console.log("KP OBJ par", obj.name, search_string_cam_settings, obj.display)
                        obj.children.map(x => {
                            let temp = searchCamSettings(x, true)
                            obj.expand = obj.expand || (temp.expand || temp.exact_match);
                            return temp;
                        })
                    }
                    else{
                        obj.children.forEach(x => {
                            searchCamSettings(x)
                        });
                        var garb = obj.children.map(x => {
                            let temp = searchCamSettings(x);
                            obj.display = obj.display || temp.display;
                            obj.expand = obj.expand || (temp.expand || temp.exact_match);
                        });
                    }
                }
                
            }
            return obj
        }
    }

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(default_cam_structure))
        temp.map(item => JSON.parse(JSON.stringify(item)))
        temp.map(item => searchCamSettings(item));
        set_display_default_cam_structure(temp);
        // console.log("KP default cam struc", temp, search_string_cam_settings);
    }, [search_string_cam_settings])

    const isCamPrefValid = (preference) => {
        // if(preference.tool_list){
        // 	let leafPreference = preference
        // 	let validity = true;
        // 	// todo check for validity
        // 	if (leafPreference.tool_list.length) {
        // 		for (let j in leafPreference.tool_list) {
        // 			let row = leafPreference.tool_list[j]
        // 			if (default_cam_structure2[full_name] && default_cam_structure2[full_name].length) {
        // 				for (let i in default_cam_structure2[full_name]) {
        // 					let field = default_cam_structure2[full_name][i]
        // 					if (field.mandatory && invalidInput(row[field.id])) {
        // 						validity = false;
        // 						break;
        // 					}
        // 				}
        // 				if (!validity) {
        // 					break;
        // 				}
        // 			}
        // 		}
        // 	}

        // 	return validity
        // }else{
        // 	let validity = preference && Object.keys(preference) ? Object.keys(preference).map((childPref) => (isCamPrefValid(preference[childPref], full_name ? full_name+ '>' + childPref : childPref))) : [true]
        // 	return !validity.includes(false)
        // }
        if (preference && Object.keys(preference)) {
            for (let full_name in preference) {
                if (camValidation[full_name] && camValidation[full_name].type == 'table') {
                    let mandatory_fields = camValidation[full_name].mandatory_fields
                    let table_data = toJSON(preference[full_name]);
                    for (let i in table_data) {
                        let pref = table_data[i]
                        for (let j in mandatory_fields) {
                            let mandatory_field = mandatory_fields[j]
                            if (invalidCamInput(pref[mandatory_field])) {
                                debug_log("invalid cam preference ", full_name)
                                return false
                            }
                        }
                    }
                } else {
                    if (camValidation[full_name] && camValidation[full_name].mandatory && invalidCamInput(preference[full_name])) {
                        debug_log("invalid cam preference ", full_name)
                        return false
                    }
                }
            }
        }
        return true
    }

    return (
        <React.Fragment>
            <div style={{ maxHeight: 'calc( 100% - 80px )', overflow: 'auto' }}>
                <div style={{marginBottom: '30px'}} className='infurnia_search_component_container_ideal'>
                    <span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
                    <input id='cam_search' className='infurnia_search_component_inner_container' placeholder='Type to search' type='text' onChange={(e) => set_search_string_cam_settings(e.target.value)}></input>
                    <span className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:search_string_cam_settings ? 'visible':'hidden', fontStyle:'italic'}} onClick={e => {set_search_string_cam_settings(''); document.getElementById('cam_search').value = ''}}>Clear</span>
                </div>
                {/* <ParentPreference parentPreference={camSettings} isCollapsable={false} is_valid={is_valid}/> */}
                {
                    display_default_cam_structure && display_default_cam_structure.length ? display_default_cam_structure.map((preference) => (
                        preference.type === 'parent' ?
                            <ParentPreference parentPreference={preference} full_name={preference.id} defaultState = {preference.expand} displayState={preference.display} camSettings={camSettings} camPreferences={camPreferences} />
                            :
                            <LeafPreference leafPreference={preference} full_name={preference.id} displayState={preference.display} camSettings={camSettings} camPreferences={camPreferences}/>
                    ))
                        : ''
                }
            </div>
            <div className='flex_property mr-4' style={{ height: '80px', justifyContent: 'end' }}>
                <Button id='update_cam_settigns' className='blue_button' onClick={updateCam}>Update</Button>
            </div>
        </React.Fragment>

    )
}

export default CamSettings