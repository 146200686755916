import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'
import './index.js';
import Multiselect from '../Multiselect';

const HWKeyValuePair_range = ({trigger_red_marker, value_red_marker, open, item_json, idx, single_item_json, set_item_json ,mode, tags, component_options_json, combination_logic, set_combination_logic, position_type, shutter_mechanism_values, shelf_type_values, item_json_dest_component, item_json_dest_subcomponent, applicable_array, sales_channels, has_skus_mapped }) => {

    const alert = useAlert()
    const [selected_comp , set_selected_comp] = useState([]);
    const [selected_sub_comp,set_selected_sub_comp] =useState([])
    const [selected_property,set_selected_property] =useState([])
    const [values_array ,set_values_array] =useState([])
    const [selected_tag_1_array,set_selected_tag_1_array] =useState([])

    // useEffect(() => {
    //     console.log("kpppp item_json is", item_json)
    // })

    useEffect(() => { 
        if(single_item_json){
            if(single_item_json && single_item_json.property[0] && single_item_json.property[0].id==='tag'){
                set_values_array(tags)
                set_selected_tag_1_array(single_item_json.tag?single_item_json.tag:[])
            }
            if(single_item_json && single_item_json.property[0] && single_item_json.property[0].id==='sales_channel'){
                set_values_array(sales_channels)
                set_selected_tag_1_array(single_item_json.sales_channel?single_item_json.sales_channel:[])
            }
            if(single_item_json && single_item_json.property[0] && single_item_json.property[0].id==='shelf_type'){
                set_values_array(shelf_type_values)
                set_selected_tag_1_array(single_item_json.value) 
            }
            if(single_item_json && single_item_json.property[0] && single_item_json.property[0].id==='shutter_mechanism'){
                if(applicable_array && applicable_array.length && applicable_array[0].component[0].id==='wardrobe'){
                    set_values_array(shutter_mechanism_values.filter(x => x.option_type==='wardrobe'))    
                }else{
                    set_values_array(shutter_mechanism_values.filter(x => x.option_type==='cabinet'))
                }
                set_selected_tag_1_array(single_item_json.value)
            }

            set_selected_comp(single_item_json.component?single_item_json.component:[])
            set_selected_sub_comp(single_item_json.subcomponent?single_item_json.subcomponent:[])
            set_selected_property(single_item_json.property?single_item_json.property:[])
        }
	},[single_item_json, item_json_dest_subcomponent])

    const onclick_item = (operator, value) => {
        update_item_json(idx,operator, value)
        if(operator==='component'){
            // var x_unit = component_options_json.find(x => x.id===value)
            set_selected_comp(value)
            update_item_json(idx,'subcomponent', [])
            set_selected_sub_comp([])
            console.log('item_json======>',item_json)
        }
        if(operator==='subcomponent' || operator==='component'){
            if(operator==='subcomponent'){
                // var x_sub_unit = selected_comp.sub_component.find(x => x.id===value)
                set_selected_sub_comp(value)
            }
            update_item_json(idx,'property', [])
            set_selected_property([])
            set_values_array([])
            console.log('item_json======>',item_json)
        }
        if(operator==='property'|| operator==='subcomponent' || operator==='component'){
            if(operator==='property'){
                set_selected_property(value)
                if(value && value.length){
                    value[0].id==='tag' && set_values_array(tags)
                    value[0].id==='sales_channel' && set_values_array(sales_channels)
                    value[0].id==='shelf_type' && set_values_array(shelf_type_values)
                    value[0].type==='range' && update_item_json(idx,'operator','range')
                    if(value[0].id==='shutter_mechanism'){
                        if(applicable_array && applicable_array.length && applicable_array[0].component[0] && applicable_array[0].component[0].id==='wardrobe'){
                            set_values_array(shutter_mechanism_values.filter(x => x.option_type==='wardrobe'))  
                        }else{
                            set_values_array(shutter_mechanism_values.filter(x => x.option_type==='cabinet'))
                        }
                    }
                }
            }
            update_item_json(idx,'value', []) 
            update_item_json(idx,'operator','equal')
            set_selected_tag_1_array([])
            console.log('item_json======>',item_json)
        }
        if(operator==='tag' || operator==='sales_channel' || operator==='value'){
            set_selected_tag_1_array(value)
        }
    }

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    const handleClose_for_mf__source = () => {
        set_selected_sub_comp([])
        set_selected_property('')
    }

    // useEffect(() => {
    //     console.log("kppp trigger_red_marker", trigger_red_marker)
    // }, [trigger_red_marker])

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    useEffect(() => {
        if(item_json){
            console.log(item_json)
        }
    },[item_json])

    useEffect(() => {
        console.log('sku mapped in hw rules', has_skus_mapped)
    }, [has_skus_mapped]);

    const get_options = (sub_comp) => {
        if(sub_comp && sub_comp.properties){
            if( sub_comp && sub_comp.id==='skirting'){
                return sub_comp.properties.filter(x=>x.id!='sku')
            }else if(position_type === 'applicable' && has_skus_mapped){
                let temp = JSON.parse(JSON.stringify(sub_comp.properties)).map(x => {if(x.id === 'tag'){x.disabled = true; x.disabled_message = "Cant have property tag with SKU Applicability"} return x})
                return temp
            }else{
                return sub_comp.properties
            }
        }
        return []
    }

    return(
        <div style={{display:'flex', alignItems:'center', marginLeft:'-50px'}}>
            
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{width:'50px', height:'70px',borderBottom:idx!=0?'1px solid #DDDDDD':'' , borderLeft:idx!=0?'1px solid #DDDDDD':''}}>

                </div>
                <div style={{width:'50px', height:'70px', borderTop:item_json.length===idx+1?'':'1px solid #DDDDDD' , borderLeft:item_json.length===idx+1?"":'1px solid #DDDDDD'}}>
                            
                </div>
                {
                    item_json.length===idx+1?"":
                    <Input style={{fontSize:'12px', height:'30px', width:'70px', marginLeft:'-35px'}} type="select"
                        id={position_type+'_combination_logic_'+idx}
                        value={combination_logic}
                        onChange={(e) =>set_combination_logic(e.target.value)}
                    >
                        <option value='and'>And</option>
                        <option value='or'>OR</option>
                    </Input>
                }
            </div>
            
            <CardBody style={{width:'100%',padding:'15px 20px', overflow:'unset', backgroundColor:'white', marginBottom:item_json && item_json.length-1===idx?'0px':'20px', height:'130px'}}>
                {
                    // position_type==='applicable'?'':
                    <Row style={{marginBottom:'10px'}}>
                        <Col xs={11}>
                            <div style={{fontSize:'16px', color:'black'}}>{position_type==='action'?'Action':'Condition'} {idx+1}</div>
                        </Col>
                        <Col xs={1} style={{ textAlign:'right'}}>
                            <i onClick={() => delete_item_json(idx)} style={{ color:'#F0656F',fontSize:'14px',cursor:'pointer'}} className='fa fa-trash'/>
                        </Col>
                    </Row>
                }
                <Row key={idx} style={{ margin:0}}>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>Component</div>
                        <Multiselect
                            style_badge_container={{borderColor: (trigger_red_marker && !((single_item_json ?single_item_json.component:[]) && (single_item_json ?single_item_json.component:[]).length)) ? 'red' : ''}}
                            options={component_options_json} 
                            selectedValues={single_item_json ?single_item_json.component:[]}
                            onSelect={(selectedList) => onclick_item('component', selectedList)}
                            onRemove={(selectedList) => onclick_item('component', selectedList)}
                            displayValue="name" // Property name 
                            id={position_type+'_component_'+idx}
                            placeholder='Search Value'
                            selectionLimit={1}
                            // disable={idx>0?true:false}
                        />
                    </Col>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>SubComponent</div>
                        <Multiselect
                            style_badge_container={{borderColor: (trigger_red_marker && !((single_item_json ?single_item_json.subcomponent:[]) && (single_item_json ?single_item_json.subcomponent:[]).length)) ? 'red' : ''}}
                            options={selected_comp && selected_comp.length && selected_comp[0].sub_component && selected_comp[0].sub_component.length?selected_comp[0].sub_component:[]}
                            selectedValues={single_item_json && single_item_json.subcomponent?single_item_json.subcomponent:[]}
                            onSelect={(selectedList) => onclick_item('subcomponent', selectedList)}
                            onRemove={(selectedList) => onclick_item('subcomponent', selectedList)}
                            displayValue="name" // Property name 
                            id={position_type+'_sub_component_'+idx}
                            placeholder='Search Value'
                            selectionLimit={1}
                            // disable={idx>0?true:false}
                        />
                    </Col>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Property</div>
                            {
                                selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].properties &&  selected_sub_comp[0].properties.length ?
                                    <Multiselect
                                        style_badge_container={{borderColor: (trigger_red_marker && !(selected_property && selected_property.length)) ? 'red' : ''}}
                                        options={get_options(selected_sub_comp[0])} 
                                        selectedValues={selected_property?selected_property:[]}
                                        onSelect={(selectedList) => onclick_item('property', selectedList)}
                                        onRemove={(selectedList) => onclick_item('property', selectedList)}
                                        displayValue="name" // Property name 
                                        id={position_type+'_property_'+idx}
                                        placeholder='Search Value'
                                        selectionLimit={1}
                                    />  
                                :
                                <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70', borderColor: (trigger_red_marker && !(selected_property && selected_property.length)) ? 'red' : ''}} type="select">
                                    <option>Choose a value</option>
                                </Input>
                            }
                    </Col>
                    
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Operator</div>
                        <Input id={position_type+'_operator_'+idx} 
                            style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', opacity:selected_property && selected_property.length && selected_property[0].id==='no_condition'?.5:1}} type="select" 
                            value={item_json[idx]?item_json[idx].operator:'none'}
                            onChange={(e) =>update_item_json(idx,'operator', e.target.value)}
                            onClick={(e) =>update_item_json(idx,'operator', e.target.value)}
                            disabled={selected_property && selected_property.length && selected_property[0].id==='no_condition'?true:false}
                        >
                            {
                                selected_property && selected_property.length && (selected_property[0].type==='number' || selected_property[0].id==='front_right' || selected_property[0].id==='side_left') ?
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    <option value='less'>Less Than</option>
                                    <option value='greater'>Greater Than</option>
                                    <option value='lessequal'>Less Than Equal</option>
                                    <option value='greaterequal'>Greater Than Equal</option>
                                    <option value='range'>Range</option>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    {selected_property && selected_property[0] && ['front_skirting_visibility', 'left_skirting_visibility', 'right_skirting_visibility'].includes(selected_property[0].id)?"":<option value='notequal'>Not Equal</option>}
                                </React.Fragment>
                            }
                        </Input>
                    </Col>
                    
                    
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px'}}>
                        {selected_property && selected_property.length && selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator=='range'?<div style={{marginBottom:'5px'}}>Min-Max</div>:<div style={{marginBottom:'5px'}}>Value</div>}
                        {
                            selected_property && selected_property.length && item_json && item_json.length?
                            <React.Fragment>
                                {
                                    (selected_property[0].type==='number' || selected_property[0].id==='front_right' || selected_property[0].id==='side_left') && item_json[idx] && item_json[idx].operator && item_json[idx].operator!='range' ?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0))) ? 'red' : '' }} type="number"
                                        placeholder='Enter a value'
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value', Number(e.target.value)):alert.error('Negative Number not allowed')}
                                    />
                                    :''
                                }
                                {
                                    (selected_property[0].type==='number' || selected_property[0].id==='front_right' || selected_property[0].id==='side_left') && item_json[idx] && item_json[idx].operator && item_json[idx].operator==='range' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_min_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || (!item_json[idx].min_value || (Array.isArray(item_json[idx].min_value) && item_json[idx].min_value.length == 0))) ? 'red' : ''}} type="number"
                                                    placeholder='min-value'
                                                    title='min value'
                                                    value={item_json[idx]?item_json[idx].min_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'min_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                                <Input id={position_type+'_max_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && ((!item_json[idx]) || (!item_json[idx].max_value || (Array.isArray(item_json[idx].max_value) && item_json[idx].max_value.length == 0))) ? 'red' : ''}} type="number"
                                                    placeholder='max-value'
                                                    title='max value'
                                                    value={item_json[idx]?item_json[idx].max_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'max_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].id==='tag' || selected_property[0].id==='sales_channel' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'70px'}} type="select" 
                                                    value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                                    onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                                    title='Select Logic between property values'
                                                >    
                                                    <option value='and'>And</option>
                                                    <option value='or'>Or</option>
                                                </Input>
                                                <div style={{width:'calc(100% - 70px)'}}>
                                                    <Multiselect
                                                        style_badge_container={{borderColor: trigger_red_marker && ((!(selected_tag_1_array?selected_tag_1_array:[]) || (Array.isArray((selected_tag_1_array?selected_tag_1_array:[])) && (selected_tag_1_array?selected_tag_1_array:[]).length == 0))) ? 'red' : ''}}
                                                        style={{backgroundColor:'white', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}}
                                                        options={values_array && values_array.length? JSON.parse(JSON.stringify(values_array)):[]} 
                                                        selectedValues={selected_tag_1_array?selected_tag_1_array:[]}
                                                        onSelect={(selectedList) => onclick_item(selected_property[0].id, selectedList)}
                                                        onRemove={(selectedList) => onclick_item(selected_property[0].id, selectedList)}
                                                        displayValue={selected_property[0].id==="tag"?'tag':'name'} // Property name 
                                                        id={position_type+'_value_'+idx}
                                                        placeholder= {selected_property[0].id==="tag"?'Select Tag':'Select Sales Channel'}
                                                    /> 
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property && selected_property.length && selected_property[0].type==='panel_type' && selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].id != 'panel'?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value=''>Select Option</option>
										{selected_sub_comp[0].id === 'panel2' ? <option value='none'>None</option> : ''}
                                        <option value='shelf'>Shelf</option>
                                        <option value='fixed_shelf'>Fixed Shelf</option>
                                        <option value='adjustable_shelf'>Adjustable Shelf</option>
                                        <option value='vertical_partition_panel'>Vertical Partition Panel</option>
                                        <option value='partition_back_panel'>Partition Back Panel</option>
                                        <option value='skirting_panel'>Skirting Panel</option>
                                        <option value='exposed_panel'>Exposed Panel</option>
                                        <option value='carcass_panel'>Carcass Panel</option>
                                        <option value='shutter_panel'>Shutter Panel</option>
                                        <option value='internal_shutter_panel'>Internal Shutter Panel</option>
                                        <option value='external_shutter_panel'>External Shutter Panel</option>
                                        <option value='carcass_bottom_panel_only'>Bottom Panel</option>
                                        <option value='carcass_left_panel_only'>Left Panel</option>
                                        <option value='carcass_right_panel_only'>Right Panel</option>
                                        <option value='carcass_top_panel_only'>Top Panel</option>
                                        <option value='carcass_back_panel_only'>Back Panel</option>
                                        <option value='bottom_panel'>Bottom Panel and Bottom Rail 1</option>
                                        <option value='left_panel'>Left Panel and Left Rail 1</option>
                                        <option value='right_panel'>Right Panel and Right Rail 1</option>
                                        <option value='top_panel'>Top Panel and Top Rail 1</option>
                                        <option value='back_panel'>Back Panel and Back Rail 1</option>
                                        <option value='top_rail_panel'>Top Rail Panel</option>
                                        <option value='top_rail_panel_1'>Top Rail - 1</option>
                                        <option value='top_rail_panel_2'>Top Rail - 2</option>
										<option value='drawer_fascia_panel'>Drawer Fascia Panel</option>
										<option value='drawer_carcass_panel'>Drawer Carcass Panel</option>
										<option value='drawer_left_panel'>Drawer Left Panel</option>
										<option value='drawer_right_panel'>Drawer Right Panel</option>
										<option value='drawer_bottom_panel'>Drawer Bottom Panel</option>
										<option value='drawer_back_panel'>Drawer Back Panel</option>
										<option value='drawer_front_panel'>Drawer Front Panel</option>
                                        <option value='drawer_left_filler_side_panel'>Drawer Left Filler Side Panel</option>
                                        <option value='drawer_left_filler_front_panel'>Drawer Left Filler Front Panel</option>
                                        <option value='drawer_left_filler_back_panel'>Drawer Left Filler Back Panel</option>
                                        <option value='drawer_right_filler_side_panel'>Drawer Right Filler Side Panel</option>
                                        <option value='drawer_right_filler_front_panel'>Drawer Right Filler Front Panel</option>
                                        <option value='drawer_right_filler_back_panel'>Drawer Right Filler Back Panel</option>
										<option value='dummy_shutter'>Dummy Shutter</option>
										<option value='hinge_shutter'>Hinge Shutter</option>
										<option value='front_panel'>Front Panel</option>
                                        <option value='front_rail_panel'>Front Rail Panel</option>
										<option value='back_rail_panel'>Back Rail Panel</option>
										<option value='bottom_rail_panel'>Bottom Rail Panel</option>
										<option value='stability_rail_panel'>Stability Rail Panel</option>
										<option value='rail_panel'>Rail Panel</option>
										<option value='drawer_filler_panel'>Drawer Filler Panel</option>
										<option value='finger_groove_panel'>Finger Groove Panel</option>
                                        <option value='dummy_panel'>Dummy Panel</option>
                                    </Input>
                                    : ''
                                }
                                {
                                    selected_property && selected_property.length && selected_property[0].type==='connection_type'?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value=''>Select Option</option>
                                        <option value='dowel'>Eccentrics</option>
                                        <option value='hinge'>Hinge</option>
                                        <option value='cabinet_connector'>Cabinet Connector</option>
                                        <option value='single_panel_connector'>Single Panel Connector</option>
                                    </Input>
                                    :''
                                }
                                {
                                    selected_property && selected_property.length && selected_property[0].type==='created_source'?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value=''>Select Option</option>
                                        <option value='system_detected'>System Detected</option>
                                        <option value='user_added'>User Added</option>
                                    </Input>
                                    :''
                                }
								{

								}
                                {
                                    selected_property && selected_property.length && selected_property[0].type==='panel_type' && selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].id == 'panel'?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value='none'>Select Option</option>
                                        <option value='finger_groove_panel'>Finger groove panel</option>
                                    </Input>
                                    :''
                                }
                                {
                                    selected_property[0].type==='select' ?
                                        
                                        <Multiselect
                                        style_badge_container={{borderColor: (selected_property && selected_property.length && selected_property[0].id==='no_condition'?false:true) && trigger_red_marker && ((!(selected_tag_1_array?selected_tag_1_array:[]) || (Array.isArray((selected_tag_1_array?selected_tag_1_array:[])) && (selected_tag_1_array?selected_tag_1_array:[]).length == 0))) ? 'red' : ''}}
                                            style={{backgroundColor:'white', borderColor: (selected_property && selected_property.length && selected_property[0].id==='no_condition'?false:true) && trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}}
                                            options={values_array && values_array.length? JSON.parse(JSON.stringify(values_array)):[]} 
                                            selectedValues={selected_tag_1_array?selected_tag_1_array:[]}
                                            onSelect={(selectedList) => onclick_item('value', selectedList)}
                                            onRemove={(selectedList) => onclick_item('value', selectedList)}
                                            displayValue='name' // Property name 
                                            id={position_type+'_value_'+idx}
                                            placeholder= 'Select Option'
                                            selectionLimit={1}
                                            disable={selected_property && selected_property.length && selected_property[0].id==='no_condition'?true:false}
                                        /> 
                                    :''
                                }
								{
									selected_property && selected_property.length && selected_property[0].id==='shutter_mechanism' && selected_sub_comp && selected_sub_comp.length && (selected_sub_comp[0].id == 'panel1' || selected_sub_comp[0].id == 'panel2')?
										<>
											<div className='flex_property'>
												<Input id={position_type+'_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'70px', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}} type="select" 
													value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
													onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
													title='Select Logic between property values'
												>    
													<option value='and'>And</option>
													<option value='or'>Or</option>
												</Input>
												<div style={{width:'calc(100% - 70px)'}}>
													<Multiselect
														style={{backgroundColor:'white'}}
                                                        style_badge_container={{borderColor: trigger_red_marker && ((!(selected_tag_1_array?selected_tag_1_array:[]) || (Array.isArray((selected_tag_1_array?selected_tag_1_array:[])) && (selected_tag_1_array?selected_tag_1_array:[]).length == 0))) ? 'red' : ''}}
														options={
															[{ id: "single_swing", name: "Single Swing" },
															{ id: "single_none", name: "Single None" },
															{ id: "double_swing", name: "Double Swing" },
															{ id: "single_lift_up", name: "Single Lift Up" },
															{ id: "double_lift_up", name: "Double Lift Up" },
															{ id: "left_bifold", name: "Left Bifold" },
															{ id: "right_bifold", name: "Right Bifold" },
															{ id: "corner_unit_double_swing", name: "Corner Unit Double Swing" },
															{ id: "flap_down", name: "Flap Down" },
															{ id: "flap_up", name: "Flap Up" },
															{ id: "rolling_shutter", name: "Rolling Shutter" },
															{ id: "2_door_sliding_shutter", name: "2 Door Sliding Shutter" },
															{ id: "3_door_sliding_shutter", name: "3 Door Sliding Shutter" },
															{ id: "4_door_sliding_shutter", name: "4 Door Sliding Shutter" },
															{ id: "5_door_sliding_shutter", name: "5 Door Sliding Shutter" },
															{ id: "6_door_sliding_shutter", name: "6 Door Sliding Shutter" }]
														} 
														selectedValues={selected_tag_1_array?selected_tag_1_array:[]}
														onSelect={(selectedList) => onclick_item('value', selectedList)}
														onRemove={(selectedList) => onclick_item('value', selectedList)}
														displayValue='name' // Property name 
														id={position_type+'_value_'+idx}
														placeholder= 'Select options'
													/>
												</div>
											</div>	
										</> :""
								}
                                {
                                    selected_property[0].type==='input-select' && ['front_skirting_visibility', 'left_skirting_visibility', 'right_skirting_visibility'].includes(selected_property[0].id)?
                                        
                                        <Input id={position_type+'_value_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}} type="select" 
                                            value={item_json[idx]?item_json[idx].value:'none'}
                                            onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                            onClick={(e) =>update_item_json(idx,'value', e.target.value)}
                                        >   
                                            <option value={null}>Select a value</option>    
                                            <option value='visible'>Visible</option>
                                        </Input> 
                                    :''
                                }
                            </React.Fragment>
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70', borderColor: trigger_red_marker && (((!item_json[idx]) || ((![0, '0'].includes(item_json[idx].value) && (!item_json[idx].value || item_json[idx].value == 'none')) || (Array.isArray(item_json[idx].value) && item_json[idx].value.length == 0)))) ? 'red' : ''}} type="select">
                                <option value='none'>Choose a value</option>
                            </Input>    
                        }
                    </Col>
                </Row>
            </CardBody>
        </div>
    )
}

export default HWKeyValuePair_range;